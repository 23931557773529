import { apiPost } from '@utils/api'

const editAddress = async (token, storeCode, address) => {
  const res = await apiPost(
    `ext/n98-customer-address/save?token=${token}&storeCode=${storeCode}`,
    { address }
  )
  return res
}

export default editAddress
