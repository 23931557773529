import Text from '@components/ui/Text/Text'
import { cartService } from '@utils/magento2'
import useI18n from '@utils/hooks/useI18n'
import Textarea from '@components/ui/Textarea/Textarea'
import Button from '@components/ui/Button/Button'
import { useRef, useState, useEffect } from 'react'
import useOnClickOutside from '@utils/hooks/useClose'
import Input from '@components/ui/Input/Input'
import { useSelector } from 'react-redux'
import localRoute from '@utils/helpers/localRoute'
import Icon from '@components/ui/Icon/Icon'
import { setNotification } from '@features/ui/uiSlice'
import { useDispatch } from 'react-redux'
import ConfigContext from '@utils/context/config'
import { useContext } from 'react'
import Spinner from '@components/ui/Spinner'
import PrintCart from '@components/Pages/ProductPage/PrintCart'

const ShareCart = ({ setIsShare }) => {
  const i18n = useI18n()
  const dispatch = useDispatch()
  const share = useRef(null)
  const [res, setRes] = useState({})
  const [count, setCount] = useState(0)
  const [comment, setComment] = useState('')
  const [isPrint, setIsPrint] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const locale = useSelector((state) => state.store.language.code)
  const { warrantyType, totals, products } = useSelector((state) => state.cart)
  const { language, currency } = useSelector((state) => state.store)
  const { domain } = useContext(ConfigContext)
  const options = [
    {
      title: 'Spare parts guarantee (0% surcharge)',
      warrantyType: 'sparepart',
    },
    {
      title: '1 year full guarantee (5% surcharge)',
      warrantyType: 'fulloneyear',
    },
    {
      title: '2 year full guarantee (10% surcharge)',
      warrantyType: 'fulltwoyear',
    },
  ]
  const getShareCart = async () => {
    const res = await cartService.shareCart({ comment })
    if (res?.result) {
      setRes({
        ...res.result,
        share_url: `${domain}${localRoute(`shared-cart/${res.result.share_code}`)}`,
      })
    }
  }
  useOnClickOutside(share, () => setIsShare(false))

  const printMicroCart = async () => {
    const divContents = document.getElementById('print-cart').innerHTML

    // iframe oluştur
    const iframe = document.createElement('iframe')
    iframe.style.display = 'none'
    document.body.appendChild(iframe)

    // iframe içeriğine yazdırılacak içeriği ekle
    iframe.contentDocument.write('<!DOCTYPE html><html><head>')
    iframe.contentDocument.write(
      '<script src="https://unpkg.com/@tailwindcss/browser@4"></script>',
    )
    iframe.contentDocument.write('</head><body>')
    iframe.contentDocument.write(divContents)
    iframe.contentDocument.write('</body></html>')
    iframe.contentDocument.close()

    // Yazdırma işlemi
    setTimeout(() => {
      iframe.contentWindow.focus()
      iframe.contentWindow.print()
      setIsLoading(false)
    }, 2000)
  }

  useEffect(() => {
    setIsLoading(true)
    if (isPrint) {
      setTimeout(() => {
        printMicroCart()
      }, 1000)
    }
  }, [isPrint])

  const copyToClipboard = () => {
    navigator.clipboard.writeText(res.share_url)
    dispatch(
      setNotification({
        type: 'success',
        message: i18n.t(['Copy to clipboard']),
      }),
    )
  }
  return (
    <>
      <div
        className="fixed left-1/2 top-1/2 z-10 w-[620px] max-w-[90vw] -translate-x-1/2 -translate-y-1/2 border-2 bg-white p-5"
        ref={share}
      >
        <Text variant="body" className="relative pb-9 text-[22px] font-bold">
          {i18n.t('Share cart')}
        </Text>
        {res?.share_url ? (
          <div className="z-50">
            <Text variant="body" className="pl-3">
              {i18n.t('Share URL')}
            </Text>
            <div className="flex">
              <div className="relative w-full">
                <Input
                  type="text"
                  className="rounded-l border-[#9D9D9D]"
                  source
                  currentValue={res.share_url}
                />
              </div>
              <Button
                onClick={copyToClipboard}
                className="min-w-fit max-w-min cursor-pointer rounded-l-none rounded-r border bg-white p-2"
              >
                <Icon icon="icon-content_copy" className="text-xl" />
              </Button>
            </div>
            <div className="flex flex-col items-center py-4">
              <img
                src={`data:image/jpeg;base64, ${res.qr_code}`}
                alt="qr_code"
                width={120}
                height={120}
                className="aspect-square max-h-[120px] max-w-[120px]"
              />
              <Text variant="body" className="pt-3 text-base text-[#9d9d9d]">
                {`${i18n.t('Available until')}: ${new Date(
                  res.available_until,
                ).toLocaleDateString(locale, {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}`}
              </Text>
            </div>
            <Button
              variant={isPrint ? 'gray' : 'primary'}
              className="mb-3 py-2 text-base"
              onClick={() => setIsPrint(true)}
              disabled={isLoading}
            >
              {isLoading ? <Spinner /> : i18n.t('Print')}
            </Button>
          </div>
        ) : (
          <>
            <Text
              variant="body"
              className="pb-3 text-xl font-bold text-[#9d9d9d]"
            >
              {i18n.t('Comment')}
            </Text>
            <Textarea
              rows={5}
              maxLength={254}
              className="w-full p-2.5 outline-none focus:border-gray-300"
              onBlur={(e) => setComment(e.target.value)}
              onChange={(e) => setCount(e.target.value.length)}
            />
            <Text
              variant="inlineText"
              className="absolute right-6 top-[116px] text-sm text-[#9d9d9d]"
            >
              {count}/254
            </Text>
            <Button
              variant="primary"
              className="mt-8 py-2 text-base"
              onClick={() => getShareCart()}
            >
              {i18n.t('Request share code')}
            </Button>
          </>
        )}

        <span
          className="absolute right-5 top-4 z-[11] cursor-pointer text-lg font-bold"
          onClick={() => setIsShare(false)}
        >
          &#10005;
        </span>
      </div>
      {isPrint && (
        <div className="hidden print:block">
          <PrintCart
            products={products}
            options={options}
            warrantyType={warrantyType}
            totals={totals}
            language={language}
            currency={currency}
            qr_code={res?.qr_code || null}
          />
        </div>
      )}
    </>
  )
}

export default ShareCart
