import { useState } from 'react'
import Select from '@components/ui/Select'
import PropTypes from 'prop-types'
import cn from '@utils/helpers/cn'

const Quantity = ({ current, callback, className }) => {
  const [isSelectbox, setIsSelectbox] = useState(current < 10)
  const handleChange = (e) => {
    let qtyValue = parseInt(e)
    if (!qtyValue || (qtyValue && qtyValue < 1)) {
      qtyValue = 1
    }

    if (isSelectbox && qtyValue > 9) {
      setIsSelectbox(false)
    }

    callback(qtyValue)
  }

  return (
    <div className={cn('print:hidden', className)}>
      {isSelectbox ? (
        <Select
          name="qty"
          callback={handleChange}
          selected={current}
          options={[...Array(10)].map((_, index) => ({
            label: index === 9 ? '10+' : index + 1,
            value: index + 1,
          }))}
          className="w-[65px] print:hidden"
        />
      ) : (
        <div className="flex items-center">
          <input
            type="number"
            min="1"
            max="99"
            value={current}
            onChange={(e) => handleChange(e.target.value)}
            className="quantity w-[60px] rounded-sm border border-[#C7C7C7] px-2 py-1 text-base outline-0 hover:border-black focus:border-black focus:outline-black"
          />
        </div>
      )}
    </div>
  )
}

Quantity.propTypes = {
  current: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  callback: PropTypes.func,
}

export default Quantity
