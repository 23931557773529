export const loadState = () => {
  try {
    if (typeof localStorage === 'undefined') return undefined
    const serializedState = localStorage.getItem('ggmgastro')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = (state) => {
  try {
    if (typeof localStorage !== 'undefined') {
      const { cart, user, checkout } = state
      const serializedState = JSON.stringify({ cart, user, checkout })
      localStorage.setItem('ggmgastro', serializedState)
    }
  } catch (err) {
    console.log(err)
  }
}
