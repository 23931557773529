import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { thunk } from 'redux-thunk'
import { HYDRATE, createWrapper } from 'next-redux-wrapper'

import userReducer from '@features/user/userSlice'
import storeReducer from '@features/store/storeSlice'
import categoryReducer from '@features/category/categorySlice'
import cartReducer from '@features/cart/cartSlice'
import uiReducer from '@features/ui/uiSlice'
import seoReducer from '@features/seo/seoSlice'
import attributeReducer from '@features/attribute/attributeSlice'
import currencyReducer from '@features/currency/currencySlice'
import taxReducer from '@features/tax/taxSlice'
import checkoutReducer from '@features/checkout/checkoutSlice'
import searchReducer from '@features/search/searchSlice'

import { loadState } from '@features/local-storage'
import countriesData from '@utils/json/countries.json'
import formatPrice from '@utils/helpers/price'

import { sendGTMEvent } from '@next/third-parties/google'
import googleAnalyticsProducts from '@utils/helpers/googleAnalyticsProducts'

// eslint-disable-next-line no-unused-vars
const lastAction = (state = null, action) => {
  return action
}

const rootReducer = combineReducers({
  user: userReducer,
  store: storeReducer,
  category: categoryReducer,
  cart: cartReducer,
  ui: uiReducer,
  seo: seoReducer,
  attribute: attributeReducer,
  currency: currencyReducer,
  tax: taxReducer,
  checkout: checkoutReducer,
  search: searchReducer,
  lastAction,
})

const masterReducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      store: {
        ...state.store,
        ...action.payload.store,
      },
    }
    return nextState
  } else {
    return rootReducer(state, action)
  }
}

const preloadedState = loadState()

export const store = configureStore({
  reducer: masterReducer,
  preloadedState,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk, serializableCheck: false }),
})

store.subscribe(() => {
  const defaultState = store.getState()
  const { meta = {}, payload = {}, type = '' } = defaultState.lastAction
  const breadcrumbs = defaultState.seo.breadcrumbs || []
  if (type === 'cart/addToCart/fulfilled') {
    let qty = meta?.arg?.qty || 1
    if (meta?.arg?.force) {
      qty = meta?.arg?.qty - payload.result.prev_qty
    }
    sendGTMEvent({
      event: qty > 0 ? 'add_to_cart' : 'remove_from_cart',
      ecommerce: {
        currency: defaultState.store.currency.code.toUpperCase(),
        value: parseFloat(
          (payload.result.storePrice?.price ||
            payload.result.totals?.price ||
            payload.result.price) * Math.abs(qty),
        ).toFixed(2),
        items: googleAnalyticsProducts(
          [{ _source: { ...payload.result, qty: Math.abs(qty) } }],
          breadcrumbs,
          0,
          defaultState.store.country.code.toUpperCase(),
        ),
      },
    })
  }

  if (type === 'cart/deleteItem/fulfilled') {
    sendGTMEvent({
      event: 'remove_from_cart',
      ecommerce: {
        currency: defaultState.store.currency.code.toUpperCase(),
        value: parseFloat(
          formatPrice(meta.arg.product.totals.row_total, false, false),
        ).toFixed(2),
        items: googleAnalyticsProducts(
          [{ _source: meta.arg.product }],
          breadcrumbs,
          0,
          defaultState.store.country.code.toUpperCase(),
        ),
      },
    })
  }

  if (type === 'cart/clear/fulfilled') {
    sendGTMEvent({
      event: 'remove_from_cart',
      ecommerce: {
        currency: defaultState.store.currency.code.toUpperCase(),
        value:
          payload.prevCart.totals?.total_segments?.find(
            (i) => i.code === 'subtotal',
          )?.value || 0,
        items: googleAnalyticsProducts(
          payload.prevCart.products.map((product) => ({ _source: product })),
          breadcrumbs,
          0,
          defaultState.store.country.code.toUpperCase(),
        ),
      },
    })
  }

  if (type === 'checkout/resetCheckout' && !payload.withoutLastOrder) {
    let shipping = 0
    if (
      defaultState?.cart?.totals?.total_segments?.find(
        (i) => i.code === 'shipping',
      )?.value
    ) {
      shipping = defaultState?.cart?.totals?.total_segments?.find(
        (i) => i.code === 'shipping',
      )?.value
    }

    if (
      defaultState?.cart?.totals?.total_segments?.find(
        (i) => i.code === 'toll_surcharge',
      )?.value
    ) {
      shipping =
        shipping +
        defaultState?.cart?.totals?.total_segments?.find(
          (i) => i.code === 'toll_surcharge',
        )?.value
    }
    sendGTMEvent({
      event: 'begin_checkout',
      ecommerce: {
        currency: defaultState.store.currency.code.toUpperCase(),
        value:
          defaultState?.cart?.totals?.total_segments?.find(
            (i) => i.code === 'grand_total',
          )?.value || 0,
        tax:
          defaultState?.cart?.totals?.total_segments?.find(
            (i) => i.code === 'tax',
          )?.value || 0,
        shipping: shipping,
        discount: Math.abs(
          defaultState?.cart?.totals?.total_segments?.find(
            (i) => i.code === 'discount',
          )?.value || 0,
        ),
        coupon: defaultState.cart.totals.coupon_code || '',
        items: googleAnalyticsProducts(
          defaultState.cart.products.map((product) => ({ _source: product })),
          breadcrumbs,
          0,
          defaultState.store.country.code.toUpperCase(),
        ),
      },
    })
  }

  if (type === 'checkout/resetCheckout' && payload.withoutLastOrder) {
    const lastOrder = defaultState.checkout.lastOrder

    const userDate = new Date(lastOrder.personalDetails.created_at)
    const currentDate = new Date()
    const diffDays = Math.ceil(
      Math.abs(currentDate - userDate) / (1000 * 60 * 60 * 24),
    )
    let shipping = 0
    if (
      lastOrder?.totals?.total_segments?.find((i) => i.code === 'shipping')
        ?.value
    ) {
      shipping = lastOrder?.totals?.total_segments?.find(
        (i) => i.code === 'shipping',
      )?.value
    }

    if (
      lastOrder?.totals?.total_segments?.find(
        (i) => i.code === 'toll_surcharge',
      )?.value
    ) {
      shipping =
        shipping +
        lastOrder?.totals?.total_segments?.find(
          (i) => i.code === 'toll_surcharge',
        )?.value
    }

    sendGTMEvent({
      event: 'purchase',
      ecommerce: {
        transaction_id: lastOrder.id,
        currency: defaultState.store.currency.code.toUpperCase(),
        value:
          lastOrder.totals.total_segments.find((i) => i.code === 'grand_total')
            ?.value || 0,
        tax:
          lastOrder.totals.total_segments.find((i) => i.code === 'tax')
            ?.value || 0,
        shipping: shipping,
        discount: Math.abs(
          lastOrder.totals.total_segments.find((i) => i.code === 'discount')
            ?.value || 0,
        ),
        coupon: lastOrder.totals.coupon_code || '',
        customer_type: diffDays > 1 ? 'returning' : 'new',
        items: googleAnalyticsProducts(
          lastOrder.products.map((product) => ({
            _source: { ...product, price: product.totals.price },
          })),
          breadcrumbs,
          0,
          defaultState.store.country.code.toUpperCase(),
        ),
      },
      customer: {
        email: lastOrder.personalDetails.email,
        phone_number: `+${
          countriesData.find(
            (c) =>
              c.code.toLowerCase() ===
              lastOrder?.shippingDetails?.mobilePhoneNumberCountryCode?.toLowerCase(),
          )?.phone || '49'
        }${lastOrder.shippingDetails.mobilePhoneNumber}`,
        address: {
          first_name: lastOrder.shippingDetails.firstname,
          last_name: lastOrder.shippingDetails.lastname,
          street: `${lastOrder.shippingDetails.street} ${lastOrder.shippingDetails.houseNumber}`,
          city: lastOrder.shippingDetails.city,
          postal_code: lastOrder.shippingDetails.postcode,
          country: lastOrder.shippingDetails.countryId,
        },
      },
    })
  }

  if (type === 'checkout/setPaymentDetails') {
    let shipping = 0
    if (
      defaultState?.cart?.totals?.total_segments?.find(
        (i) => i.code === 'shipping',
      )?.value
    ) {
      shipping = defaultState?.cart?.totals?.total_segments?.find(
        (i) => i.code === 'shipping',
      )?.value
    }

    if (
      defaultState?.cart?.totals?.total_segments?.find(
        (i) => i.code === 'toll_surcharge',
      )?.value
    ) {
      shipping =
        shipping +
        defaultState?.cart?.totals?.total_segments?.find(
          (i) => i.code === 'toll_surcharge',
        )?.value
    }
    sendGTMEvent({
      event: 'add_billing_address',
      ecommerce: {
        currency: defaultState.store.currency.code.toUpperCase(),
        value:
          defaultState?.cart?.totals?.total_segments?.find(
            (i) => i.code === 'grand_total',
          )?.value || 0,
        tax:
          defaultState?.cart?.totals?.total_segments?.find(
            (i) => i.code === 'tax',
          )?.value || 0,
        shipping: shipping,
        discount: Math.abs(
          defaultState?.cart?.totals?.total_segments?.find(
            (i) => i.code === 'discount',
          )?.value || 0,
        ),
        coupon: defaultState.cart.totals.coupon_code || '',
        items: googleAnalyticsProducts(
          defaultState.cart.products.map((product) => ({ _source: product })),
          breadcrumbs,
          0,
          defaultState.store.country.code.toUpperCase(),
        ),
      },
    })
  }

  if (type === 'checkout/setShippingDetails') {
    let shipping = 0
    if (
      defaultState?.cart?.totals?.total_segments?.find(
        (i) => i.code === 'shipping',
      )?.value
    ) {
      shipping = defaultState?.cart?.totals?.total_segments?.find(
        (i) => i.code === 'shipping',
      )?.value
    }

    if (
      defaultState?.cart?.totals?.total_segments?.find(
        (i) => i.code === 'toll_surcharge',
      )?.value
    ) {
      shipping =
        shipping +
        defaultState?.cart?.totals?.total_segments?.find(
          (i) => i.code === 'toll_surcharge',
        )?.value
    }
    sendGTMEvent({
      event: 'add_shipping_info',
      ecommerce: {
        currency: defaultState.store.currency.code.toUpperCase(),
        value:
          defaultState?.cart?.totals?.total_segments?.find(
            (i) => i.code === 'grand_total',
          )?.value || 0,
        tax:
          defaultState?.cart?.totals?.total_segments?.find(
            (i) => i.code === 'tax',
          )?.value || 0,
        shipping: shipping,
        discount: Math.abs(
          defaultState?.cart?.totals?.total_segments?.find(
            (i) => i.code === 'discount',
          )?.value || 0,
        ),
        coupon: defaultState.cart.totals.coupon_code || '',
        shipping_tier:
          defaultState.checkout.shippingMethod?.code || 'uninsured',
        items: googleAnalyticsProducts(
          defaultState.cart.products.map((product) => ({ _source: product })),
          breadcrumbs,
          0,
          defaultState.store.country.code.toUpperCase(),
        ),
      },
    })
  }

  if (type === 'checkout/setPaymentMethod') {
    let shipping = 0
    if (
      defaultState?.cart?.totals?.total_segments?.find(
        (i) => i.code === 'shipping',
      )?.value
    ) {
      shipping = defaultState?.cart?.totals?.total_segments?.find(
        (i) => i.code === 'shipping',
      )?.value
    }

    if (
      defaultState?.cart?.totals?.total_segments?.find(
        (i) => i.code === 'toll_surcharge',
      )?.value
    ) {
      shipping =
        shipping +
        defaultState?.cart?.totals?.total_segments?.find(
          (i) => i.code === 'toll_surcharge',
        )?.value
    }
    sendGTMEvent({
      event: 'add_payment_info',
      ecommerce: {
        currency: defaultState.store.currency.code.toUpperCase(),
        value:
          defaultState?.cart?.totals?.total_segments?.find(
            (i) => i.code === 'grand_total',
          )?.value || 0,
        tax:
          defaultState?.cart?.totals?.total_segments?.find(
            (i) => i.code === 'tax',
          )?.value || 0,
        shipping: shipping,
        discount: Math.abs(
          defaultState?.cart?.totals?.total_segments?.find(
            (i) => i.code === 'discount',
          )?.value || 0,
        ),
        coupon: defaultState.cart.totals.coupon_code || '',
        payment_type: payload.code,
        items: googleAnalyticsProducts(
          defaultState.cart.products.map((product) => ({ _source: product })),
          breadcrumbs,
          0,
          defaultState.store.country.code.toUpperCase(),
        ),
      },
    })
  }
})

export const makeStore = () => store

export const wrapper = createWrapper(makeStore, { debug: false })
