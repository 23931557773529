import PropTypes from 'prop-types'
import cn from '@utils/helpers/cn'
const Container = ({ className, children, ...props }) => {
  return (
    <div className={cn('px-2 2xl:container lg:px-4', className)} {...props}>
      {children}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.node,
}

export default Container
