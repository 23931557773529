import { apiPost } from '@utils/api'
import { store } from '@features/store'

const deleteItem = async (item) => {
  const storeCode = store.getState().store.language.code
  const userToken = store.getState().user?.token
  const body = {
    cartItem: {
      quote_id: item.quoteId,
      item_id: item.product.item_id,
      sku: item.product.sku,
    },
  }

  const res = await apiPost(
    `cart/delete?token=${userToken ? userToken : ''}&cartId=${
      item.quoteId
    }&storeCode=${storeCode}`,
    body
  )
  return res
}

export default deleteItem
