import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import Icon from '@components/ui/Icon'
import cn from '@utils/helpers/cn'
import { useRouter } from 'next/router'
import Link from '@components/ui/Link'
import { useDispatch, useSelector } from 'react-redux'
import { setLoginModal, setIsSearchbarFocus } from '@features/ui/uiSlice'
import { useEffect, useState } from 'react'
const MobileAccountModal = dynamic(
  () => import('@components/blocks/Layout/Layout-ui/MobileAccountModal'),
  { ssr: false },
)

const BottomNavigator = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const route = router.asPath
  const { isLoginModal } = useSelector((state) => state.ui)
  const { language, country, currency } = useSelector((state) => state.store)
  const [isMobileAccountModal, setIsMobileAccountModal] = useState(false)
  const { products } = useSelector((state) => state.cart)
  const { currentPage } = useSelector((state) => state.seo)
  const { current } = useSelector((state) => state.user)
  const [productCount, setProductCount] = useState(0)
  const currentRoute = `/${language.code}-${country.code}-${currency.code}`
  useEffect(() => {
    let count = 0
    products.forEach((product) => {
      count += product.qty
    })
    setProductCount(count)
  }, [products])
  return (
    <>
      <div className="nav-safearea fixed bottom-0 z-10 flex w-full items-center justify-around border-t border-[#c7c7c7] bg-white lg:hidden print:hidden">
        <Link
          locale
          href=""
          className="flex cursor-pointer flex-col items-center"
        >
          <span
            className={cn({
              'absolute -top-[1px] h-1 w-12 bg-[#b90202]':
                route === currentRoute,
            })}
          />
          <Icon
            icon="icon-home"
            className={cn('text-[26px]', {
              'pointer-events-none max-w-max text-[#b90202]':
                route === currentRoute,
            })}
          />
        </Link>
        <div
          onClick={() => dispatch(setIsSearchbarFocus(true))}
          className="flex cursor-pointer flex-col items-center"
        >
          <span
            className={cn({
              'absolute -top-[1px] h-1 w-12 bg-[#b90202]':
                route === currentRoute + '/categorylist' ||
                currentPage === 'product' ||
                currentPage === 'category',
            })}
          />
          <Icon
            icon="icon-search"
            className={cn('text-[20px]', {
              'pointer-events-none max-w-max text-[#b90202]':
                route === currentRoute + '/categorylist' ||
                currentPage === 'product' ||
                currentPage === 'category',
            })}
          />
        </div>
        <span
          className={cn('flex cursor-pointer flex-col items-center', {
            'pointer-events-none': current && isMobileAccountModal,
          })}
          onClick={() =>
            current
              ? setIsMobileAccountModal(true)
              : dispatch(setLoginModal(true))
          }
        >
          <span
            className={cn({
              'absolute -top-[1px] h-1 w-12 bg-[#b90202]':
                route === `${currentRoute}/my-account` || isLoginModal === true,
            })}
          />
          <Icon
            icon="icon-user"
            className={cn('text-[20px]', {
              'pointer-events-none max-w-max text-[#b90202]':
                route === `${currentRoute}/my-account` || isLoginModal === true,
            })}
          />
        </span>

        <Link
          href={currentRoute + '/cart'}
          className="flex cursor-pointer flex-col items-center"
        >
          <span
            className={cn({
              'absolute -top-[1px] h-1 w-12 bg-[#b90202]':
                route === currentRoute + '/cart',
            })}
          />
          <Icon
            icon="icon-local_grocery_store"
            className={cn('relative text-[26px]', {
              'pointer-events-none max-w-max text-[#b90202]':
                route === currentRoute + '/cart',
            })}
          >
            {productCount > 0 && (
              <span className="absolute left-[18px] top-[-6px] flex min-h-[18px] min-w-[18px] items-center justify-center rounded-full bg-[#b90202] font-roboto text-xs font-semibold text-white">
                {productCount}
              </span>
            )}
          </Icon>
        </Link>
      </div>
      {isMobileAccountModal && current && (
        <MobileAccountModal
          setModal={setIsMobileAccountModal}
          route={route}
          currentRoute={currentRoute}
        />
      )}
    </>
  )
}

BottomNavigator.propTypes = {
  language: PropTypes.string,
  country: PropTypes.string,
  currency: PropTypes.string,
}
export default BottomNavigator
