import { apiPost } from '@utils/api'
import { store } from '@features/store'
import { appConfig } from '@utils/appConfig'
import { attributeService } from '@utils/magento2'
import bodybuilder from 'bodybuilder'

const getProductsByCategoryId = async (
  id,
  locale = 'de',
  country = 'de',
  query = {},
  categoryFilter = [],
  attributes = [],
) => {
  if (!id) {
    return []
  }

  const sort = query?.sort || appConfig.defaultSortParams.value
  const from = query?.page ? 20 * (query.page - 1) : 0

  const filter = [
    ...categoryFilter?.map((item) => item.attribute_code),
    'price',
  ]

  const attributeList = await attributeService.getAttribute(locale, attributes)

  const salesBlacklistAttribute =
    attributeList
      ?.find((a) => a.attribute_code === 'sales_blacklist')
      ?.options?.find(
        (o) => o.label.toLowerCase() === country.toLocaleLowerCase(),
      )?.value || null

  const filterQuery = {}

  Object.keys(query)
    .filter((key) => filter.includes(key))
    .forEach((key) => (filterQuery[key] = query[key]?.split(',')))

  let requestBodyData = bodybuilder().filter('bool', (b) =>
    b.filter('bool', 'must', [
      { terms: { visibility: [2, 3, 4] } },
      { terms: { status: [0, 1] } },
      { terms: { category_ids: Array.isArray(id) ? id : [id] } },
    ]),
  )
  filter.forEach((item) => {
    const attribute = attributes?.find((a) => a.attribute_code === item) || {}

    if (
      item === 'price' ||
      attribute?.layer_navigation_type === 'range_slider'
    ) {
      requestBodyData = requestBodyData
        .aggregation('min', item, `agg_terms_${item}_min`)
        .aggregation('max', item, `agg_terms_${item}_max`)
    } else {
      requestBodyData = requestBodyData.aggregation(
        'terms',
        item,
        `agg_terms_${item}`,
        {
          size: 100,
        },
      )
    }
  })

  if (salesBlacklistAttribute) {
    requestBodyData = requestBodyData.notFilter(
      'term',
      'sales_blacklist',
      salesBlacklistAttribute,
    )
  }

  const requestBody = requestBodyData.build()

  if (Object.keys(filterQuery).length > 0) {
    requestBody.post_filter = {
      bool: {
        should: [
          {
            bool: {
              must: [
                ...Object.keys(filterQuery).map((item) => {
                  const attribute =
                    attributes.find((a) => a.attribute_code === item) || {}
                  if (
                    item === 'price' ||
                    attribute?.layer_navigation_type === 'range_slider'
                  ) {
                    return {
                      range: {
                        [item]: {
                          gte: filterQuery[item][0],
                          lte: filterQuery[item][1],
                        },
                      },
                    }
                  }

                  return {
                    terms: { [item]: filterQuery[item] },
                  }
                }),
              ],
            },
          },
          {
            bool: {
              must: [
                ...Object.keys(filterQuery).map((item) => {
                  const attribute =
                    attributes.find((a) => a.attribute_code === item) || {}
                  if (
                    item === 'price' ||
                    attribute?.layer_navigation_type === 'range_slider'
                  ) {
                    return {
                      range: {
                        [item]: {
                          gte: filterQuery[item][0],
                          lte: filterQuery[item][1],
                        },
                      },
                    }
                  }

                  return {
                    terms: { [item]: filterQuery[item] },
                  }
                }),
                { match: { type_id: 'configurable' } },
              ],
            },
          },
        ],
        minimum_should_match: 1,
      },
    }
  }

  if (sort.includes('deliverytime.normalized')) {
    requestBody.sort = [
      {
        'deliverytime.normalized': {
          order: 'asc',
          mode: 'avg',
          nested: {
            path: 'deliverytime',
            filter: {
              term: {
                'deliverytime.country_id': country.toUpperCase(),
              },
            },
          },
        },
      },
    ]
  }

  const sourceInclude = [
    'activity',
    'type_id',
    '*sku',
    'product_links',
    'tax_class_id',
    'special_price',
    'special_to_date',
    'special_from_date',
    'name',
    'price',
    'price_incl_tax',
    'original_price_incl_tax',
    'original_price',
    'special_price_incl_tax',
    'rrp',
    'old_price',
    'id',
    'image',
    'sale',
    'new',
    'url_path',
    'url_key',
    'status',
    'tier_prices',
    'custom_options',
    'configurable_children.sku',
    'configurable_children.price',
    'configurable_children.special_price',
    'configurable_children.price_incl_tax',
    'configurable_children.special_price_incl_tax',
    'configurable_children.original_price',
    'configurable_children.original_price_incl_tax',
    '*image',
    '*small_image',
    'configurable_children.color',
    'configurable_children.size',
    'configurable_children.tier_prices',
    'final_price',
    'configurable_children.final_price',
    'short_description',
    'info',
    'stock',
    'errors',
    'net_dimensions',
    'sales_blacklist',
    'order_in_shop',
    'shipping_method',
    'average_review',
    'average_review_count',
    'sales_price_*',
    'capacity',
    'gross_width',
    'gross_depth',
    'net_width',
    'net_depth',
    'net_height',
    'recommended_filling_quantity',
    'number_of_doors',
    'number_of_pizzas_per_chamber',
    'number_of_burners',
    'number_of_drawers',
    'show_on_storefront',
    'new_article',
    'show_on_storefront_customers_recommend',
    'no_leasing',
    'request_item',
    'display_mode_akeneo',
    'cms_content_page',
    'category',
    'deliverytime',
    'product_label',
    'number_of_baking_chambers',
    'temperature_range_to',
    'fully_chamotte_lined',
    'power',
    'number_of_pizzas_per_hour_per_baking_chamber',
    'oven_type',
    'special_features',
    'action',
    'quality_level',
    'second_choice',
    'bynder_images',
    'bynder_images_nested',
  ]

  const elasticsearch = locale
    ? appConfig.elasticsearch[locale]
    : store.getState().store.elasticsearch

  const res = await apiPost(
    `catalog/${elasticsearch}/product/_search?_source_exclude=sgn&_source_include=${sourceInclude.join(
      ',',
    )}&from=${from}&size=20&sort=${sort}`,
    JSON.stringify(requestBody),
  )

  let resFilterAggs = res.aggregations

  if (res.hits && res.hits.hits && res.hits.hits.length > 0) {
    const requestBodyFilters = requestBody
    delete requestBodyFilters.post_filter

    if (Object.keys(filterQuery).length > 0) {
      requestBodyFilters.query.bool.filter.bool.should = [
        {
          bool: {
            must: [
              ...Object.keys(filterQuery).map((item) => {
                const attribute =
                  attributes.find((a) => a.attribute_code === item) || {}
                if (
                  item === 'price' ||
                  attribute?.layer_navigation_type === 'range_slider'
                ) {
                  return {
                    range: {
                      [item]: {
                        gte: filterQuery[item][0],
                        lte: filterQuery[item][1],
                      },
                    },
                  }
                }

                return {
                  terms: { [item]: filterQuery[item] },
                }
              }),
            ],
          },
        },
        {
          bool: {
            must: [
              ...Object.keys(filterQuery).map((item) => {
                const attribute =
                  attributes.find((a) => a.attribute_code === item) || {}
                if (
                  item === 'price' ||
                  attribute?.layer_navigation_type === 'range_slider'
                ) {
                  return {
                    range: {
                      [item]: {
                        gte: filterQuery[item][0],
                        lte: filterQuery[item][1],
                      },
                    },
                  }
                }

                return {
                  terms: { [item]: filterQuery[item] },
                }
              }),
              { match: { type_id: 'configurable' } },
            ],
          },
        },
      ]
      requestBodyFilters.query.bool.filter.bool.minimum_should_match = 1

      const resFilter = await apiPost(
        `catalog/${elasticsearch}/product/_search?size=0`,
        JSON.stringify(requestBodyFilters),
      )
      resFilterAggs = resFilter.aggregations
    }

    return {
      products: res.hits.hits,
      total: res.hits.total.value,
      filters: res.aggregations,
      with_filters: resFilterAggs,
    }
  }
  return res
}

export default getProductsByCategoryId
