import Icon from '@components/ui/Icon'
import { useState } from 'react'
import cn from '@utils/helpers/cn'
import PropTypes from 'prop-types'

const Input = ({
  type = 'text',
  currentValue,
  useLabel = false,
  name,
  id,
  className,
  placeholder,
  password,
  innerRef = null,
  register = null,
  error = null,
  validation = {},
  isFullWidth = false,
  ...props
}) => {
  const [value, setValue] = useState(currentValue)
  const [visible, setVisible] = useState(false)
  const formData = () => {
    if (typeof register === 'function') {
      return {
        ...register(name, validation),
        ...props,
      }
    }
    return props
  }
  return (
    <div className={cn('relative', { 'w-full': isFullWidth })}>
      <input
        type={password ? (visible ? 'text' : 'password') : type}
        className={cn(
          'peer w-full appearance-none rounded-sm border border-[#C7C7C7] p-2.5 text-base outline-0 hover:border-[#000] focus:border-[#000]',
          {
            'border-red-500': error && error?.message,
            'focus:border-red-500': error && error?.message,
            'hover:border-red-500': error && error?.message,
          },
          className,
        )}
        id={id}
        placeholder={!useLabel ? ' ' : placeholder}
        ref={innerRef}
        name={name}
        defaultValue={value}
        onChange={(e) => setValue(e.target.value)}
        {...formData()}
      />
      {password && (
        <Icon
          icon={visible ? 'icon-remove_red_eye' : 'icon-visibility_off'}
          className={cn(
            'absolute right-3 top-2 cursor-pointer text-xl text-[#c7c7c7] hover:text-[#282d34]',
            {
              'text-[#282d34]': visible,
            },
          )}
          onClick={() => setVisible(!visible)}
        />
      )}
      {!useLabel && placeholder?.length > 0 && (
        <label
          htmlFor={id}
          className="pointer-events-none absolute left-0 top-0 -translate-y-5 px-2 text-sm text-gray-400 duration-300 peer-placeholder-shown:translate-y-[3px] peer-focus:-translate-y-5"
        >
          {placeholder}
        </label>
      )}
      {error && error?.message && (
        <span className="text-xs text-red-500">{error.message}</span>
      )}
    </div>
  )
}

Input.propTypes = {
  type: PropTypes.string,
  password: PropTypes.bool,
  useLabel: PropTypes.bool,
  placeholder: PropTypes.any,
  id: PropTypes.string,
  currentValue: PropTypes.any,
  useLabel: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  password: PropTypes.bool,
  innerRef: PropTypes.object,
  error: PropTypes.object,
  validation: PropTypes.object,
}

export default Input
