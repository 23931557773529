import { apiPost } from '@utils/api'
import { store } from '@features/store'
import { actionCreate } from '@features/cart/cartSlice'

const adyenPaymentMethods = async (token, locale, storeCode) => {
  const cartId = store.getState().cart.id
  const payload = {
    cart_id: cartId,
    shopperLocale: locale,
    country: storeCode.toUpperCase(),
  }

  const endpoint = `ext/ggm-adyen-payment/methods/${cartId}?token=${token}`

  try {
    const res = await apiPost(endpoint, payload)

    if (res.code === 200) {
      const result = JSON.parse(res.result)
      return [
        {
          paymentMethods: result.paymentMethodsResponse.paymentMethods,
        },
        {
          ...result.paymentMethodsExtraDetails,
        },
      ]
    }
  } catch (error) {
    console.error('fetch payment methods failed')
  }

  await store.dispatch(actionCreate(token)).unwrap()

  const cartIdNew = store.getState().cart.id
  const payloadNew = {
    cart_id: cartIdNew,
    shopperLocale: locale,
    country: storeCode.toUpperCase(),
  }

  const endpointNew = `ext/ggm-adyen-payment/methods/${cartIdNew}?token=${token}`

  try {
    const res = await apiPost(endpointNew, payloadNew)

    if (res.code === 200) {
      const result = JSON.parse(res.result)
      return [
        {
          paymentMethods: result.paymentMethodsResponse.paymentMethods,
        },
        {
          ...result.paymentMethodsExtraDetails,
        },
      ]
    }
  } catch (error) {
    console.error('fetch payment methods failed')
  }

  return []
}

export default adyenPaymentMethods
