import { apiGet } from '@utils/api'
import { store } from '@features/store'

const pull = async (
  token = store.getState().user.token,
  cartId = store.getState().cart.id,
  storeCode = store.getState().store.language.code
) => {
  const res = await apiGet(
    `cart/pull?token=${token}&cartId=${cartId}&storeCode=${storeCode}`
  )
  return res
}

export default pull
