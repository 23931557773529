import { apiPost } from '@utils/api'
import { store } from '@features/store'
import { checkoutService } from '@utils/magento2'
import { appConfig } from '@utils/appConfig'

const setPaypalExpressCheckout = async () => {
  const storeCode = store.getState().store.language.code
  const currencyCode = 'EUR'
  const cartId = store.getState().cart.id
  const cartItems = store.getState().cart.products
  const cartTotals = store.getState().cart.totals
  const userToken = store.getState().user.token
  const userEmail = store.getState().user.current.email
  const shippingDetails = store.getState().checkout.shippingDetails

  const purchaseUnits = await checkoutService.reserveOrderId()

  // const serviceCodes = [
  //   'warranty',
  //   'island_surcharge',
  //   'toll_surcharge',
  //   'rushorder_option',
  // ]

  let itemTotal = 0
  let taxTotal = 0
  cartItems.forEach((product) => {
    itemTotal = itemTotal + product.totals.base_row_total
    taxTotal = taxTotal + product.totals.base_tax_amount
  })

  const body = JSON.stringify({
    cart_id: cartId,
    brand_name: '',
    locale: appConfig.languages
      .find((code) => code.code === storeCode)
      .locale.replace('-', '_'),
    currency_code: currencyCode,
    email: userEmail,
    purchase_units: [
      {
        reference_id: purchaseUnits.result,
        description:
          'Need to return an item? We accept returns for unused items in packaging 60 days after you order',
        items: cartItems.map((item) => ({
          name: item.name,
          unit_amount: {
            currency_code: currencyCode,
            value: item.totals.base_price,
          },
          tax: {
            currency_code: currencyCode,
            value: (
              item.totals.base_price_incl_tax - item.totals.base_price
            ).toFixed(2),
          },
          quantity: item.qty,
          description: '',
          sku: item.sku,
          category: 'PHYSICAL_GOODS',
        })),
        amount: {
          breakdown: {
            item_total: {
              currency_code: currencyCode,
              value: itemTotal.toFixed(2),
            },
            shipping: {
              currency_code: currencyCode,
              value: cartTotals.base_shipping_incl_tax,
            },
            discount: {
              currency_code: currencyCode,
              value: 0,
            },
            tax_total: {
              currency_code: currencyCode,
              value: taxTotal.toFixed(2),
            },
          },
          value: cartTotals.base_grand_total.toFixed(2),
          currency_code: currencyCode,
        },
        shipping: {
          name: {
            full_name: `${shippingDetails.firstname} ${shippingDetails.lastname}`,
          },
          address: {
            address_line_1: shippingDetails.street,
            address_line_2: shippingDetails.houseNumber,
            admin_area_2: shippingDetails.city,
            postal_code: shippingDetails.postcode,
            country_code: shippingDetails.countryId,
          },
        },
      },
    ],
    user_token: userToken,
    email: userEmail,
    return_url: 'https://www.paypal.com/checkoutnow/error',
    cancel_url: 'https://www.paypal.com/checkoutnow/error',
    total_type: 'EstimatedTotal',
    logo: '',
  })

  const res = await apiPost(
    `ext/paypal/setExpressCheckout?storeCode=${storeCode}`,
    body,
  )
  return res
}

export default setPaypalExpressCheckout
