import PropTypes from 'prop-types'
import Text from '@components/ui/Text'
import cn from '@utils/helpers/cn'
import formatPrice from '@utils/helpers/price'
import useI18n from '@utils/hooks/useI18n'
const RRP = ({ oldPrice, type, className, prefix }) => {
  const i18n = useI18n()
  return (
    <Text
      variant="inlineText"
      className={cn(
        'text-start font-sans text-sm font-light text-[#D81100] line-through',
        {
          'text-lg': type === 'productPage',
          '!font-roboto-condensed text-xs !not-italic': type === 'cart',
          '!font-roboto-condensed text-sm !not-italic': type === 'cartSummary',
        },
        className,
      )}
    >
      {`${i18n.t('RRP')} `}
      {formatPrice(
        oldPrice,
        type !== 'cart' &&
          type !== 'cartSummary' &&
          type !== 'cartProduct' &&
          type !== 'productPage' &&
          type !== 'productPageSticky' &&
          type !== 'detailed',
        true,
        prefix || null,
      )}
      {type !== 'wishlist' &&
        type !== 'detailed' &&
        type !== 'productPage' &&
        type !== 'productPageSticky' && <sup>*</sup>}
    </Text>
  )
}
RRP.propTypes = {
  oldPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
}

export default RRP
