import PropTypes from 'prop-types'
import Text from '@components/ui/Text'
import cn from '@utils/helpers/cn'
import formatPrice from '@utils/helpers/price'

const Price = ({ price, type, className, prefix }) => {
  return (
    <Text
      variant="inlineText"
      className={cn(
        type === 'productPage'
          ? 'product-text-shadow text-[45px] font-bold print:!font-roboto-condensed print:text-[40px] print:not-italic'
          : type === 'cartSummary'
            ? '!font-roboto-condensed text-lg font-bold !not-italic'
            : type === 'wishlist'
              ? 'product-text-shadow mr-2.5 text-xl font-bold sm:text-[28px]'
              : type === 'cart'
                ? '!font-roboto-condensed !text-base font-bold !not-italic'
                : type === 'detailed'
                  ? 'mr-2.5 !font-roboto-condensed text-xl font-bold !not-italic sm:text-[28px]'
                  : type === 'related'
                    ? 'product-text-shadow mt-1.5 text-sm font-bold'
                    : type === 'productPageSticky'
                      ? 'product-text-shadow text-2xl md:text-[40px]'
                      : type === 'cartProduct'
                        ? '!font-roboto-condensed text-[16px] !font-bold !not-italic'
                        : 'product-text-shadow text-2xl font-bold',
        'relative text-start font-barlow font-extrabold italic text-[#332e2e]',
        className,
      )}
    >
      {formatPrice(
        price,
        type !== 'cart' &&
          type !== 'cartSummary' &&
          type !== 'cartProduct' &&
          type !== 'productPage' &&
          type !== 'productPageSticky' &&
          type !== 'detailed',
        true,
        prefix || null,
      )}{' '}
      {(type === 'wishlist' ||
        type === 'storyblok' ||
        type === 'detailed' ||
        type === 'productPage' ||
        type === 'productPageSticky') && (
        <sup
          className={cn('absolute -right-2 top-1', {
            'top-3': type === 'productPage',
          })}
        >
          *
        </sup>
      )}
    </Text>
  )
}
Price.propTypes = {
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
}

export default Price
