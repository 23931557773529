import PropTypes from 'prop-types'
import cn from '@utils/helpers/cn'
import Text from '@components/ui/Text'
import Icon from '@components/ui/Icon'
import Link from '@components/ui/Link'
import Image from '@components/ui/Image'
import useI18n from '@utils/hooks/useI18n'

const GgmApplication = ({ type, activeTabs, handleClick }) => {
  const i18n = useI18n()
  return (
    <span
      className={cn({
        'relative w-1/4 max-lg:w-full lg:pl-2': type === 'footer',
      })}
    >
      <span
        onClick={() => handleClick('app')}
        className={cn(
          {
            'w-full border-b border-red-700 max-lg:border-gray-600 lg:pointer-events-none':
              type === 'footer',
            'mx-3 pb-1': type === 'header',
          },
          'flex cursor-pointer items-baseline justify-between',
        )}
      >
        <Text
          variant="paragraph"
          className={cn(
            {
              'pb-3 font-normal max-lg:pl-2': type === 'footer',
              'pl-1 pt-5 text-base font-medium': type === 'header',
            },
            'uppercase text-white',
          )}
        >
          {i18n.t('GGM Gastro App')}
        </Text>
        <Icon
          icon="icon-chevron-down"
          className={cn(
            {
              'pointer-events-none translate-x-0 rotate-0 pr-2 lg:hidden':
                type === 'footer',
              '-translate-x-2 rotate-180 pr-2':
                type === 'footer' && activeTabs.includes('app'),
              '-translate-x-2 -rotate-90': type === 'header',
            },
            'text-white',
          )}
        />
      </span>
      <span
        className={cn(
          {
            'mx-0 w-full pb-4 pt-3 max-lg:px-2': type === 'footer',
            'p-3 pt-0 max-lg:border-none': type === 'header',
            flex: type === 'footer' && activeTabs.includes('app'),
            'max-lg:hidden': type === 'footer' && !activeTabs.includes('app'),
          },
          'flex gap-2 max-lg:border-b max-lg:border-gray-500',
        )}
      >
        <Link
          href={
            'https://apps.apple.com/app/ggm-gastro-gastronomiebedarf/id1316953857'
          }
          className={cn(
            {
              'max-sm:w-1/3': type === 'header',
              'max-lg:w-[25%] max-sm:w-1/2': type === 'footer',
            },
            'w-1/2 cursor-pointer rounded-lg',
          )}
        >
          <Image
            src="/vsf/svg/app-store/de.svg"
            alt="App Store"
            className="size-full"
            width={180}
            height={55}
          />
        </Link>
        <Image
          src="/vsf/svg/google-play/de.png"
          alt="Google Play Store"
          className={cn(
            {
              'max-sm:w-1/3': type === 'header',
              'max-lg:w-[25%] max-sm:w-1/2': type === 'footer',
            },
            'w-1/2 cursor-pointer rounded-lg',
          )}
          width={180}
          height={55}
        />
      </span>
    </span>
  )
}

GgmApplication.propTypes = {
  type: PropTypes.string,
  handleClick: PropTypes.func,
  activeTabs: PropTypes.array,
}

export default GgmApplication
