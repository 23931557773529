import { store } from '@features/store'
import { apiPost } from '@utils/api'

const deassignCustomer = async (id) => {
  const token = store.getState().user.token
  const body = {
    customerIdToDeassign: id,
  }
  const res = await apiPost(
    `ext/ggm-sales-agency/deassign-customer?token=${token}`,
    body
  )
  return res
}

export default deassignCustomer
