import PropTypes from 'prop-types'
import cn from '@utils/helpers/cn'
const Icon = ({ icon, className, children, ...props }) => {
  return (
    <span className={cn(icon, className)} {...props}>
      {children}
    </span>
  )
}

Icon.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node,
}

export default Icon
