import FullContainer from '@components/ui/FullContainer/FullContainer'
import Link from '@components/ui/Link'
import Section from '@components/ui/Section'
import { actionGetSubCategories } from '@features/category/categorySlice'
import { setScrollLock } from '@features/ui/uiSlice'
import cn from '@utils/helpers/cn'
import useI18n from '@utils/hooks/useI18n'
import { storeView } from '@utils/storeView'
import dynamic from 'next/dynamic'
import Image from '@components/ui/Image'
import PropTypes from 'prop-types'
import { useState, useRef, useEffect, useMemo, useCallback, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useOnClickOutside from '@utils/hooks/useClose'
import { useRouter } from 'next/router'

// Use React.lazy with Suspense in the parent component where this is used
const CategoryOverview = memo(
  dynamic(() => import('@components/blocks/Category/CategoryOverview'), {
    ssr: false,
    loading: () => null,
  }),
)

const CategoryOverviewLevel = memo(
  dynamic(() => import('@components/blocks/Category/CategoryOverviewLevel'), {
    ssr: false,
    loading: () => null,
  }),
)

// Memoized static category images - to minimize re-renders
const CategoryImage = memo(({ icon }) => {
  // Precomputed image URL to prevent recalculation on render
  const imageUrl = useMemo(
    () => `${process.env.NEXT_PUBLIC_IMG_ENDPOINT}media/${icon}`,
    [icon],
  )

  return (
    <Image
      src={imageUrl}
      alt=""
      className="max-md:hidden"
      width={26}
      height={26}
      priority={true}
      unoptimized={false}
      loading="eager"
    />
  )
})

CategoryImage.displayName = 'CategoryImage'

CategoryImage.propTypes = {
  icon: PropTypes.string.isRequired,
}

// Memoized component for additional item images
const AdditionalItemImage = memo(({ icon }) => {
  return (
    <Image
      src={icon}
      alt=""
      width={26}
      height={26}
      priority={true}
      unoptimized={false}
      loading="eager"
    />
  )
})

AdditionalItemImage.displayName = 'AdditionalItemImage'

AdditionalItemImage.propTypes = {
  icon: PropTypes.string.isRequired,
}

// Main MegaMenu component - heavily optimized with memoization
const MegaMenu = memo(
  ({ categories, language }) => {
    const i18n = useI18n()
    const dispatch = useDispatch()
    const router = useRouter()
    const currentRoute = router.query.locales

    // Optimize Redux selectors with equality checks
    const stateStore = useSelector(
      (state) => state.store,
      (prev, next) => prev === next,
    )
    const subCategories = useSelector(
      (state) => state.category.categories,
      (prev, next) => prev === next,
    )

    // Memoize store view data to prevent unnecessary recalculations
    const currentStore = useMemo(() => storeView(stateStore), [stateStore])

    const [activeCategory, setActiveCategory] = useState(null)
    const [isTouch, setIsTouch] = useState(false)

    const megaMenuRef = useRef(null)
    const selectedMenuTimeoutRef = useRef(null)
    const hoverTimeoutRef = useRef(null)

    // Device detection - only runs on mount or language change
    useEffect(() => {
      if (typeof window === 'undefined') return

      // Use more efficient media query API
      const mediaQuery = window.matchMedia('(max-width: 1023px)')

      const updateTouchMode = (event) => {
        setIsTouch(
          event.matches ||
            'ontouchstart' in window ||
            navigator.maxTouchPoints > 0,
        )
      }

      // Set initial value
      updateTouchMode(mediaQuery)

      // Add listener
      mediaQuery.addEventListener('change', updateTouchMode)

      return () => {
        mediaQuery.removeEventListener('change', updateTouchMode)
      }
    }, [language])

    // Cleanup function for all timeouts
    const clearAllTimeouts = useCallback(() => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current)
        hoverTimeoutRef.current = null
      }

      if (selectedMenuTimeoutRef.current) {
        clearTimeout(selectedMenuTimeoutRef.current)
        selectedMenuTimeoutRef.current = null
      }
    }, [])

    // Navigation cleanup with memoization
    const navigateClear = useCallback(() => {
      clearAllTimeouts()
      setActiveCategory(null)
      dispatch(setScrollLock(false))
    }, [dispatch, clearAllTimeouts])

    // Optimized mouseover handler with debouncing
    const handleMouseOver = useCallback(
      (id) => {
        if (isTouch) return // Early return for touch devices

        // Clear any existing hover timeout
        clearAllTimeouts()

        // Add debounce for hover events
        hoverTimeoutRef.current = setTimeout(() => {
          if (id === activeCategory) return // Early return if category already active

          const category = categories.find((cat) => cat.id === id)
          if (category && category.menu_display_mode !== 'subcategory') {
            dispatch(actionGetSubCategories({ id }))
          }
          setActiveCategory(id)
          dispatch(setScrollLock(true))
        }, 300) // Reduced delay for better responsiveness
      },
      [categories, dispatch, isTouch, activeCategory, clearAllTimeouts],
    )

    // Optimized mouseleave handler
    const handleMouseLeave = useCallback(() => {
      if (isTouch) return // Early return for touch devices
      clearAllTimeouts()
      navigateClear()
    }, [isTouch, navigateClear, clearAllTimeouts])

    // Direct action function - separated for code clarity
    const openCategoryMenu = useCallback(
      (id) => {
        const category = categories.find((cat) => cat.id === id)
        if (category && category.menu_display_mode !== 'subcategory') {
          dispatch(actionGetSubCategories({ id }))
        }
        setActiveCategory(id)
        dispatch(setScrollLock(true))
      },
      [categories, dispatch],
    )

    // Optimized click handler
    const handleClick = useCallback(
      (id) => {
        // Clear all timeouts first
        clearAllTimeouts()

        // Toggle behavior - close if already open
        if (id === activeCategory) {
          navigateClear()
          return
        }

        // Different behavior based on device type
        if (isTouch) {
          // Use shorter timeout for better responsiveness
          selectedMenuTimeoutRef.current = setTimeout(() => {
            openCategoryMenu(id)
          }, 300)
        } else {
          // Immediate response for desktop
          openCategoryMenu(id)
        }
      },
      [
        activeCategory,
        isTouch,
        navigateClear,
        clearAllTimeouts,
        openCategoryMenu,
      ],
    )

    // Memoize subcategory computation to avoid recalculation
    const allSubCategories = useMemo(() => {
      // Return a function that calculates subcategories for a specific ID
      return (id) => {
        const category = subCategories[id]

        if (category) {
          // Optimize array operations
          return category.reduce((acc, item) => {
            if (item.children_data?.length) {
              acc.push(...item.children_data)
            }
            return acc
          }, [])
        }

        // Fallback to categories data
        return categories.find((cat) => cat.id === id)?.children_data || []
      }
    }, [subCategories, categories])

    // Handle clicks outside the menu
    useOnClickOutside(megaMenuRef, navigateClear)

    // Category list rendering - memoized to avoid unnecessary recalculation
    const categoryItems = useMemo(() => {
      return categories?.map((category, i) => (
        <li
          className="w-[12.5%] cursor-pointer hover:bg-[#F7F7F7]"
          key={`category-${category.id}-${language}`}
          onMouseEnter={
            !isTouch ? () => handleMouseOver(category.id) : undefined
          }
          onClick={() => handleClick(category.id)}
          onMouseLeave={!isTouch ? handleMouseLeave : undefined}
        >
          <div className="relative h-[37px]">
            <Link
              href={category.url_path}
              locale
              onClick={(e) => {
                // Allow direct navigation on desktop, open submenu on mobile
                if (
                  isTouch &&
                  !(activeCategory && activeCategory === category.id)
                ) {
                  e.preventDefault()
                } else if (isTouch) {
                  navigateClear()
                }
              }}
              className={cn(
                'flex min-h-full items-center overflow-hidden text-ellipsis whitespace-nowrap border-l-[1px] border-[#f7f7f7] px-[5px] xl:px-2.5',
                {
                  'border-b': i < 8,
                },
              )}
            >
              <CategoryImage icon={category.icon} />
              <span className="ml-2 truncate text-sm font-medium text-darkBackground">
                {category.name}
              </span>
            </Link>
          </div>
          {activeCategory && activeCategory === category.id && (
            <div
              className={cn(
                i < 8 ? 'top-9' : 'top-[74px]',
                'submenu-wrapper absolute left-0 z-10 w-full bg-white shadow-lg',
              )}
            >
              <div className="static size-full max-h-[50vh] min-h-[30vh] overflow-y-auto p-8">
                <button
                  onClick={navigateClear}
                  className="absolute right-4 top-4 z-10 block text-xl text-[#9d9d9d]"
                  aria-label="Close menu"
                >
                  &#10005;
                </button>
                <div className="w-full" onClick={navigateClear}>
                  {category.menu_display_mode === 'level2_3' ? (
                    <CategoryOverviewLevel id={category.id} />
                  ) : (
                    <CategoryOverview
                      list={allSubCategories(category.id)}
                      count={allSubCategories(category.id).length}
                      className="lg:grid-cols-8 xl:grid-cols-8"
                      key={`${category.id}-subcategories-${language}`}
                      small
                      menu={true}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </li>
      ))
    }, [
      categories,
      language,
      isTouch,
      activeCategory,
      handleMouseOver,

      handleClick,
      handleMouseLeave,
      navigateClear,
      allSubCategories,
    ])

    // Additional items rendering - memoized
    const additionalItems = useMemo(() => {
      return currentStore.additionalMainNavItems.map((item, i) => (
        <li
          className="w-[12.5%] cursor-pointer hover:bg-[#F7F7F7]"
          key={`additional-${i}-${language}`}
        >
          <div className="relative  h-[37px]">
            <Link
              href={
                item.url.includes('ggmmoebel.com')
                  ? `${item.url}/${currentRoute}`
                  : item.url
              }
              external={item.external}
              locale={item.locale}
              className="flex min-h-full items-center truncate border-l border-[#f7f7f7] px-[5px] xl:px-2.5"
            >
              <AdditionalItemImage icon={item.icon} />
              <span className="ml-2 truncate text-sm font-medium text-darkBackground">
                {i18n.t(item.title)}
              </span>
            </Link>
          </div>
        </li>
      ))
    }, [currentStore.additionalMainNavItems, i18n, language])

    // Main component render
    return (
      <FullContainer className="hidden min-h-[74px] bg-[#e2e2e2] lg:block">
        <Section>
          <ul
            className="relative m-0 flex flex-wrap border-r border-[#f7f7f7] p-0"
            ref={megaMenuRef}
            data-language={language}
          >
            {categoryItems}
            {additionalItems}
          </ul>
        </Section>
      </FullContainer>
    )
  },
  (prevProps, nextProps) => {
    // First check for language change
    if (prevProps.language !== nextProps.language) {
      return false // Always re-render on language change
    }

    // Basic validation
    if (!prevProps.categories || !nextProps.categories) {
      return prevProps.categories === nextProps.categories
    }

    // Quick check for array length
    if (prevProps.categories.length !== nextProps.categories.length) {
      return false
    }

    // Deep shallow comparison of critical properties
    for (let i = 0; i < prevProps.categories.length; i++) {
      if (
        prevProps.categories[i].id !== nextProps.categories[i].id ||
        prevProps.categories[i].name !== nextProps.categories[i].name ||
        prevProps.categories[i].icon !== nextProps.categories[i].icon
      ) {
        return false
      }
    }

    // No changes detected
    return true
  },
)

// Add displayName for better debugging
MegaMenu.displayName = 'MegaMenu'

MegaMenu.propTypes = {
  categories: PropTypes.array.isRequired,
  language: PropTypes.string.isRequired,
}

export default MegaMenu
