import Image from 'next/image'
import { useState, useEffect } from 'react'

// Constants for configuration
const PLACEHOLDER_IMAGE = '/placeholder.png'
const BLUR_PLACEHOLDER =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
const SMALL_DIMENSION_THRESHOLD = 40
const DEFAULT_QUALITY = 75
const DEFAULT_ALT = 'GGM Gastro'

// Image cache implementation
const imageCache = new Map()

/**
 * Normalizes the source URL by replacing multiple consecutive slashes with a single slash
 * @param {string} src - The source URL to normalize
 * @returns {string} - Normalized URL
 */
const normalizeSrc = (src) => {
  return src?.replace(/([^:]\/)\/+/g, '$1')
}

/**
 * Preloads an image and stores it in cache
 * @param {string} src - Image source URL
 * @returns {Promise} - Promise that resolves when image is loaded
 */
const preloadImage = (src) => {
  if (imageCache.has(src)) {
    return Promise.resolve(imageCache.get(src))
  }

  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = src
    img.onload = () => {
      imageCache.set(src, img)
      resolve(img)
    }
    img.onerror = reject
  })
}

/**
 * Enhanced Next.js Image component with error handling, caching and optimizations
 * @param {string} src - Source URL of the image
 * @param {string} [alt=DEFAULT_ALT] - Alt text for the image
 * @param {boolean} [isErrorHide=false] - Whether to hide the image on error
 * @param {number} [quality=DEFAULT_QUALITY] - Quality of the image (1-100)
 * @param {boolean} [priority=false] - Whether the image should be prioritized
 * @param {object} props - Additional props passed to Next.js Image component
 */
const NextImage = ({
  src,
  alt = DEFAULT_ALT,
  isErrorHide = false,
  quality = DEFAULT_QUALITY,
  priority = false,
  ...props
}) => {
  const [isError, setIsError] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  // Normalize src at component initialization
  const normalizedSrc = normalizeSrc(src)

  useEffect(() => {
    if (priority && normalizedSrc) {
      preloadImage(normalizedSrc)
        .then(() => setIsLoaded(true))
        .catch(() => setIsError(true))
    }
  }, [normalizedSrc, priority])

  const handleImageError = (e) => {
    if (isErrorHide) {
      e.target.style.display = 'none'
      return
    }

    if (!isError && !normalizedSrc.endsWith('.svg')) {
      setIsError(true)
      e.target.src = PLACEHOLDER_IMAGE
      e.target.srcset = PLACEHOLDER_IMAGE
    }
  }

  const handleImageLoad = () => {
    setIsLoaded(true)
    if (!imageCache.has(normalizedSrc)) {
      imageCache.set(normalizedSrc, true)
    }
  }

  // Skip placeholder for SVGs and small images
  const shouldUseEmptyPlaceholder =
    normalizedSrc.endsWith('.svg') ||
    (props.width && props.width < SMALL_DIMENSION_THRESHOLD) ||
    (props.height && props.height < SMALL_DIMENSION_THRESHOLD) ||
    priority ||
    imageCache.has(normalizedSrc)

  // Ensure correct dimension handling
  const dimensions = {
    width: props.width || undefined,
    height: props.height || undefined,
    ...(!props.width && !props.height && { fill: true }),
  }

  return (
    <Image
      src={normalizedSrc}
      alt={alt}
      onError={handleImageError}
      onLoad={handleImageLoad}
      quality={quality}
      priority={priority}
      placeholder={shouldUseEmptyPlaceholder ? 'empty' : 'blur'}
      blurDataURL={BLUR_PLACEHOLDER}
      {...dimensions}
      {...props}
      className={`transition-opacity duration-300 ${
        isLoaded ? 'opacity-100' : 'opacity-0'
      } ${props.className || ''}`}
    />
  )
}

export default NextImage
