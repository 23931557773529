import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { attributeService } from '@utils/magento2'

const initialState = {
  byCode: {},
  byId: {},
}

export const actionGetAttribute = createAsyncThunk(
  'attribute/getAttribute',
  async (params) => {
    const locale = params?.locale || 'de'
    const response = await attributeService.getAttribute(locale)
    return { response }
  }
)

export const attributeSlice = createSlice({
  name: 'attribute',
  initialState,
  reducers: {
    resetAttribute: (state) => {
      state.byCode = {}
      state.byId = {}
    },
  },
  extraReducers(builder) {
    builder.addCase(actionGetAttribute.fulfilled, (state, action) => {
      const attributes = action.payload.response
      if (Array.isArray(attributes)) {
        state.byCode = {}
        state.byId = {}
        attributes.forEach((attribute) => {
          state.byCode[attribute.attribute_code] = attribute
          state.byId[attribute.attribute_id] = attribute
        })
      }
    })
  },
})

export const { resetAttribute } = attributeSlice.actions
export default attributeSlice.reducer
