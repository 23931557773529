import { apiPost } from '@utils/api'
import { store } from '@features/store'

const createReview = async (review) => {
  const storeCode = store.getState().store.language.code

  const res = await apiPost(
    `ext/ggm-review/createReview?storeCode=${storeCode}`,
    review
  )
  return res
}

export default createReview
