import { createContext, useState, useRef, useEffect } from 'react'
import rosetta from 'rosetta'
import { appConfig } from '@utils/appConfig'
// import rosetta from 'rosetta/debug';

const i18n = rosetta() || {}

export const defaultLanguage = 'de'
export const languages = appConfig.languages.map((l) => l.code)
export const contentLanguageMap = appConfig.languages.reduce((acc, l) => {
  acc[l.code] = l.locale
  return acc
}, {})

export const I18nContext = createContext()

// default language
i18n.locale(defaultLanguage)

export default function I18n({ children, locale, lngDict }) {
  const activeLocaleRef = useRef(locale || defaultLanguage)
  const [, setTick] = useState(0)
  const firstRender = useRef(true)

  const i18nWrapper = {
    activeLocale: activeLocaleRef.current,
    t: (...args) => {
      const translate = i18n.t(...args)
      if (translate && translate.length > 0) {
        return translate
      }
      return args[0]
    },
    locale: (l, dict) => {
      i18n.locale(l)
      activeLocaleRef.current = l
      if (dict) {
        i18n.set(l, dict)
      }
      // force rerender to update view
      setTick((tick) => tick + 1)
    },
  }

  // for initial SSR render
  if (locale && firstRender.current === true) {
    firstRender.current = false
    i18nWrapper.locale(locale, lngDict)
  }

  // when locale is updated
  useEffect(() => {
    if (locale) {
      i18nWrapper.locale(locale, lngDict)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  return (
    <I18nContext.Provider value={i18nWrapper || {}}>
      {children}
    </I18nContext.Provider>
  )
}
