import { apiPost } from '@utils/api'

const update = async (body, token, storeCode) => {
  const url = `user/me?token=${token}&storeCode=${storeCode}`
  const res = await apiPost(url, body)

  return res
}

export default update
