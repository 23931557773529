import attribute from '@utils/magento2/attribute'
import breadcrumbs from '@utils/magento2/breadcrumbs'
import category from '@utils/magento2/category'
import product from '@utils/magento2/product'
import user from '@utils/magento2/user'
import salesAgency from '@utils/magento2/user/salesAgency'
import cart from '@utils/magento2/cart'
import tax from '@utils/magento2/tax'
import ext from '@utils/magento2/ext'
import checkout from '@utils/magento2/checkout'

// export all services
export const attributeService = attribute
export const breadcrumbsService = breadcrumbs
export const categoryService = category
export const productService = product
export const userService = user
export const salesAgencyService = salesAgency
export const cartService = cart
export const taxService = tax
export const extService = ext
export const checkoutService = checkout

// export all services as an object
export const magento2 = {
  attributeService,
  breadcrumbsService,
  categoryService,
  productService,
  userService,
  cartService,
  taxService,
  extService,
  checkoutService,
  salesAgencyService,
}

export default magento2
