import PropTypes from 'prop-types'
import cn from '@utils/helpers/cn'
const Section = ({ title, className, innerRef, children, ...props }) => {
  return (
    <div
      className={cn('mx-auto flex w-full max-w-full', className)}
      ref={innerRef}
      {...props}
    >
      {children}
    </div>
  )
}

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

export default Section
