import { createUrlPrefix, fetchPageData } from './api'

/**
 * Fetches page data from Storyblok based on language and slug
 * @param {Object} params - Parameters object
 * @param {string} params.language - Language code (default: 'de')
 * @param {string} params.slug - Page slug (default: 'storyblok')
 * @returns {Promise<Object|null>} Page data or null if not found
 */
const StoryblokPage = async ({ language = 'de', slug = 'storyblok' }) => {
  // Create full page URL
  const prefix = createUrlPrefix(language)
  const pageUrl = `${prefix}/${slug}`

  // Fetch page data
  return await fetchPageData(pageUrl)
}

export default StoryblokPage
