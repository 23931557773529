import dynamic from 'next/dynamic'
import { memo } from 'react'

// Group components by category to reduce the number of dynamic imports
// Core layout components - commonly used
const CoreComponents = {
  Page: dynamic(() => import('@components/Storyblok/block/Page')),
  Container: dynamic(() => import('@components/Storyblok/block/Container')),
  GridContainer: dynamic(
    () => import('@components/Storyblok/block/GridContainer'),
  ),
  GridItem: dynamic(() => import('@components/Storyblok/block/GridItem')),
  Box: dynamic(() => import('@components/Storyblok/block/Box')),
  Stack: dynamic(() => import('@components/Storyblok/block/Stack')),
}

// Typography components
const TypographyComponents = {
  Typography: dynamic(() => import('@components/Storyblok/block/Typography')),
  Richtext: dynamic(() => import('@components/Storyblok/block/RichText')),
}

// Interactive components
const InteractiveComponents = {
  Button: dynamic(() => import('@components/Storyblok/block/Button')),
  ButtonGroup: dynamic(() => import('@components/Storyblok/block/ButtonGroup')),
  Link: dynamic(() => import('@components/Storyblok/block/Link')),
  Checkbox: dynamic(() => import('@components/Storyblok/block/Checkbox')),
  Switch: dynamic(() => import('@components/Storyblok/block/Switch')),
  TextField: dynamic(() => import('@components/Storyblok/block/TextField')),
}

// Card related components
const CardComponents = {
  Card: dynamic(() => import('@components/Storyblok/block/Card')),
  CardActionArea: dynamic(
    () => import('@components/Storyblok/block/CardActionArea'),
  ),
  CardActions: dynamic(() => import('@components/Storyblok/block/CardActions')),
  CardContent: dynamic(() => import('@components/Storyblok/block/CardContent')),
  CardHeader: dynamic(() => import('@components/Storyblok/block/CardHeader')),
  CardMedia: dynamic(() => import('@components/Storyblok/block/CardMedia')),
  ProductCard: dynamic(() => import('@components/Storyblok/block/ProductCard')),
}

// Navigation related components
const NavigationComponents = {
  AppBar: dynamic(() => import('@components/Storyblok/block/AppBar')),
  Toolbar: dynamic(() => import('@components/Storyblok/block/Toolbar')),
  Drawer: dynamic(() => import('@components/Storyblok/block/Drawer')),
  SwipeableDrawer: dynamic(
    () => import('@components/Storyblok/block/SwipeableDrawer'),
  ),
  MenuItem: dynamic(() => import('@components/Storyblok/block/MenuItem')),
  MenuList: dynamic(() => import('@components/Storyblok/block/MenuList')),
  Tabs: dynamic(() => import('@components/Storyblok/block/Tabs')),
  Tab: dynamic(() => import('@components/Storyblok/block/Tab')),
  Breadcrumbs: dynamic(() => import('@components/Storyblok/block/Breadcrumbs')),
  BottomNavigation: dynamic(
    () => import('@components/Storyblok/block/BottomNavigation'),
  ),
  BottomNavigationAction: dynamic(
    () => import('@components/Storyblok/block/BottomNavigationAction'),
  ),
  FooterComponent: dynamic(
    () => import('@components/Storyblok/block/FooterComponent'),
  ),
}

// Feedback components
const FeedbackComponents = {
  Dialog: dynamic(() => import('@components/Storyblok/block/Dialog')),
  DialogActions: dynamic(
    () => import('@components/Storyblok/block/DialogActions'),
  ),
  DialogContent: dynamic(
    () => import('@components/Storyblok/block/DialogContent'),
  ),
  DialogContentText: dynamic(
    () => import('@components/Storyblok/block/DialogContentText'),
  ),
  DialogTitle: dynamic(() => import('@components/Storyblok/block/DialogTitle')),
  Snackbar: dynamic(() => import('@components/Storyblok/block/Snackbar')),
  SnackbarContent: dynamic(
    () => import('@components/Storyblok/block/SnackbarContent'),
  ),
  Modal: dynamic(() => import('@components/Storyblok/block/Modal')),
  Popover: dynamic(() => import('@components/Storyblok/block/Popover')),
  Popper: dynamic(() => import('@components/Storyblok/block/Popper')),
}

// Progress indicators
const ProgressComponents = {
  CircularProgress: dynamic(
    () => import('@components/Storyblok/block/CircularProgress'),
  ),
  LinearProgress: dynamic(
    () => import('@components/Storyblok/block/LinearProgress'),
  ),
  Skeleton: dynamic(() => import('@components/Storyblok/block/Skeleton')),
}

// Image related components
const ImageComponents = {
  Image: dynamic(() => import('@components/Storyblok/block/Image')),
  ImageList: dynamic(() => import('@components/Storyblok/block/ImageList')),
  ImageListItem: dynamic(
    () => import('@components/Storyblok/block/ImageListItem'),
  ),
  ImageListItemBar: dynamic(
    () => import('@components/Storyblok/block/ImageListItemBar'),
  ),
  Avatar: dynamic(() => import('@components/Storyblok/block/Avatar')),
  AvatarGroup: dynamic(() => import('@components/Storyblok/block/AvatarGroup')),
}

// Stepper related components
const StepperComponents = {
  Stepper: dynamic(() => import('@components/Storyblok/block/Stepper')),
  Step: dynamic(() => import('@components/Storyblok/block/Step')),
  StepLabel: dynamic(() => import('@components/Storyblok/block/StepLabel')),
  StepContent: dynamic(() => import('@components/Storyblok/block/StepContent')),
  StepIcon: dynamic(() => import('@components/Storyblok/block/StepIcon')),
  MobileStepper: dynamic(
    () => import('@components/Storyblok/block/MobileStepper'),
  ),
}

// Transition related components
const TransitionComponents = {
  Collapse: dynamic(() => import('@components/Storyblok/block/Collapse')),
  Grow: dynamic(() => import('@components/Storyblok/block/Grow')),
  Slide: dynamic(() => import('@components/Storyblok/block/Slide')),
}

// Misc components
const MiscComponents = {
  Accordion: dynamic(() => import('@components/Storyblok/block/Accordion')),
  Badge: dynamic(() => import('@components/Storyblok/block/Badge')),
  Chip: dynamic(() => import('@components/Storyblok/block/Chip')),
  Divider: dynamic(() => import('@components/Storyblok/block/Divider')),
  Paper: dynamic(() => import('@components/Storyblok/block/Paper')),
  SvgIcon: dynamic(() => import('@components/Storyblok/block/SvgIcon')),
  Pagination: dynamic(() => import('@components/Storyblok/block/Pagination')),
  SpeedDial: dynamic(() => import('@components/Storyblok/block/SpeedDial')),
  SpeedDialIcon: dynamic(
    () => import('@components/Storyblok/block/SpeedDialIcon'),
  ),
  Slider: dynamic(() => import('@components/Storyblok/block/Slider')),
  Html: dynamic(() => import('@components/Storyblok/block/Html')),
  DeliveryCountryFilter: dynamic(
    () => import('@components/Storyblok/block/DeliveryCountryFilter'),
  ),
}

// Merge all component groups into a single object
const Components = {
  ...CoreComponents,
  ...TypographyComponents,
  ...InteractiveComponents,
  ...CardComponents,
  ...NavigationComponents,
  ...FeedbackComponents,
  ...ProgressComponents,
  ...ImageComponents,
  ...StepperComponents,
  ...TransitionComponents,
  ...MiscComponents,
}

// Memoize the Placeholder component to prevent unnecessary re-renders
const Placeholder = memo(({ componentName }) => (
  <div className="border border-red-200 bg-red-100 py-4">
    <p className="text-center text-red-700">
      The component <strong>{componentName}</strong> has not been created yet.
    </p>
  </div>
))

// Memoize the StoryblokComponent to prevent unnecessary re-renders
const StoryblokComponent = memo(({ blok, index = -1 }) => {
  // Early return if blok is not defined
  if (!blok) return null

  // Check if the component exists and render it
  if (Components[blok.component]) {
    const Component = Components[blok.component]
    return <Component index={index} blok={blok} key={blok._uid} />
  }

  // Fallback to placeholder if component doesn't exist
  return <Placeholder componentName={blok.component} />
})

StoryblokComponent.displayName = 'StoryblokComponent'
Placeholder.displayName = 'Placeholder'

export default StoryblokComponent
