import getProductsByCategoryId from '@utils/magento2/product/getProductsByCategoryId'
import getProductsBySku from '@utils/magento2/product/getProductsBySku'
import getProductsBySkuAlgolia from '@utils/magento2/product/getProductsBySkuAlgolia'
import getProductsById from '@utils/magento2/product/getProductsById'
import productStockCheck from '@utils/magento2/product/productStockCheck'
import productStockRemoveAll from '@utils/magento2/product/productStockRemoveAll'
import productStockRemove from '@utils/magento2/product/productStockRemove'
import getReviews from '@utils/magento2/product/getReviews'

const product = {
  getProductsByCategoryId,
  getProductsBySku,
  getProductsBySkuAlgolia,
  getProductsById,
  getReviews,
  productStockCheck,
  productStockRemoveAll,
  productStockRemove,
}

export default product
