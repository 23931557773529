import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { userService } from '@utils/magento2'
import { store } from '@features/store'

const initialState = {
  token: '',
  refreshToken: '',
  groupToken: '',
  groupId: null,
  current: null,
  currentStorecode: '',
  sessionStarted: new Date().toISOString(),
  ordersHistory: null,
  localDataLoaded: false,
  userGroup: {
    id: 0,
    code: 'NOT LOGGED IN',
    taxClassId: 10,
    taxClassName: 'B2C',
  },
  salesAgency: {
    selectedUser: {},
    selectedOrder: {},
  },
  salesAgent: false,
  isOrderHistoryRefreshedFromCheckout: false,
  wishlist: null,
}

export const actionLogin = createAsyncThunk(
  'user/login', // action name
  async ({ username, password }) => {
    // send login request to backend
    const loginData = await userService.login(username, password)
    // return login data
    return loginData
  },
)

export const actionMe = createAsyncThunk('user/me', async (token) => {
  const user = await userService.me(token)
  return user
})

export const actionWishlist = createAsyncThunk(
  'user/wishlist',
  async (token) => {
    const wishlist = await userService.wishlist(token)
    return wishlist
  },
)
export const actionClearWishlist = createAsyncThunk(
  'user/clearWishlist',
  async () => {
    const clearWishlist = await userService.clearWishlist()
    return clearWishlist
  },
)
export const actionPullAddress = createAsyncThunk(
  'user/pullAddress',
  async () => {
    const token = store.getState().user.token || ''
    const storeCode = store.getState().store.language.code || ''
    const addresses = await userService.pullAddress(token, storeCode)
    return addresses
  },
)
export const actionAddress = createAsyncThunk(
  'user/setAddress',
  async (params, thunkAPI) => {
    const { token, type, id, storeCode } = params
    const res = await userService.setAddress(token, type, id, storeCode)
    if (res.code === 200) {
      thunkAPI.dispatch(actionPullAddress())
    }
    return res
  },
)
export const actionNewsletter = createAsyncThunk(
  'user/newsletter',
  async (params) => {
    const { email, storeCode, isChecked } = params
    let res = {}
    if (isChecked) {
      res = await userService.subscribe(email, storeCode)
    } else {
      res = await userService.unSubscribe(email, storeCode)
    }
    return res
  },
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
    setStartSession: (state) => {
      state.sessionStarted = new Date()
    },
    setGroupToken: (state, action) => {
      state.groupToken = action.payload
    },
    setGroupId: (state, action) => {
      state.groupId = action.payload
    },
    setUserGroup: (state, action) => {
      const { id, code, taxClassId, taxClassName } = action.payload
      state.userGroup.id = id
      state.userGroup.code = code
      state.userGroup.taxClassId = taxClassId
      state.userGroup.taxClassName = taxClassName
    },
    setCurrent: (state, action) => {
      state.current = action.payload
    },
    setAddress: (state, action) => {
      state.current.address = action.payload
    },
    setOrdersHistory: (state, action) => {
      state.ordersHistory = action.payload
    },
    setEndSession: (state) => {
      state.token = ''
      state.current = null
      state.sessionStarted = null
    },
    setLocalDataLoaded: (state, action) => {
      state.localDataLoaded = action.payload
    },
    setSalesAgencySelectedUser: (state, action) => {
      state.salesAgency.selectedUser = action.payload
    },
    setSalesAgencySelectedOrder: (state, action) => {
      state.salesAgency.selectedOrder = action.payload
    },
    resetUser: () => initialState,
  },
  extraReducers: (builder) => {
    return builder
      .addCase(actionLogin.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          state.token = action.payload.result
          state.refreshToken = action.payload.meta.refreshToken
          state.sessionStarted = new Date().toISOString()
        }
      })
      .addCase(actionMe.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          state.current = action.payload.result
          state.salesAgent = action.payload.result.custom_attributes?.some(
            (attr) =>
              attr.attribute_code === 'sales_agency' &&
              parseInt(attr.value) > 0,
          )
        }
      })
      .addCase(actionWishlist.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          state.wishlist = action.payload.result
        }
      })
      .addCase(actionClearWishlist.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          state.wishlist.items = []
        }
      })
      .addCase(actionPullAddress.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          state.current.addresses = action.payload.result.items
        }
      })
      .addCase(actionNewsletter.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          state.current.extension_attributes.is_subscribed =
            !state.current.extension_attributes.is_subscribed
        }
      })
  },
})

export const isUserSalesAgency = (state) => {
  return (
    state?.user?.current?.custom_attributes?.some(
      (attr) =>
        attr.attribute_code === 'sales_agency' && parseInt(attr.value) > 0,
    ) || false
  )
}

export const {
  setToken,
  setStartSession,
  setGroupToken,
  setGroupId,
  setUserGroup,
  setCurrent,
  setOrdersHistory,
  setEndSession,
  setLocalDataLoaded,
  resetUser,
  setSalesAgencySelectedUser,
  setSalesAgencySelectedOrder,
} = userSlice.actions

export default userSlice.reducer
