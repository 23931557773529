import PropTypes from 'prop-types'
import useOnClickOutside from '@utils/hooks/useClose'
import { useRef, useMemo, useCallback, memo } from 'react'
import { appConfig } from '@utils/appConfig'
import cn from '@utils/helpers/cn'
import Icon from '@components/ui/Icon'
import Text from '@components/ui/Text'
import { useSelector, useDispatch } from 'react-redux'
import { setLanguageModal } from '@features/ui/uiSlice'
import useI18n from '@utils/hooks/useI18n'
import Image from '@components/ui/Image'
import dynamic from 'next/dynamic'

const LanguageModal = dynamic(
  () => import('@components/blocks/Layout/Layout-ui/LanguageModal'),
  {
    ssr: false,
  },
)

// Style constants to prevent recalculation on each render
const CONTAINER_STYLES = '-ml-2 flex max-w-min flex-col'
const TRIGGER_BASE_STYLES =
  'flex items-center gap-x-2 text-sm py-[10px] pl-2 pr-[5px] min-w-max hover:text-white hover:bg-gray-600 rounded-b-sm cursor-pointer select-none'
const LANGUAGE_MODAL_CONTAINER = 'relative'
const MODAL_WRAPPER_STYLES = 'absolute z-30 w-72 border bg-white p-4'

const LanguageDesktop = ({ language, country, currency }) => {
  const i18n = useI18n()
  const dispatch = useDispatch()
  const { languageModal } = useSelector((state) => state.ui)
  const languageRef = useRef(null)

  // Memoize the selected language to avoid recalculation on each render
  const selectedLanguage = useMemo(
    () =>
      appConfig.languages.find((lang) => lang.code === language)?.flagCode ||
      'de',
    [language],
  )

  // Memoize the currency symbol
  const currencySymbol = useMemo(
    () => appConfig.currency.find((item) => item.code === currency)?.symbol,
    [currency],
  )

  // Use useCallback for event handlers
  const handleToggleModal = useCallback(() => {
    dispatch(setLanguageModal(!languageModal))
  }, [dispatch, languageModal])

  const handleCloseModal = useCallback(() => {
    dispatch(setLanguageModal(false))
  }, [dispatch])

  // Set up click outside handler
  useOnClickOutside(languageRef, handleCloseModal)

  // Memoize trigger class names
  const triggerClassNames = useMemo(
    () =>
      cn(
        TRIGGER_BASE_STYLES,
        { 'text-[#7B8491]': !languageModal },
        { 'pointer-events-none text-white': languageModal },
      ),
    [languageModal],
  )

  // Memoize chevron class names
  const chevronClassNames = useMemo(
    () =>
      cn({
        'rotate-180': languageModal === true,
      }),
    [languageModal],
  )

  return (
    <div className={CONTAINER_STYLES}>
      {/* Language selector trigger */}
      <span onClick={handleToggleModal} className={triggerClassNames}>
        {i18n.t('language')}:
        <Image
          width={19}
          height={19}
          src={`/vsf/flags/1x1/${selectedLanguage}.svg`}
          alt="Language Flag"
        />
        |
        <Text className="max-xl:min-w-max" variant="inlineText">
          {i18n.t('delivery country')}: {country?.toUpperCase()}
        </Text>
        |
        <span>
          <Text variant="inlineText" className="uppercase">
            {currency}
          </Text>
          <Text variant="inlineText" className="font-semibold">
            {' '}
            ({currencySymbol})
          </Text>
        </span>
        <Icon icon="icon-chevron-down" className={chevronClassNames} />
      </span>

      {/* Language modal - only render when needed */}
      {languageModal && (
        <span className={LANGUAGE_MODAL_CONTAINER} ref={languageRef}>
          <span className={MODAL_WRAPPER_STYLES}>
            <LanguageModal />
          </span>
        </span>
      )}
    </div>
  )
}

LanguageDesktop.propTypes = {
  language: PropTypes.string,
  country: PropTypes.string,
  currency: PropTypes.string,
}

// Wrap with memo to prevent unnecessary re-renders
export default memo(LanguageDesktop)
