import { apiPost } from '@utils/api'

const deleteAddress = async (token, addressId, storeCode) => {
  const res = await apiPost(
    `ext/n98-customer-address/delete?token=${token}&address_id=${addressId}&storeCode=${storeCode}`
  )

  return res
}

export default deleteAddress
