import { apiPut } from '@utils/api'
import { store } from '@features/store'
import { appConfig } from '@utils/appConfig'

const assign = async (token, userId, cartId) => {
  const userToken = token || store.getState().user?.token
  const storeCode = store.getState().store.language.code
  const storeId = appConfig.storeViews[storeCode].storeId
  const res = await apiPut(
    `cart/assign?token=${userToken}&cartId=${cartId}&storeCode=${storeCode}`,
    { userId, storeId }
  )
  return res
}

export default assign
