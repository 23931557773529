import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import BottomNavigator from '@components/blocks/Layout/Layout-ui/BottomNaviagator/BottomNavigator'
import Cart from '@components/blocks/Layout/Layout-ui/Cart'
import LanguageDesktop from '@components/blocks/Layout/Layout-ui/LanguageDesktop'
import MobileHeader from '@components/blocks/Layout/Layout-ui/MobileHeader'
import MobileMenu from '@components/blocks/Layout/Layout-ui/MobileMenu'
import Container from '@components/ui/Container'
import MegaMenu from '@components/blocks/Layout/MegaMenu/MegaMenu'
import { addCategories } from '@features/category/categorySlice'
import cn from '@utils/helpers/cn'
import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ConfigContext from '@utils/context/config'
import StoryblokComponent from '@components/Storyblok/StoryblokComponent'

const LoginSkeleton = () => (
  <div className="flex h-full min-w-[175px] animate-pulse items-center justify-end space-x-2 max-lg:hidden">
    <div className="size-[37px] rounded-full bg-gray-300" />
    <div className="flex flex-col space-y-2">
      <div className="h-[10px] w-[50px] rounded-md bg-gray-300" />
      <div className="h-[10px] w-[70px] rounded-md bg-gray-300" />
    </div>
  </div>
)

const Login = dynamic(
  () => import('@components/blocks/Layout/Layout-ui/Login'),
  { loading: () => <LoginSkeleton />, ssr: false },
)

const WishlistIconSkeleton = () => (
  <div className="flex h-full min-w-[130px] animate-pulse items-center justify-end space-x-2 max-lg:hidden">
    <div className="size-[37px] rounded-full bg-gray-300" />
    <div className="h-[20px] w-[100px] rounded-md bg-gray-300" />
  </div>
)

const WishlistIcon = dynamic(
  () => import('@components/blocks/Layout/Layout-ui/WishlistIcon'),
  { loading: () => <WishlistIconSkeleton />, ssr: false },
)

const StoryblokBlock = React.memo(({ index, blok }) => (
  <StoryblokComponent index={index} blok={blok} key={blok._uid} />
))

StoryblokBlock.displayName = 'StoryblokBlock'

StoryblokBlock.propTypes = {
  index: PropTypes.number,
  blok: PropTypes.object,
}

const Header = ({ language, country, currency }) => {
  const dispatch = useDispatch()
  const config = useContext(ConfigContext)
  const categories = config?.categories || []
  const [loginPopup, setLoginPopup] = useState(false)
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [navVisible, setNavVisible] = useState(true)
  const { mobileMenu } = useSelector((state) => state.ui)

  const centerState = config?.header?.center || {}
  const rightState = config?.header?.right || {}

  useEffect(() => {
    dispatch(addCategories({ id: 'root', response: categories }))
  }, [language, dispatch])

  const filteredCategories = useMemo(
    () =>
      categories?.filter(
        (cat) =>
          cat.include_in_menu > 0 &&
          (cat.product_count > 0 || cat.children_count > 0),
      ),
    [categories],
  )

  const mobileCategories = useMemo(
    () =>
      [...filteredCategories].sort((a, b) => {
        const aPosition = a.rank_order_mobile
          ? a.rank_order_mobile
          : a.rank_order_shop
            ? a.rank_order_shop
            : a.position
        const bPosition = b.rank_order_mobile
          ? b.rank_order_mobile
          : b.rank_order_shop
            ? b.rank_order_shop
            : b.position
        return aPosition - bPosition
      }),
    [filteredCategories],
  )

  const desktopCategories = useMemo(
    () =>
      [...filteredCategories].sort((a, b) => {
        const aPosition = a.rank_order_shop ? a.rank_order_shop : a.position
        const bPosition = b.rank_order_shop ? b.rank_order_shop : b.position
        return aPosition - bPosition
      }),
    [filteredCategories],
  )

  const centerBlocks = useMemo(
    () =>
      centerState.blocks?.filter(
        (b) =>
          (b.hasOwnProperty('showBlock') && b.showBlock) ||
          !b.hasOwnProperty('showBlock'),
      ) || [],
    [centerState.blocks],
  )

  const rightBlocks = useMemo(
    () =>
      rightState.blocks?.filter(
        (b) =>
          (b.hasOwnProperty('showBlock') && b.showBlock) ||
          !b.hasOwnProperty('showBlock'),
      ) || [],
    [rightState.blocks],
  )

  useEffect(() => {
    let ticking = false

    const handleScroll = () => {
      const scrollTop = window.scrollY

      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (scrollTop > lastScrollTop && scrollTop > 150) {
            setNavVisible(false)
          } else {
            setNavVisible(true)
          }
          setLastScrollTop(scrollTop)
          ticking = false
        })
        ticking = true
      }
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll, { passive: true })
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [lastScrollTop])

  const handleLoginPopup = useCallback((value) => {
    setLoginPopup(value)
  }, [])

  return (
    <>
      <header
        className={cn(
          'sticky top-0 z-[11] w-full bg-[#282d34] transition-all delay-200 ease-in-out print:hidden',
          !navVisible && '-top-10',
        )}
      >
        <Container>
          <div className="relative hidden w-full items-center text-xs lg:flex">
            <div className="mr-12 w-1/4 max-xl:w-1/3">
              <LanguageDesktop
                language={language}
                country={country}
                currency={currency}
              />
            </div>
            <div className={cn(centerState.class)}>
              {centerBlocks.map((blok, index) => (
                <StoryblokComponent index={index} blok={blok} key={blok._uid} />
              ))}
            </div>
            <div className={cn(rightState.class)}>
              {rightBlocks.map((blok, index) => (
                <StoryblokComponent index={index} blok={blok} key={blok._uid} />
              ))}
            </div>
          </div>
          <div className="flex w-full items-center">
            <div className="relative flex w-full items-center justify-between max-lg:flex-col lg:pb-[15px] lg:pt-5">
              <MobileHeader categories={mobileCategories} />
              <>
                <div className="hidden min-w-max justify-center lg:flex">
                  <Login
                    setLoginPopup={handleLoginPopup}
                    loginPopup={loginPopup}
                  />
                </div>
                <WishlistIcon />
                <span className="flex">
                  <Cart navVisible={navVisible} />
                </span>
              </>
            </div>
          </div>
          {mobileMenu && (
            <MobileMenu
              categories={mobileCategories}
              setLoginPopup={handleLoginPopup}
              loginPopup={loginPopup}
            />
          )}
        </Container>

        <MegaMenu
          categories={desktopCategories}
          language={language}
          key={language}
        />
      </header>
      <BottomNavigator
        setLogin={handleLoginPopup}
        login={loginPopup}
        locale={language}
        country={country}
        currency={currency}
      />
    </>
  )
}

Header.propTypes = {
  language: PropTypes.string,
  country: PropTypes.string,
  currency: PropTypes.string,
}

export default React.memo(Header)
