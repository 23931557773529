import { store } from '@features/store'
import { apiPost } from '@utils/api'

const fetchCustomerOrders = async (id) => {
  const token = store.getState().user.token
  const body = {
    pageSize: 10,
    page: 1,
    customerId: id,
  }
  const res = await apiPost(
    `ext/ggm-sales-agency/order-history?token=${token}`,
    body
  )
  return res
}

export default fetchCustomerOrders
