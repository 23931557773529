import { apiPost } from '@utils/api'

const resetByCode = async (email, passCode, password, storeCode) => {
  const res = await apiPost(
    `ext/ggm-reset-password/reset-by-code?storeCode=${storeCode}`,
    {
      email,
      passCode,
      password,
    },
  )
  return res
}

export default resetByCode
