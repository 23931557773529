import { store } from '@features/store'
import { setLastOrder } from '@features/checkout/checkoutSlice'

const orderComplete = async (id, backendOrderId, albisId = null) => {
  const {
    personalDetails,
    shippingDetails,
    paymentDetails,
    shippingMethod,
    paymentMethod,
  } = store.getState().checkout

  const { products, totals } = store.getState().cart

  const orderDetails = {
    id,
    backendOrderId,
    albisId,
    personalDetails,
    shippingDetails,
    paymentDetails,
    shippingMethod,
    paymentMethod,
    products,
    totals,
  }

  await store.dispatch(setLastOrder(orderDetails))
}

export default orderComplete
