import { apiPost } from '@utils/api'
import { store } from '@features/store'
import { appConfig } from '@utils/appConfig'
import bodybuilder from 'bodybuilder'
const getAttribute = async (
  locale,
  attributes = appConfig.custom_attributes,
) => {
  const body = bodybuilder()
    .filter('terms', 'attribute_code', attributes)
    .build()

  const sourceInclude = [
    'activity',
    'attribute_code',
    'id',
    'entity_type_id',
    'options',
    'default_value',
    'is_user_defined',
    'frontend_label',
    'attribute_id',
    'default_frontend_label',
    'is_visible_on_front',
    'is_visible',
    'is_comparable',
    'tier_prices',
    'frontend_input',
    'ggm_how_you_heard',
    'request_item',
    'energy_label',
    'energy_efficiency_class',
    'energy_label_export',
    '*',
  ]

  const elasticsearch = locale
    ? appConfig.elasticsearch[locale]
    : store.getState().store.elasticsearch

  const res = await apiPost(
    `catalog/${elasticsearch}/attribute/_search?_source_exclude=sgn&_source_include=${sourceInclude.join(
      ',',
    )}&from=0&size=1000&sort=`,
    JSON.stringify(body),
  )

  if (res.hits && res.hits.hits && res.hits.hits.length > 0) {
    const hits = res.hits.hits
    return hits.map((hit) => hit._source)
  }

  return []
}

export default getAttribute
