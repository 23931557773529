import { store } from '@features/store'
import { apiGet } from '@utils/api'

const fetchAllOrders = async (page) => {
  const token = store.getState().user.token
  const pageSize = 10
  const res = await apiGet(
    `ext/ggm-sales-agency/orders/${pageSize}/${page}?token=${token}`
  )
  return res
}

export default fetchAllOrders
