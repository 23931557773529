import { actionLogin, actionMe, actionWishlist } from '@features/user/userSlice'
import { actionCreate, actionPull } from '@features/cart/cartSlice'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { store } from '@features/store'
import { setNotification, setLoginModal } from '@features/ui/uiSlice'
import { cartService } from '@utils/magento2'
import useI18n from '@utils/hooks/useI18n'

const useLogin = () => {
  const dispatch = useDispatch()
  const i18n = useI18n()
  const [isLogin, setIsLogin] = useState(!!store.getState().user?.token)

  const userLogin = async (username, password, cartClear = false) => {
    const login = await dispatch(actionLogin({ username, password })).unwrap()
    if (login.code === 200) {
      setIsLogin(true)
      dispatch(setLoginModal(false))
      dispatch(
        setNotification({
          type: 'success',
          message: i18n.t('You are logged in!'),
          callback: null,
        }),
      )
      const user = await dispatch(actionMe(login.result)).unwrap()
      dispatch(actionWishlist(login.result))
      userCart(login.result, user.result.id, cartClear)
    }
  }

  const userCart = async (token, userId, cartClear) => {
    const guestCartId = store.getState().cart?.id || null
    const cartProducts = store.getState().cart?.products || []
    if (cartClear) {
      await cartService.clear(token)
    }
    if (guestCartId && cartProducts.length > 0) {
      await cartService.assign(token, userId, guestCartId)
    }
    const cartId = await dispatch(actionCreate(token)).unwrap()
    await dispatch(actionPull({ token, cartId: cartId.result })).unwrap()
  }

  return [isLogin, setIsLogin, userLogin]
}

export default useLogin
