import cn from '@utils/helpers/cn'
import React from 'react'

const PrintHeader = () => {
  return (
    <div className={cn('flex justify-between p-8')}>
      <div className="order-0 shrink-0 self-auto">
        <img
          src="/print/ggmgastroLogo.png"
          alt="logo ggm"
          width="206"
          height="46"
        />
      </div>
      <div className={cn('flex gap-1.5')}>
        <img
          width={30}
          height={30}
          src="/vsf/tuv-footer.webp"
          alt="logo ehi"
          className={cn('max-h-[30px]')}
        />
        <img
          src="/vsf/images/footer/ehi_large.webp"
          width={30}
          height={30}
          alt="logo tuv"
          className={cn('max-h-[30px]')}
        />
        <img
          width={22}
          height={30}
          src="/vsf/images/header/dekra.png"
          alt="logo dekra"
          className={cn('max-h-[30px]')}
        />
      </div>
    </div>
  )
}

export default PrintHeader
