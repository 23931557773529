import { store } from '@features/store'
import { apiPost } from '@utils/api'

const getPayByLink = async (
  challengeOrderCustomerEmail,
  challengeOrderIncrementId
) => {
  const token = store.getState().user.token
  const body = {
    payload: { challengeOrderCustomerEmail, challengeOrderIncrementId },
    token,
  }
  const res = await apiPost(`ext/ggm-sales-agency/get-pay-by-link`, body)
  return res
}

export default getPayByLink
