import StoryblokPage from '@utils/storyblok/page'

const StoryblokConfig = async ({ language = 'de' }) => {
  const storyblokData = await StoryblokPage({ language, slug: 'pageconfig' })

  const config = storyblokData?.story?.content
    ? {
        header: {
          center: {
            class: storyblokData.story.content?.headerCenterClass || '',
            blocks: storyblokData.story.content?.headerCenterBlocks || [],
          },
          right: {
            class: storyblokData.story.content?.headerRightClass || '',
            blocks: storyblokData.story.content?.headerRightBlocks || [],
          },
        },
        footer: {
          info: {
            class: storyblokData.story.content?.infoClass || '',
            blocks: storyblokData.story.content?.infoBlocks || [],
          },
          component: {
            class: storyblokData.story.content?.componentClass || '',
            blocks: storyblokData.story.content?.componentBlocks || [],
          },
        },
      }
    : {
        header: {
          center: {
            class: '',
            blocks: [],
          },
          right: {
            class: '',
            blocks: [],
          },
        },
        footer: {
          info: {
            class: '',
            blocks: [],
          },
          component: {
            class: '',
            blocks: [],
          },
        },
      }

  return config
}

export default StoryblokConfig
