import PrintHeader from '@components/Pages/ProductPage/PrintHeader/PrintHeader'
import Text from '@components/ui/Text'
import cn from '@utils/helpers/cn'
import formatPrice from '@utils/helpers/price'
import useI18n from '@utils/hooks/useI18n'
import { cartService } from '@utils/magento2'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PrintDeliveryTime from '@components/Pages/ProductPage/PrintDeliveryTime/PrintDeliveryTime'

const PrintCart = ({
  products,
  options,
  warrantyType,
  totals,
  className,
  qr_code = null,
}) => {
  const i18n = useI18n()
  const [qrCode, setQrCode] = useState(qr_code)
  useEffect(() => {
    if (!qrCode) {
      shareCart()
    }
  }, [])
  const shareCart = async () => {
    const comment = ''
    const res = await cartService.shareCart({ comment })
    if (res?.result?.qr_code) {
      setQrCode(res.result.qr_code)
    }
  }
  const country = useSelector((state) => state.store.country)
  const subTotal = totals?.total_segments?.map(
    (total) => total.code === 'subtotal' && total.value,
  )[0]
  const shipping = totals?.total_segments?.find(
    (total) => total.code === 'shipping',
  ).value
  const tax = totals?.total_segments?.find(
    (total) => total.code === 'tax',
  ).value
  const grand_total = totals?.total_segments?.find(
    (total) => total.code === 'grand_total',
  ).value
  const warranty = options?.find(
    (option) => option?.warrantyType === warrantyType,
  )?.warrantyType
  const titles = ['Item', 'Description', 'Price', 'Qty', 'Subtotal']
  return (
    <div id="print-cart" className={cn('m-2 w-full', className)}>
      <PrintHeader />
      <div>
        <div className="relative overflow-x-auto p-10 pl-8 print:p-5">
          <table className="text-left">
            <thead>
              <tr>
                {titles.map((item, i) => (
                  <th className={cn('text-center text-xs font-bold')} key={i}>
                    {i18n.t(item)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {products?.map((item, i) => {
                const selectedCountry = item?.deliverytime?.find(
                  (item) => item.country_id === country.code.toUpperCase(),
                )
                return (
                  <tr key={i} className={cn('text-base font-light')}>
                    <td className="inline-block w-40 px-0 pt-5 text-center align-top print:w-48">
                      <div className="inline-block overflow-hidden whitespace-nowrap align-top">
                        <img
                          src={`${process.env.NEXT_PUBLIC_IMG_ENDPOINT}media/catalog/product${item?.small_image}`}
                          width={80}
                          height={80}
                          alt={item.name}
                        />
                      </div>
                    </td>
                    <td className="w-80 pt-1 text-left  text-sm font-bold print:w-72">
                      <p className="my-3 leading-[1.1]">{item.name}</p>
                      <p className={cn('font-medium')}>
                        {i18n.t('SKU')}: {item.sku}
                      </p>
                      <span className={cn('mt-2 flex items-center')}>
                        <PrintDeliveryTime
                          deliveryTimeMin={selectedCountry?.deliverytime_min}
                          deliveryTimeMax={selectedCountry?.deliverytime_max}
                          deliveryTimeUnit={selectedCountry?.deliverytime_unit}
                          cart={true}
                          className="px-0"
                        />
                      </span>
                    </td>
                    <td className="w-48 pt-5 text-center align-top text-sm font-normal">
                      {formatPrice(item.price, false)}
                    </td>
                    <td className="w-24 pt-5 text-center align-top text-sm font-light">
                      {item.qty}
                    </td>
                    <td className="w-24 pt-5 text-center align-top text-sm font-bold">
                      {formatPrice(item.price * item.qty, false)}
                    </td>
                  </tr>
                )
              })}
              <tr className="mt-2 text-sm font-light">
                <td></td>
                <td>
                  <div className="mt-2">
                    <Text variant="inlineText" className="font-bold">
                      {i18n.t('Subtotal')}
                    </Text>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td>
                  <div className="mt-2 text-right font-bold">
                    {formatPrice(subTotal, false)}
                  </div>
                </td>
              </tr>
              <tr className="text-sm font-light">
                <td></td>
                <td>
                  <div>
                    <Text variant="inlineText" className="font-bold">
                      {i18n.t('Shipping & Handling')}
                    </Text>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td>
                  <div className="text-right font-bold">
                    {formatPrice(shipping, false)}
                  </div>
                </td>
              </tr>
              <tr className="text-sm font-light">
                <td></td>
                <td>
                  <div>
                    <Text variant="inlineText" className="font-bold">
                      {i18n.t('Warranty')}
                    </Text>
                  </div>
                </td>
                <td>
                  <p className="py-1 font-medium">
                    {!warranty ? i18n.t(options[0].title) : i18n.t(warranty)}
                  </p>
                </td>
                <td></td>
                <td>
                  <div className="text-right font-bold">€0.00</div>
                </td>
              </tr>
              <tr className="text-sm font-light">
                <td></td>
                <td>
                  <div>
                    <Text variant="inlineText" className="font-bold">
                      {i18n.t('Tax')}
                    </Text>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td>
                  <div className="text-right font-bold">
                    {formatPrice(tax, false)}
                  </div>
                </td>
              </tr>
              <tr className="text-sm font-light">
                <td></td>
                <td>
                  <div>
                    <Text variant="inlineText" className="font-bold">
                      {i18n.t('Total')}
                    </Text>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td>
                  <div className="text-right font-bold">
                    {formatPrice(grand_total, false)}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {qrCode && (
        <img
          src={`data:image/jpeg;base64, ${qrCode}`}
          alt="qr_code"
          width={72}
          height={72}
          className="fixed -bottom-0.5 -left-0.5 aspect-square bg-white p-0.5"
        />
      )}
    </div>
  )
}

export default PrintCart
