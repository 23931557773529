import NextLink from 'next/link'
import PropTypes from 'prop-types'
import cn from '@utils/helpers/cn'
import { useRouter } from 'next/router'

const Link = ({
  href,
  external = false,
  locale = false,
  onClick,
  className,
  children,
  passHref = false,
  ...props
}) => {
  const router = useRouter()
  let locales = 'de-de-eur'

  if (router?.query?.locales) {
    locales = router.query.locales
  } else {
    const prefix = router?.asPath?.split('/')[1]
    if (!/^[a-z]{2}-[a-z]{2}-[a-z]{3}$/.test(prefix)) {
      locales = prefix
    }
  }

  if (locale) {
    return (
      <NextLink
        href={'/[locales]/[...slug]'}
        as={`/${locales}/${href}`}
        target={external ? '_blank' : undefined}
        className={cn(className)}
        onClick={onClick}
        passHref={passHref}
        {...(external && { rel: 'noopener' })}
        {...props}
      >
        {children}
      </NextLink>
    )
  }
  return (
    <NextLink
      href={href}
      target={external ? '_blank' : undefined}
      className={cn(className)}
      onClick={onClick}
      passHref={passHref}
      data-test="link"
      {...(external && { rel: 'noopener' })}
      {...props}
    >
      {children}
    </NextLink>
  )
}

Link.propTypes = {
  href: PropTypes.string.isRequired,
  external: PropTypes.bool,
  locale: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Link
