import { store } from '@features/store'
import { apiGet } from '@utils/api'

const productStockCheck = async (sku = 'all') => {
  const storeCode = store.getState().store.language.code
  const token = store.getState().user.token
  const res = await apiGet(
    `ext/ggm-sales-agency/product-stock-check/${
      sku
    }/${storeCode}?token=${token}`,
  )
  return res
}

export default productStockCheck
