import { createSlice } from '@reduxjs/toolkit'
import { appConfig } from '@utils/appConfig'

const { language, country, currency } = appConfig.i18n.default

const initialState = {
  language: appConfig.languages.find((item) => item.code === language),
  country: appConfig.deliveryCountry.find((item) => item.code === country),
  currency: appConfig.currency.find((item) => item.code === currency),
  elasticsearch: appConfig.elasticsearch[language],
}

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = appConfig.languages.find(
        (item) => item.code === action.payload
      )
      state.elasticsearch = appConfig.elasticsearch[action.payload]
    },
    setCountry: (state, action) => {
      state.country = appConfig.deliveryCountry.find(
        (item) => item.code === action.payload
      )
    },
    setCurrency: (state, action) => {
      state.currency = appConfig.currency.find(
        (item) => item.code === action.payload
      )
    },
  },
})

export const { setLanguage, setCountry, setCurrency } = storeSlice.actions

export default storeSlice.reducer
