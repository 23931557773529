import { store } from '@features/store'
import { apiPost } from '@utils/api'

const saveOrderComment = async (orderComment) => {
  const activeState = store.getState()
  const quoteId = activeState.cart.id
  const customerToken = activeState.user.token

  const res = await apiPost('ext/ggm-order-comment/saveOrderComment', {
    quoteId,
    orderComment,
    customerToken,
  })
  return res
}

export default saveOrderComment
