import { apiDelete } from '@utils/api'

const unSubscribe = async (email, storeCode) => {
  const res = await apiDelete(
    `ext/mailchimp-subscribe/subscribe?storeCode=${storeCode}`,
    {
      email,
    }
  )
  return res
}

export default unSubscribe
