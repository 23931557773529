import { apiPost } from '@utils/api'
import { store } from '@features/store'
import { checkoutService } from '@utils/magento2'

const adyenInitPayment = async (
  method,
  additional_data,
  urlStoreCodesFromRoute,
) => {
  const storeCode = store.getState().store.language.code
  const cartId = store.getState().cart.id
  const token = store.getState().user.token
  const email = store.getState().checkout.personalDetails.email

  const res = await apiPost(
    `ext/ggm-adyen-payment/init-payment/${storeCode}/${cartId}?token=${token}`,
    {
      email,
      method,
      additional_data,
      urlStoreCodesFromRoute,
    },
  )

  if (res.code === 200 && res.result.isFinal) {
    checkoutService.orderComplete(res.result.orderNumber, res.result.orderId)
  }

  return res.result
}

export default adyenInitPayment
