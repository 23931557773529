import { apiPost } from '@utils/api'

const resetPassword = async (email, storeCode) => {
  const res = await apiPost(`user/reset-password?storeCode=${storeCode}`, {
    email,
  })
  return res
}

export default resetPassword
