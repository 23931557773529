import { appConfig } from '@utils/appConfig'

export const storeView = (store) => {
  const selectedStoreView =
    appConfig.storeViews[store.language?.code || 'default']

  return {
    ...selectedStoreView,
    language: store.language,
    country: store.country,
    currency: store.currency,
  }
}
