import { apiPost } from '@utils/api'
import { store } from '@features/store'

const setShippingOptions = async (options) => {
  const languageCode = store.getState().store.language.code
  const cartId = store.getState().cart.id

  const body = {
    cart_id: cartId,
    shipping_options: options,
  }

  const res = await apiPost(
    `ext/ggm-shipping/setShippingOptions?storeCode=${languageCode}`,
    JSON.stringify(body)
  )

  return res
}

export default setShippingOptions
