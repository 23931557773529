import { apiPost } from '@utils/api'
import { store } from '@features/store'

const customerList = async ({ pageSize = 10, page = 1, keyword = '' }) => {
  const token = store.getState().user.token
  const body = {
    pageSize,
    page,
    customerSearch: {
      keyword,
    },
  }
  const res = await apiPost(
    `ext/ggm-sales-agency/customer-list?token=${token}`,
    body
  )
  return res
}

export default customerList
