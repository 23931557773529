import formatPrice from '@utils/helpers/price'
import { store } from '@features/store'
import { setActiveItemCategory } from '@features/seo/seoSlice'
import { appConfig } from '@utils/appConfig'

const googleAnalyticsProducts = (
  products = [],
  breadcrumbs = [],
  firstIndex = 0,
  countryCode = 'DE',
  item_category = '',
) => {
  // Input validation
  if (!Array.isArray(products)) {
    return []
  }

  // Process products in a single iteration
  return products.map((product, index) => ({
    item_id: product?._source?.sku,
    item_name: product?._source?.name,
    index: firstIndex + index,
    item_brand: 'GGM Gastro',
    price: parseFloat(
      product?._source?.totals?.price ||
        product?._source?.storePrice?.price ||
        product?._source?.[`sales_price_${getPriceSuffix(countryCode)}`] ||
        formatPrice(product?._source?.price, true, false),
    ).toFixed(2),
    quantity: product?._source?.qty || 1,
    ...getBreadcrumbData(
      product?._source?.breadcrumbs || breadcrumbs,
      item_category,
      product?._source?.sku,
    ),
  }))
}

const getBreadcrumbData = (breadcrumbs, category = '', sku) => {
  // Check for existing data in dataLayer
  if (typeof window !== 'undefined' && window.dataLayer) {
    const existingData = getExistingDataLayerItem(sku)
    if (existingData) return existingData
  }

  // Handle category-based returns
  if (category) {
    return { item_category: category }
  }

  // Get state data
  const state = store.getState()
  const { seo = {} } = state
  const { activeItemCategory, beforePage, currentPage } = seo

  // Handle special cases
  if (activeItemCategory) {
    store.dispatch(setActiveItemCategory(''))
    return { item_category: activeItemCategory }
  }

  if (beforePage === 'search' || currentPage === 'search') {
    return { item_category: 'search' }
  }

  if (beforePage?.includes('wishlist') || currentPage?.includes('wishlist')) {
    return { item_category: 'wishlist' }
  }

  if (
    beforePage === '' &&
    (currentPage === 'product' || currentPage === 'category')
  ) {
    return {}
  }

  // Process breadcrumbs
  return processBreadcrumbs(breadcrumbs.filter((item) => item.url_path !== ''))
}

const getExistingDataLayerItem = (sku) => {
  const cartItem = window.dataLayer
    ?.filter((item) => item.event === 'add_to_cart')
    ?.find((item) =>
      item.ecommerce.items.find((product) => product.item_id === sku),
    )
    ?.ecommerce.items.find((product) => product.item_id === sku)

  if (!cartItem?.item_id) return null

  return Object.fromEntries(
    Object.entries(cartItem)
      .filter(([key]) => key.startsWith('item_category'))
      .map(([key, value]) => [key, value]),
  )
}

const processBreadcrumbs = (breadcrumbs) => {
  const result = {}

  breadcrumbs.slice(0, 4).forEach((item, index) => {
    result[`item_category${index || ''}`] = item.name
  })

  if (breadcrumbs.length > 4) {
    result.item_category5 = breadcrumbs
      .slice(4)
      .map((item) => item.name)
      .join(' > ')
  }

  return result
}

const getPriceSuffix = (code) => {
  const normalizedCode = code.toString().toLowerCase()
  const countryData = appConfig.deliveryCountry.find(
    (item) => item.code === normalizedCode,
  )
  return countryData?.salesPriceAttributeSuffix || normalizedCode
}

export default googleAnalyticsProducts
