import { apiPost } from '@utils/api'
import { store } from '@features/store'

const reserveOrderId = async () => {
  const cartId = store.getState().cart.id
  const res = await apiPost(
    `ext/n98-reserve-order-id/reserve-order-id/${cartId}`
  )
  return res
}

export default reserveOrderId
