import { store } from '@features/store'
import { apiPost } from '@utils/api'

const assignCustomer = async (challengeEmail, challengePostcode) => {
  const token = store.getState().user.token
  const body = {
    challengeEmail,
    challengePostcode,
  }
  const res = await apiPost(
    `ext/ggm-sales-agency/assign-customer?token=${token}`,
    body
  )
  return res
}

export default assignCustomer
