import '@styles/chrome-bug.css'
import '@styles/globals.css'
import Layout from '@components/layout/DefaultLayout'
import RouterComponent from '@components/RouterComponent'
import { wrapper } from '@features/store'
import I18n from '@utils/lib/i18n'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { StyledEngineProvider } from '@mui/material/styles'
import { Barlow_Condensed, Roboto, Roboto_Condensed } from 'next/font/google'
import { SpeedInsights } from '@vercel/speed-insights/react'
import { NextSeo } from 'next-seo'
import { appConfig } from '@utils/appConfig'
import { setBeforePage } from '@features/seo/seoSlice'
import ConfigContext from '@utils/context/config'
import dynamic from 'next/dynamic'
import cn from '@utils/helpers/cn'
import App from 'next/app'
import {
  normalizeLocale,
  resolveDomain,
  loadPageData,
} from '@utils/helpers/withGetStaticProps'

const barlow = Barlow_Condensed({
  subsets: ['latin', 'latin-ext'],
  weight: ['800'],
  style: 'italic',
  variable: '--font-barlow',
  display: 'optional',
})

const roboto = Roboto({
  subsets: ['latin', 'latin-ext'],
  weight: ['300', '400', '700', '900'],
  variable: '--font-roboto',
  display: 'optional',
})

const roboto_condensed = Roboto_Condensed({
  subsets: ['latin', 'latin-ext'],
  weight: ['300', '400', '700'],
  variable: '--font-roboto-condensed',
  display: 'optional',
})

const GTMEvents = dynamic(() => import('@components/GTMEvents'), {
  ssr: false,
})

function GGMWebShopApp({ Component, ...rest }) {
  const { store, props } = wrapper.useWrappedStore(rest)
  const {
    pageProps,
    router: {
      query: { locales, slug },
    },
  } = props

  let language = pageProps.language || 'de'
  let country = pageProps.country || 'de'
  let currency = pageProps.currency || 'eur'
  const domain = pageProps.domain || process.env.NEXT_PUBLIC_DOMAIN
  const categories = pageProps.categories || []

  const getCookie = (cname) => {
    if (typeof document === 'undefined') {
      return ''
    }
    const name = cname + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  if (!language && typeof document !== 'undefined') {
    const prefix = getCookie('storeview_prefix')
    if (prefix?.split('-').length === 3) {
      language = prefix.split('-')[0]
      country = prefix.split('-')[1]
      currency = prefix.split('-')[2]
    }
  }

  const router = useRouter()

  useEffect(() => {
    const handleStart = () => {
      beforePage()
      NProgress.start()
    }

    const handleStop = () => {
      NProgress.done()
    }

    const beforePage = () => {
      const type = pageProps?.type
      if (locales && slug && pageProps?.source && type) {
        store.dispatch(setBeforePage(type))
      } else if (locales && router.asPath === `/${locales}`) {
        store.dispatch(setBeforePage('home'))
      } else if (locales) {
        if (router.asPath.includes('/search?q=')) {
          store.dispatch(setBeforePage('search'))
        } else {
          store.dispatch(
            setBeforePage(
              router.asPath.replace(`/${router.query.locales}/`, ''),
            ),
          )
        }
      } else {
        store.dispatch(setBeforePage('404'))
      }
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router.asPath])

  let hrefLang = []

  if (pageProps?.source?.href_lang?.length > 0) {
    hrefLang = [
      ...(hrefLang = pageProps.source.href_lang
        .filter((c) => c.languageCode === 'de')
        .map((c) => {
          return {
            hrefLang: 'x-default',
            href: c.url.replace('/homepage-test', ''),
            ...(c.languageCode === language && { fetchPriority: 'auto' }),
          }
        })),
      ...pageProps.source.href_lang.map((c) => {
        return {
          hrefLang: c.languageCode,
          href: c.url.replace('/homepage-test', ''),
          ...(c.languageCode === language && { fetchPriority: 'auto' }),
        }
      }),
      {
        hrefLang: language,
        href: `${domain}/${language}-${
          appConfig.i18n[language].country
        }-${appConfig.i18n[language].currency}${
          router?.query?.slug?.length > 0
            ? `/${router.query.slug.join('/')}`
            : router?.route?.replace('/[locales]', '')
        }`,
        fetchPriority: 'auto',
      },
    ]
  } else if (pageProps?.type === 'cms_page') {
    hrefLang =
      Object.keys(appConfig.i18n)?.map((c) => ({
        hrefLang: c === 'default' ? 'x-default' : c,
        href: `${domain}/${appConfig.i18n[c].language}-${
          appConfig.i18n[c].country
        }-${appConfig.i18n[c].currency}${
          router?.query?.slug?.length > 0
            ? `/${router.query.slug.join('/')}`
            : router?.route?.replace('/[locales]', '')
        }`,
        ...(c === language && { fetchPriority: 'auto' }),
      })) || []
  } else if (pageProps?.type === 'homepage') {
    hrefLang =
      Object.keys(appConfig.i18n)?.map((c) => ({
        hrefLang: c === 'default' ? 'x-default' : c,
        href: `${domain}/${appConfig.i18n[c].language}-${
          appConfig.i18n[c].country
        }-${appConfig.i18n[c].currency}`,
        ...(c === language && { fetchPriority: 'auto' }),
      })) || []
  } else {
    hrefLang = [
      {
        hrefLang: language,
        href: `${domain}/${language}-${
          appConfig.i18n[language].country
        }-${appConfig.i18n[language].currency}`,
        fetchPriority: 'auto',
      },
    ]
  }

  return (
    <>
      <Provider store={store}>
        <ConfigContext.Provider
          value={{ ...pageProps.config, domain, categories }}
        >
          {process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' && (
            <GTMEvents
              type={props.pageProps.type}
              language={language}
              country={country}
              currency={currency}
            />
          )}
          <RouterComponent
            language={language}
            country={country}
            currency={currency}
            locales={locales}
            slug={slug}
            source={props.pageProps.source}
            type={props.pageProps.type}
            pageProps={pageProps}
          />
          <NextSeo
            defaultTitle="GGM Gastro International GmbH"
            canonical={`${domain}${router.asPath?.split('?')[0]}`}
            languageAlternates={pageProps?.type ? hrefLang : []}
            additionalLinkTags={
              pageProps?.type
                ? [
                    {
                      rel: 'dns-prefetch',
                      href: 'https://www.googletagmanager.com',
                    },
                    {
                      rel: 'dns-prefetch',
                      href: process.env.NEXT_PUBLIC_IMG_ENDPOINT,
                    },
                    {
                      rel: 'preconnect',
                    },
                  ]
                : []
            }
            noindex={true}
            nofollow={true}
            key={router.asPath}
          />
          <I18n lngDict={pageProps.i18n} locale={language}>
            <StyledEngineProvider injectFirst>
              <div
                className={cn(
                  `${barlow.variable} ${roboto.variable} ${roboto_condensed.variable} font-roboto`,
                  {
                    homepage: pageProps.type === 'homepage',
                    category: pageProps.type === 'category',
                    product: pageProps.type === 'product',
                    cms_page: pageProps.type === 'cms_page',
                  },
                )}
              >
                {pageProps.layoutDisabled ||
                getCookie('is_app') === 'shopgate' ? (
                  <Component {...props.pageProps} />
                ) : (
                  <Layout
                    language={language}
                    country={country}
                    currency={currency}
                    key={`${language}-${country}-${currency}`}
                  >
                    <Component {...props.pageProps} />
                  </Layout>
                )}
              </div>
            </StyledEngineProvider>
          </I18n>
        </ConfigContext.Provider>
      </Provider>
      <SpeedInsights />
    </>
  )
}

const pageData = async (prefix) => {
  try {
    let locales = prefix

    if (!/^[a-z]{2}-[a-z]{2}-[a-z]{3}$/.test(locales)) {
      locales = 'de-de-eur'
    }

    // Get locale information - optimize with destructuring
    const { language, country, currency } = normalizeLocale(locales)

    // Resolve domain with cached function
    const domain = resolveDomain(language)

    // Load page data with optimized function
    const { config, i18n, categories } = await loadPageData(language)

    // Prepare common props - create object directly
    const commonProps = {
      language,
      country,
      currency,
      config,
      i18n,
      domain,
      categories,
    }

    // Execute handler and merge props efficiently
    const pageProps = {}
    const mergedProps = pageProps.props
      ? { ...commonProps, ...pageProps.props }
      : commonProps

    // Use try-catch for JSON operations to prevent unhandled errors
    try {
      // Ensure props are serializable
      const sanitizedProps = JSON.parse(JSON.stringify(mergedProps))

      return {
        ...pageProps,
        props: sanitizedProps,
      }
    } catch (jsonError) {
      console.error('[withGetStaticProps JSON Error]:', jsonError.message)
      return {
        ...pageProps,
        props: commonProps,
      }
    }
  } catch (error) {
    console.error('[withGetStaticProps]:', error.message)
    return {
      props: {},
    }
  }
}

GGMWebShopApp.getInitialProps = async (context) => {
  const ctx = await App.getInitialProps(context)

  const is404Page =
    // Server-side kontrolü
    (context.res && context.res.statusCode === 404) ||
    // Client-side hata kontrolü
    (context.err &&
      (context.err.statusCode === 404 || context.err.code === 'ENOENT')) ||
    // Router pathname kontrolü
    context.router.pathname === '/404' ||
    // URL'nin son kısmı kontrol et (custom 404 sayfaları için)
    context.router.asPath.endsWith('/404')

  if (!is404Page) {
    return ctx
  }
  const path = context?.router?.asPath || ''
  let locales = 'de-de-eur'
  if (path.split('/').length > 1) {
    const prefix = path.split('/')[1]
    if (/^[a-z]{2}-[a-z]{2}-[a-z]{3}$/.test(prefix)) {
      locales = prefix
    }
  }

  const data = await pageData(locales)

  return {
    ...ctx,
    pageProps: {
      ...ctx.pageProps,
      ...data.props,
    },
  }
}

export default GGMWebShopApp
