import PropTypes from 'prop-types'
import cn from '@utils/helpers/cn'
import Container from '@components/ui/Container'
const FullContainer = ({ className, children, ...props }) => {
  return (
    <div className={cn('w-full', 'ml-auto', 'mr-auto', className)} {...props}>
      <Container>{children}</Container>
    </div>
  )
}

FullContainer.propTypes = {
  children: PropTypes.node,
}

export default FullContainer
