import { apiPost } from '@utils/api'
import { store } from '@features/store'
import { appConfig } from '@utils/appConfig'
import bodybuilder from 'bodybuilder'

const getCategoryById = async (id, locale) => {
  if (!id) {
    return {}
  }
  const body = bodybuilder()
    .filter('terms', '_id', Array.isArray(id) ? id : [id])
    .filter('terms', 'is_active', [true])
    .build()

  const sourceInclude = [
    'id',
    '*.children_data.id',
    'children_data.id',
    'children_data.name',
    'children_data.url_path',
    'children_data.position',
    'children_data.rank_order_shop',
    'children_data.children_count',
    'children_data.preview_image',
    'children_data.lowest_price',
    'children_data.show_category_filter',
    'children_data.layer_navigation_attributes',
    '*.id',
    'children_count',
    'sku',
    'name',
    'is_active',
    'parent_id',
    'level',
    'url_key',
    'url_path',
    'product_count',
    'path',
    'position',
    'description',
    'image',
    'include_in_menu',
    'icon',
    'preview_image',
    'rank_order_mobile',
    'rank_order_shop',
    'lowest_price',
    'show_on_storefront',
    'show_on_storefront_interesting_articles',
    'display_mode_akeneo',
    'cms_content_page',
    'menu_display_mode',
    'target_category',
    'show_category_filter',
    'layer_navigation_attributes',
  ]

  const elasticsearch = locale
    ? appConfig.elasticsearch[locale]
    : store.getState().store.elasticsearch

  const res = await apiPost(
    `catalog/${elasticsearch}/category/_search?_source_exclude=sgn&_source_include=${sourceInclude.join(
      ',',
    )}&from=0&size=4000&sort=position:asc`,
    JSON.stringify(body),
  )

  if (res.hits && res.hits.hits && res.hits.hits.length > 0) {
    const hits = res.hits.hits
    if (Array.isArray(id)) {
      return hits.map((hit) => hit._source)
    }
    return hits[0]._source
  }

  return res
}

export default getCategoryById
