import PropTypes from 'prop-types'
import cn from '@utils/helpers/cn'

const Textarea = ({
  className,
  id,
  rows,
  placeholder,
  register = null,
  error = null,
  name,
  validation = {},
  ...props
}) => {
  const formData = () => {
    if (typeof register === 'function') {
      return {
        ...register(name, validation),
        ...props,
      }
    }
    return props
  }
  return (
    <textarea
      className={cn('resize-none rounded border-2', className, {
        'border-red-500': error && error?.message,
        'focus:border-red-500': error && error?.message,
        'hover:border-red-500': error && error?.message,
      })}
      rows={rows}
      id={id}
      placeholder={placeholder}
      {...formData()}
      {...props}
    />
  )
}

Textarea.propTypes = {
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
}

export default Textarea
