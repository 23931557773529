import { apiPost } from '@utils/api'
import { store } from '@features/store'
import { appConfig } from '@utils/appConfig'
const checkTaxId = async (taxId, countryId) => {
  const cartId = store.getState().cart.id
  const taxCountry =
    appConfig.taxId.find((country) => country.code === countryId.toLowerCase())
      ?.vatIdCode || ''
  const data = await apiPost('ext/ggm-vat-validation', {
    cartId,
    countryId,
    taxId: taxCountry + taxId,
    taxReduction: 1,
  })
  return data
}

export default checkTaxId
