import PropTypes from 'prop-types'
import Icon from '@components/ui/Icon'
import Link from '@components/ui/Link'
import Text from '@components/ui/Text'
import useOnClickOutside from '@utils/hooks/useClose'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GgmApplication from '@components/blocks/Layout/Layout-ui/Ggm-Application'
import LanguageMobile from '@components/blocks/Layout/Layout-ui/LanguageMobile'
import {
  setLoginModal,
  setMenuModal,
  setNotification,
} from '@features/ui/uiSlice'
import cn from '@utils/helpers/cn'
import useI18n from '@utils/hooks/useI18n'
import { appConfig } from '@utils/appConfig'
import { isUserSalesAgency } from '@features/user/userSlice'
import Image from '@components/ui/Image'
import useLogin from '@utils/hooks/useLogin'
import { resetUser } from '@features/user/userSlice'
import { resetCart } from '@features/cart/cartSlice'
import { useRouter } from 'next/router'
import { storeView } from '@utils/storeView'

const MobileMenu = ({ categories }) => {
  const dispatch = useDispatch()
  const stateStore = useSelector((state) => state.store)
  const currentStore = storeView(stateStore)
  const { language, country, currency } = useSelector((state) => state.store)
  const mobileMenuRef = useRef(null)
  const isSalesAgency = useSelector(isUserSalesAgency)
  const [, setIsLogin] = useLogin()
  const router = useRouter()
  const route = router.asPath
  const currentRoute = `/${language.code}-${country.code}-${currency.code}`
  const currentUser = useSelector((state) => state.user?.current)
  useOnClickOutside(mobileMenuRef, () => dispatch(setMenuModal(false)))
  const mobileMen = [
    { name: 'Catalog', link: 'catalogs' },
    { name: 'Shipping costs', link: 'shipping-cost' },
    { name: 'Payment information', link: 'paymentmethods' },
  ]
  const i18n = useI18n()
  const logout = () => {
    setIsLogin(false)
    dispatch(resetUser())
    dispatch(resetCart())
    dispatch(
      setNotification({
        type: 'success',
        message: i18n.t(["You're logged out"]),
        callback: null,
      }),
    )
  }
  const mobileLogin = () => {
    dispatch(setLoginModal(true))
    dispatch(setMenuModal(false))
  }
  return (
    <div
      className="absolute left-0 top-1 h-screen w-2/5 overflow-y-auto bg-[#282d34] max-lg:w-2/5 max-sm:w-full"
      ref={mobileMenuRef}
    >
      <Text
        variant="inlineText"
        className="absolute left-3 top-1 cursor-pointer text-2xl font-bold text-white"
        onClick={() => dispatch(setMenuModal(false))}
      >
        &#10005;
      </Text>
      <div className="flex w-full items-center justify-center border-b-[0.5px] border-gray-400 py-4">
        <Link
          href=""
          className="w-1/3"
          locale
          onClick={() => dispatch(setMenuModal(false))}
        >
          <Image
            width={130}
            height={30}
            src="/logo.svg"
            alt="GGM logo"
            className="cursor-pointer"
          />
        </Link>
      </div>
      <div className="relative w-full cursor-pointer text-white">
        <details className="group" open>
          <summary
            className={cn(
              'mx-3 flex items-baseline justify-between border-b border-gray-500 pb-5',
            )}
          >
            <Text
              variant="body"
              className="pointer-events-none pl-1 pt-5 text-base font-medium"
            >
              {i18n.t('Categories')}
            </Text>
            <Icon
              icon="icon-chevron-down"
              className={'-translate-x-2 -rotate-90 group-open:rotate-0'}
            />
          </summary>
          <div
            className={cn(
              'relative flex-col flex-wrap gap-1 px-4 transition-all',
            )}
          >
            {categories?.map((category, i) => (
              <Link
                href={category?.url_path}
                className="flex items-baseline justify-between border-b-[0.5px] border-gray-400"
                locale
                key={i}
                onClick={() => dispatch(setMenuModal(false))}
              >
                <Text
                  variant="body"
                  className="flex w-full items-center py-3.5 pl-1.5"
                >
                  {category?.icon && (
                    <Image
                      src={`${process.env.NEXT_PUBLIC_IMG_ENDPOINT}media/${category.icon}`}
                      alt=""
                      className="mr-2 invert"
                      width={26}
                      height={26}
                      unoptimized
                    />
                  )}
                  {category?.name}
                </Text>
                <Icon
                  icon="icon-chevron-down"
                  className={'-translate-x-2 -rotate-90'}
                />
              </Link>
            ))}
            {currentStore.additionalMainNavItems.map((item, i) => (
              <Link
                href={
                  item.url.includes('ggmmoebel.com')
                    ? `${item.url}${currentRoute}`
                    : item.url
                }
                className="flex items-baseline justify-between border-b-[0.5px] border-gray-400"
                external={item.external}
                locale={item.locale}
                onClick={() => dispatch(setMenuModal(false))}
                key={i}
              >
                <Text
                  variant="body"
                  className="flex w-full items-center py-3.5 pl-1.5"
                >
                  {item?.icon && (
                    <Image
                      src={item.icon}
                      alt=""
                      className="mr-2 invert"
                      width={26}
                      height={26}
                      unoptimized
                    />
                  )}
                  {i18n.t(item.title)}
                </Text>
                <Icon
                  icon="icon-chevron-down"
                  className={'-translate-x-2 -rotate-90'}
                />
              </Link>
            ))}
          </div>
        </details>
      </div>
      <LanguageMobile />
      {currentUser ? (
        <div className="relative w-full text-white">
          <details className="group flex justify-between">
            <summary
              className={cn(
                'mx-3 flex cursor-pointer items-baseline justify-between border-b border-gray-500 pb-5 group-open:border-none',
              )}
            >
              <Text variant="body" className="pl-1 pt-5 text-base font-medium">
                {i18n.t('My account')}
              </Text>
              <Icon
                icon="icon-chevron-down"
                className={'-translate-x-2 -rotate-90 group-open:rotate-0'}
              />
            </summary>

            {appConfig.userMenu
              .filter((menu) => menu.salesAgency === isSalesAgency)
              .map((menu, index) => (
                <div
                  className={cn(
                    'relative my-3 cursor-pointer flex-col flex-wrap gap-1 pl-6 text-base transition-all',
                    {
                      'pointer-events-none cursor-default text-[#9D9D9D]':
                        route === currentRoute + '/' + menu.link,
                    },
                  )}
                  key={index}
                >
                  <Link
                    key={index}
                    href={menu.link}
                    locale={!menu.link.includes('http')}
                    target={menu.link.includes('http') ? '_blank' : '_self'}
                    onClick={
                      menu.title === 'Logout'
                        ? logout
                        : () => dispatch(setMenuModal(false))
                    }
                  >
                    {i18n.t(menu.title)}
                  </Link>
                </div>
              ))}
          </details>
        </div>
      ) : (
        <div className="w-full cursor-pointer text-white" onClick={mobileLogin}>
          <div className="mx-3 flex items-baseline justify-between border-b border-gray-500 pb-5">
            <Text variant="body" className="pl-1 pt-5 text-base font-medium">
              {i18n.t('Log in')}
            </Text>
            <Icon
              icon="icon-chevron-down"
              className="-translate-x-2 -rotate-90"
            />
          </div>
        </div>
      )}
      {mobileMen.map((menuItem, i) => (
        <Link
          href={menuItem.link}
          locale
          className="w-full cursor-pointer text-white"
          key={i}
          onClick={() => dispatch(setMenuModal(false))}
        >
          <div className="mx-3 flex items-baseline justify-between border-b border-gray-500 pb-5">
            <Text variant="body" className="pl-1 pt-5 text-base font-medium">
              {i18n.t(menuItem.name)}
            </Text>
            <Icon
              icon="icon-chevron-down"
              className="-translate-x-2 -rotate-90"
            />
          </div>
        </Link>
      ))}
      <GgmApplication type="header" handleClick={() => {}} activeTabs={null} />
    </div>
  )
}

MobileMenu.propTypes = {
  categories: PropTypes.array,
}
export default MobileMenu
