import { apiGet } from '@utils/api'
import { store } from '@features/store'
import { appConfig } from '@utils/appConfig'

const getWarranty = async () => {
  const language = store.getState().store?.language.code || ''
  const country = store.getState().store?.country.code || ''
  const storeId = appConfig.storeViews[language]?.storeId || 2
  const res = await apiGet(
    `ext/ggm-warranty/warranties/${storeId}/${country.toUpperCase()}`
  )
  return res
}

export default getWarranty
