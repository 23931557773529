import { store } from '@features/store'
import { apiPost } from '@utils/api'

const magentoForm = async (data) => {
  const storeCode = store.getState().store.language.code
  const res = await apiPost(`ext/custom-form/?storeCode=${storeCode}`, {
    formResult: data,
  })
  return res
}

export default magentoForm
