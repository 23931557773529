import { apiPost } from '@utils/api'
import { store } from '@features/store'

const clear = async (token) => {
  const userToken = token || store.getState().user?.token
  const quoteId = store.getState().cart.id
  const res = userToken
    ? await apiPost(`ext/n98-clear-cart/customer-cart/clear?token=${userToken}`)
    : await apiPost(`ext/n98-clear-cart/guest-cart/clear?hash=${quoteId}`)
  return res
}

export default clear
