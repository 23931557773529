import { apiPost } from '@utils/api'
import { store } from '@features/store'
import { actionCreate } from '@features/cart/cartSlice'

const addToCart = async (sku, qty, force, custom_options) => {
  const storeCode = store.getState().store.language.code
  const token = store.getState().user.token
  let quoteId = store.getState().cart.id
  const products = store.getState().cart.products
  let itemId = null
  let totalQty = qty
  if (!quoteId || products.length === 0) {
    await store.dispatch(actionCreate(token))
    quoteId = store.getState().cart.id
  }
  const product = products.find((p) => p.sku === sku)
  if (product) {
    totalQty = force ? qty : product.qty + qty
    itemId = product.item_id
  }

  const body = {
    cartItem: {
      sku,
      qty: totalQty,
      quoteId,
    },
  }

  if (itemId) {
    body.cartItem.item_id = itemId
  }

  if (custom_options) {
    body.cartItem.product_option = {
      extension_attributes: {
        custom_options: {
          [custom_options.id]: {
            option_id: custom_options.id,
            option_value: custom_options.value,
          },
        },
      },
    }
  }

  const res = await apiPost(
    `cart/update?token=${
      token ? token : ''
    }&cartId=${quoteId}&storeCode=${storeCode}`,
    body,
  )
  return res
}

export default addToCart
