import PropTypes from 'prop-types'
import cn from '@utils/helpers/cn'
import Text from '@components/ui/Text'
import Icon from '@components/ui/Icon'

const Select = ({
  name,
  title,
  selected,
  options,
  callback,
  className,
  register = null,
  error = null,
  validation = {},
  ...props
}) => {
  const formData = () => {
    if (typeof register === 'function') {
      return {
        ...register(name, validation),
        ...props,
      }
    }
    return props
  }
  return (
    <div className="flex items-center">
      {title?.length > 0 && (
        <Text className="mr-2 text-base font-semibold" variant="inlineText">
          {title}
        </Text>
      )}
      <div className="relative w-full rounded-sm border border-gray-300 bg-white text-gray-800">
        <select
          className={cn(
            'w-full appearance-none bg-white px-1 py-0.5',
            className,
          )}
          name={name}
          onChange={(e) =>
            typeof callback === 'function' ? callback(e.target.value) : null
          }
          defaultValue={selected}
          {...formData()}
        >
          {options?.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center border-l-2 px-1 text-black">
          <Icon icon="icon-chevron-down" />
        </div>
      </div>
      {error && error?.message && (
        <span className="text-xs text-red-500">{error.message}</span>
      )}
    </div>
  )
}

Select.propTypes = {
  name: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  options: PropTypes.array,
  callback: PropTypes.func,
}

export default Select
