import Link from '@components/ui/Link'
import cn from '@utils/helpers/cn'
import Image from '@components/ui/Image'
const Certifications = ({ className }) => {
  const cert = [
    {
      img: 'tuv-header',
      link: 'https://www.tuev-saar.de/zertifikat/sc44757/',
      width: 30,
      height: 30,
    },
    {
      img: 'images/header/dekra',
      link: 'https://www.dekra.com/en/home/',
      width: 22,
      height: 30,
    },
  ]

  return (
    <span
      className={cn(
        'flex',
        'gap-[5px]',
        'items-center',
        'justify-end ',
        'min-w-min',
        'xl:w-1/4',
        className,
      )}
    >
      {cert.map((certs, i) => (
        <Link key={i} href={certs.link}>
          <Image
            src={`/vsf/${certs.img}.png`}
            alt="certification images"
            className="h-[30px] max-w-min"
            width={certs.width}
            height={certs.height}
          />
        </Link>
      ))}
    </span>
  )
}

Certifications.propTypes = {}
export default Certifications
