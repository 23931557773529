import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { setCurrentPage, setHrefLang } from '@features/seo/seoSlice'
import { actionGetAttribute } from '@features/attribute/attributeSlice'
import { actionGetCurrency } from '@features/currency/currencySlice'
import { actionGetTax } from '@features/tax/taxSlice'
import { actionPull, actionCreate } from '@features/cart/cartSlice'
import { resetSearch, actionGetSearchQuery } from '@features/search/searchSlice'
import {
  setLanguage,
  setCountry,
  setCurrency,
} from '@features/store/storeSlice'
import { saveState } from '@features/local-storage'
import { appConfig } from '@utils/appConfig'
import dynamic from 'next/dynamic'

const IntercomChat = dynamic(() => import('@components/IntercomChat'), {
  ssr: false,
})

const RouterComponent = ({
  locales,
  slug,
  source,
  type,
  language,
  country,
  currency,
  pageProps,
}) => {
  const dispatch = useDispatch()
  const router = useRouter()
  let showCookie = false
  const [showIntercom, setShowIntercom] = useState(false)

  useSelector((state) => {
    const { category, ui, seo, ...loadState } = state
    saveState(loadState)
  })

  const { id: cartId } = useSelector((state) => state.cart)

  const userToken = useSelector((state) => state.user.token || null)

  const {
    language: { code: currentLanguage },
    country: { code: currentCountry },
    currency: { code: currentCurrency },
  } = useSelector((state) => state.store)

  useEffect(() => {
    if (locales && slug && source && type) {
      dispatch(setCurrentPage(type))
      dispatch(setHrefLang(source.href_lang))
    } else if (locales && router.asPath === `/${locales}`) {
      dispatch(setCurrentPage('home'))
      dispatch(setHrefLang([]))
    } else if (locales) {
      if (router.asPath.includes('/search?q=')) {
        dispatch(setCurrentPage('search'))
      } else {
        dispatch(
          setCurrentPage(
            router.asPath.replace(`/${router.query.locales}/`, ''),
          ),
        )
      }
      dispatch(setHrefLang([]))
    } else {
      dispatch(setCurrentPage('404'))
      dispatch(setHrefLang([]))
    }
    if (typeof document !== 'undefined' && type) {
      document.body.classList.remove(...document.body.classList)
      document.body.classList.add(type)
    } else {
      document.body.classList.remove(...document.body.classList)
    }
  }, [router.asPath])

  useEffect(() => {
    dispatch(actionGetAttribute({ locale: language }))
    dispatch(actionGetTax({ locale: language }))
    dispatch(resetSearch())
    dispatch(actionGetSearchQuery({ query: '', locale: language }))
  }, [language])

  useEffect(() => {
    const countryData = appConfig.deliveryCountry.find(
      (item) => item.code === country,
    )
    if (
      countryData &&
      countryData['external-link']?.length > 0 &&
      process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ) {
      window.location.replace(countryData['external-link'])
    } else {
      if (language !== currentLanguage) {
        dispatch(setLanguage(language))
      }
      if (country !== currentCountry) {
        dispatch(setCountry(country))
      }
      if (currency !== currentCurrency) {
        dispatch(setCurrency(currency))
      }
      document.cookie =
        'storeview_prefix=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      document.cookie = `storeview_prefix=${language}-${country}-${currency}; path=/;`
    }
  }, [language, country, currency])

  // SSR - Pull cart data
  useEffect(() => {
    ssrCart()
    dispatch(actionGetCurrency())
    cookieFirstInit()
  }, [])

  const getCookie = (cname) => {
    if (typeof document === 'undefined') {
      return ''
    }
    const name = cname + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  const cookieFirstInit = () => {
    setTimeout(() => {
      showCookieFirst()
    }, 3000)
  }

  const showCookieFirst = () => {
    const cookieFirstRoot = document.getElementsByClassName('cookiefirst-root')
    if (!showCookie && cookieFirstRoot.length > 0) {
      showCookie = true
      cookieFirstRoot[0].setAttribute('style', 'display:inline !important')
    }
    if (!(pageProps.layoutDisabled || getCookie('is_app') === 'shopgate')) {
      setShowIntercom(true)
    }
  }

  const ssrCart = async () => {
    if (userToken) {
      await dispatch(actionCreate(userToken)).unwrap()
    }
    if (cartId) {
      await dispatch(actionPull()).unwrap()
    }
  }

  if (showIntercom) {
    return <IntercomChat page={type} />
  }

  return null
}

export default RouterComponent
