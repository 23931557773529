import { apiPost } from '@utils/api'
import { store } from '@features/store'

const refresh = async (refreshToken) => {
  const storeCode = store.getState().store.language.code
  const url = `user/refresh?storeCode=${storeCode}`
  const res = await apiPost(url, { refreshToken })
  return res
}

export default refresh
