import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import { categoryService } from '@utils/magento2'

const initialState = {
  categories: {},
  beforeCategoryPath: null,
}

export const actionGetSubCategories = createAsyncThunk(
  'category/getCategories',
  async (params, { getState }) => {
    const id = params?.id || 'root'
    const force = params?.force || false
    const locale = params?.locale || getState().store?.language?.code || 'de'
    //Check if there is category data stored in the Store State
    const category = getState().category?.categories[id] //Get categoies data from  store state
    //if there is a data in store state then make it response which we don't have to fetch new data
    if (!force && category && category.length > 0) {
      return { id, response: category }
    }
    //Else fetch data from magento category service and store it in response
    const response = await categoryService.getSubCategoryById(id, locale)
    return { id, response }
  }
)

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    resetCategories: (state) => {
      state.categories = {}
    },
    addCategories: (state, action) => {
      state.categories[action.payload.id] = action.payload.response
    },
    addBeforeCategoryPath: (state, action) => {
      state.beforeCategoryPath = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(actionGetSubCategories.fulfilled, (state, action) => {
      if (action.payload.id === 'root') {
        state.categories = {}
      }
      state.categories[action.payload.id] = Array.isArray(
        action.payload.response
      )
        ? action.payload.response
        : []
    })
  },
})

export const { resetCategories, addCategories, addBeforeCategoryPath } =
  categorySlice.actions

const selectRootCategory = (state) => state.category.categories
export const rootCategory = createSelector(
  [selectRootCategory],
  (categories) => categories['root'] || []
)
export default categorySlice.reducer
