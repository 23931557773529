import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentPage: 'home',
  beforePage: '',
  activeItemCategory: '',
  hrefLang: [],
  breadcrumbs: [],
}

export const seoSlice = createSlice({
  name: 'seo',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setBeforePage: (state, action) => {
      state.beforePage = action.payload
    },
    setActiveItemCategory: (state, action) => {
      state.activeItemCategory = action.payload
    },
    setHrefLang: (state, action) => {
      state.hrefLang = action.payload
    },
    setBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload
    },
  },
})

export const {
  setCurrentPage,
  setBeforePage,
  setActiveItemCategory,
  setHrefLang,
  setBreadcrumbs,
} = seoSlice.actions

export default seoSlice.reducer
