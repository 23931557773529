import { apiPost } from '@utils/api'
import { store } from '@features/store'

const create = async (token) => {
  let cartToken = token || null
  const userToken = store.getState().user?.token
  if (!cartToken && userToken) {
    cartToken = userToken
  }
  // Get the store language code from the Redux store
  const storeCode = store.getState().store.language.code
  // Create a cart
  const res = await apiPost(
    `cart/create?token=${cartToken ? cartToken : ''}&storeCode=${storeCode}`
  )
  return res
}

export default create
