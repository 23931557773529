import { useEffect } from 'react'

function useOnClickOutside(ref, handler, escape = true) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current || ref.current.contains(event.target)) {
        return
      }

      if (event?.target?.id === 'notification') {
        return
      }

      handler(event)
    }

    const handleKeyDown = (event) => {
      if (escape && event.key === 'Escape') {
        handler(event)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [ref, handler, escape])
}

export default useOnClickOutside
