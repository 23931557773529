import PropTypes from 'prop-types'
import cn from '@utils/helpers/cn'
import Icon from '@components/ui/Icon'
import Text from '@components/ui/Text'
import useI18n from '@utils/hooks/useI18n'
import localRoute from '@utils/helpers/localRoute'

const DeliveryTime = ({
  status,
  isShort,
  deliveryTimeMin,
  deliveryTimeMax,
  deliveryTimeUnit,
  className,
  ...props
}) => {
  const i18n = useI18n()
  return (
    <span
      className={cn(
        'flex max-w-fit items-center gap-1 rounded-md bg-[#E3F3FF] px-2 py-[4px] text-xs font-medium',
        {
          'bg-[#E6F2DC] font-medium text-[#298400]':
            deliveryTimeUnit === 'days',
          'bg-[#F7F7F7] text-[#7B8491]': !deliveryTimeUnit,
          'bg-transparent': deliveryTimeUnit === 'included',
        },
        className,
      )}
      {...props}
    >
      {(deliveryTimeUnit === 'included' || deliveryTimeUnit === 'days') && (
        <Icon className="flex items-center text-sm" icon="icon-check_circle" />
      )}
      {!deliveryTimeUnit && (
        <Text
          variant="inlineText"
          className="text-[10px] font-medium text-[#1160B1] sm:text-xs"
        >
          {i18n.t(['We will contact you with the exact delivery time.'])}
        </Text>
      )}
      {deliveryTimeUnit === 'days' && (
        <span className="text-[10px] font-medium sm:text-xs">
          {i18n.t('In stock!')}
          <span
            className={cn(
              isShort && 'hidden',
              'text-[10px] font-medium sm:text-xs',
            )}
          >
            : {deliveryTimeMin} - {deliveryTimeMax} {i18n.t(deliveryTimeUnit)}
          </span>
        </span>
      )}
      {deliveryTimeUnit === 'weeks' && (
        <Text
          variant="inlineText"
          className="text-[10px] font-medium text-[#1160B1] sm:text-xs"
        >
          <Text variant="inlineText">{i18n.t('Delivery time') + ':'}</Text>
          {' ' +
            deliveryTimeMin +
            ' - ' +
            deliveryTimeMax +
            ' ' +
            i18n.t(deliveryTimeUnit)}
        </Text>
      )}
      {deliveryTimeUnit === 'date' && (
        <Text
          variant="inlineText"
          className="text-[10px] text-[#1160B1] sm:text-xs"
        >
          {isShort ? (
            <Text variant="inlineText">{i18n.t('from') + ' '}</Text>
          ) : (
            <Text variant="inlineText">{i18n.t('Available from') + ' '}</Text>
          )}
          {deliveryTimeMin}
        </Text>
      )}
      {deliveryTimeUnit === 'none' && (
        <Text variant="inlineText" className="text-[#1160B1]">
          Nur Selbstabholung
        </Text>
      )}
      {deliveryTimeUnit === 'included' && (
        <span
          className="text-[10px] sm:text-sm"
          dangerouslySetInnerHTML={{
            __html: i18n.t(['incl. {{lowestPrice}}'], {
              lowestPrice: `<a href="${localRoute('best-price')}" title="${i18n.t('Lowest Price Guarantee')}" target="_blank" class="text-[#345581] underline hover:text-[#3B73CC]">${i18n.t('Lowest Price Guarantee')}</a>`,
            }),
          }}
        ></span>
      )}
    </span>
  )
}

DeliveryTime.propTypes = {
  deliveryTimeMin: PropTypes.string,
  deliveryTimeMax: PropTypes.string,
  deliveryTimeUnit: PropTypes.string,
  isShort: PropTypes.bool,
}

export default DeliveryTime
