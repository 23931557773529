import { apiPost } from '@utils/api'
import { store } from '@features/store'
import { appConfig } from '@utils/appConfig'
import bodybuilder from 'bodybuilder'

const getProductsBySku = async (sku, locale, size = 1000) => {
  if (!sku) {
    return []
  }

  const body = bodybuilder()
    .filter('terms', 'visibility', [2, 3, 4])
    .filter('terms', 'status', [0, 1])
    .filter('terms', 'sku', Array.isArray(sku) ? sku : [sku])
    .build()

  const sourceInclude = [
    'activity',
    'type_id',
    '*sku',
    'product_links',
    'tax_class_id',
    'special_price',
    'special_to_date',
    'special_from_date',
    'name',
    'price',
    'price_incl_tax',
    'original_price_incl_tax',
    'original_price',
    'special_price_incl_tax',
    'rrp',
    'old_price',
    'id',
    'image',
    'sale',
    'new',
    'url_path',
    'url_key',
    'status',
    'tier_prices',
    'custom_options',
    'configurable_children.sku',
    'configurable_children.price',
    'configurable_children.special_price',
    'configurable_children.price_incl_tax',
    'configurable_children.special_price_incl_tax',
    'configurable_children.original_price',
    'configurable_children.original_price_incl_tax',
    '*image',
    '*small_image',
    'configurable_children.color',
    'configurable_children.size',
    'configurable_children.tier_prices',
    'final_price',
    'configurable_children.final_price',
    'short_description',
    'info',
    'stock',
    'errors',
    'net_dimensions',
    'sales_blacklist',
    'order_in_shop',
    'shipping_method',
    'average_review',
    'average_review_count',
    'sales_price_*',
    'capacity',
    'gross_width',
    'gross_depth',
    'net_width',
    'net_depth',
    'net_height',
    'recommended_filling_quantity',
    'number_of_doors',
    'number_of_pizzas_per_chamber',
    'number_of_burners',
    'number_of_drawers',
    'show_on_storefront',
    'new_article',
    'show_on_storefront_customers_recommend',
    'no_leasing',
    'request_item',
    'display_mode_akeneo',
    'cms_content_page',
    'category',
    'deliverytime',
    'product_label',
    'number_of_baking_chambers',
    'temperature_range_to',
    'fully_chamotte_lined',
    'power',
    'number_of_pizzas_per_hour_per_baking_chamber',
    'oven_type',
    'special_features',
    'action',
    'quality_level',
    'second_choice',
    'bynder_images',
    'bynder_images_nested',
  ]

  const elasticsearch = locale
    ? appConfig.elasticsearch[locale]
    : store.getState().store.elasticsearch

  const res = await apiPost(
    `catalog/${elasticsearch}/product/_search?_source_exclude=sgn&_source_include=${sourceInclude.join(
      ',',
    )}&from=0&size=${size}`,
    JSON.stringify(body),
  )

  if (res?.hits && res.hits.hits && res.hits.hits.length > 0) {
    return { products: res.hits.hits, total: res.hits.total.value }
  }

  return res
}

export default getProductsBySku
