import Container from '@components/ui/Container/Container'
import Link from '@components/ui/Link/Link'
import Text from '@components/ui/Text/Text'
import useI18n from '@utils/hooks/useI18n'
import { useSelector } from 'react-redux'

const CheckoutFooter = () => {
  const i18n = useI18n()
  const currentUser = useSelector((state) => state.user)
  const taxes = useSelector((state) => state.tax)
  const countryCode = useSelector((state) => state.store.country.code)
  const getTax = () => {
    const taxClassId = currentUser?.userGroup?.taxClassId || 10
    const tax =
      taxes?.rules
        .find((tax) =>
          tax.customer_tax_class_ids.includes(parseInt(taxClassId)),
        )
        ?.rates?.find(
          (rate) =>
            rate.tax_country_id.toUpperCase() === countryCode.toUpperCase(),
        )?.rate || 0

    return parseInt(tax)
  }
  return (
    <footer className="bg-background pb-14">
      <Container>
        <hr className="mx-auto border-gray-300 pb-2 lg:w-1/2" />
        <div className="mx-auto px-2 lg:w-1/2">
          <div className="flex justify-between pb-4">
            <Link
              locale
              href=""
              variant="inlineText"
              className="text-sm text-[#1160B1] max-sm:hidden"
            >
              {i18n.t('Go to Shop')}
            </Link>
            <div className="flex gap-3 px-3 max-sm:w-full max-sm:justify-between">
              <Link
                target="_blank"
                href="https://helpcenter.ggmgastro.com/"
                variant="inlineText"
                className="text-sm text-[#1160B1]"
              >
                {i18n.t('Contact')}
              </Link>
              <Link
                locale
                href="legal"
                variant="inlineText"
                className="text-sm text-[#1160B1]"
              >
                {i18n.t('Legal notice')}
              </Link>
              <Link
                locale
                href="privacy"
                variant="inlineText"
                className="text-sm text-[#1160B1]"
              >
                {i18n.t('Privacy policy')}
              </Link>
              <Link
                locale
                href="terms"
                variant="inlineText"
                className="text-sm text-[#1160B1]"
              >
                {i18n.t('Terms')}
              </Link>
            </div>
          </div>
          <Text
            variant="paragraph"
            className="text-last-center mb-4 text-justify text-xs text-[#9D9D9D]"
          >
            {i18n.t([
              'The offers in the online shop are aimed exclusively at commercial customers, authorities, associations and social and church institutions within the meaning of §14 BGB. Our offer is not aimed at consumers in the sense of §13 BGB. Subject to technical and price changes and errors. Self-collectors from EU member countries also pay a deposit in the amount of German sales tax (19%), which will be refunded after the goods arrive in the other EU member state and after receipt of the receipt. For further information please click ',
            ])}
            <Link
              locale
              href="collection"
              variant="inlineText"
              className="text-[#1160B1]"
            >
              {i18n.t('here')}
            </Link>
          </Text>
          <Text
            variant="paragraph"
            className="text-last-center text-justify text-xs text-[#9D9D9D]"
          >
            {i18n.t(
              [
                'Copyright 2004–{{copyrightYear}} © GGM Gastro International GmbH',
              ],
              { copyrightYear: new Date().getFullYear() },
            )}
            <Text variant="inlineText" className="ml-1 text-xs">
              {i18n.t(['| all prices plus VAT {{tax}}%, incl. Delivery'], {
                tax: getTax(),
              })}
              .
            </Text>
          </Text>
        </div>
      </Container>
    </footer>
  )
}

export default CheckoutFooter
