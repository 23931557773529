import StoryblokConfig from '@utils/storyblok/config'
import { categoryService } from '@utils/magento2'
import { appConfig } from '@utils/appConfig'

/**
 * Optimized timeout controller for async operations
 * Using AbortController for more efficient resource management
 */
export const withTimeout = (promise, ms = 15000) => {
  // Create abort controller for cleaner timeout management
  const controller = new AbortController()
  const timeoutId = setTimeout(() => controller.abort(), ms)

  // Create a promise that rejects when aborted
  const timeoutPromise = new Promise((_, reject) => {
    controller.signal.addEventListener(
      'abort',
      () => reject(new Error('Operation timed out')),
      { once: true },
    )
  })

  return Promise.race([promise, timeoutPromise]).finally(() =>
    clearTimeout(timeoutId),
  )
}

/**
 * Normalize locale parameters with minimal string operations
 * Using destructuring for more efficient variable assignment
 */
export const normalizeLocale = (locales = 'de-de-eur') => {
  const parts = locales.toLowerCase().split('-')
  return {
    language: parts[0] || 'de',
    country: parts[1] || 'de',
    currency: parts[2] || 'eur',
  }
}

// Cache for loaded page data to prevent redundant operations
const dataCache = new Map()

/**
 * Load required data for the page with caching
 */
export const loadPageData = async (language = 'de') => {
  // Check cache first to avoid redundant network requests
  const cacheKey = `pageData_${language}`
  if (dataCache.has(cacheKey)) {
    return dataCache.get(cacheKey)
  }

  // Declare fallback values
  let config = null
  let i18n = {}

  try {
    // Load StoryblokConfig with timeout
    config = await withTimeout(StoryblokConfig({ language })).catch((error) => {
      console.error('[loadPageData] StoryblokConfig error:', error.message)
      return null
    })

    const categories = await withTimeout(
      categoryService.getSubCategoryById('root', language),
    )

    // Only attempt to translate if the function exists
    try {
      const languageCode =
        appConfig.languages.find((item) => item.code === language.toLowerCase())
          ?.locale || 'de-DE'
      const translationResult = await fetch(
        `${process.env.NEXT_PUBLIC_DOMAIN}/api/i18n?language=${languageCode}`,
      ).then((res) => res.json())

      // Check if translationResult is a Promise
      if (translationResult instanceof Promise) {
        i18n = await translationResult.catch((error) => {
          console.error('[loadPageData] translate error:', error.message)
          return {}
        })
      } else {
        // If not a Promise, use the result directly
        i18n = translationResult || {}
      }
    } catch (translateError) {
      console.error(
        '[loadPageData] translate execution error:',
        translateError.message,
      )
      i18n = {}
    }

    // Validate the data before caching
    if (!i18n || typeof i18n !== 'object') {
      console.warn(
        `[loadPageData] Invalid i18n data for ${language}, using empty object`,
      )
      i18n = {}
    }

    // Store validated result in cache
    const result = {
      config: config || null,
      i18n: i18n || {},
      categories: categories || [],
    }

    dataCache.set(cacheKey, result)
    return result
  } catch (error) {
    console.error('[loadPageData] Unexpected error:', error.message)
    // Return fallback data on error
    return { config: null, i18n: {} }
  }
}

/**
 * Domain resolver with result caching
 */
export const resolveDomain = (() => {
  const cachedDomains = new Map()

  return (language) => {
    if (cachedDomains.has(language)) {
      return cachedDomains.get(language)
    }

    let domain = process.env.NEXT_PUBLIC_DOMAIN

    if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
      domain = 'https://www.ggmgastro.com'
    }

    cachedDomains.set(language, domain)
    return domain
  }
})()

export function withGetStaticProps(handler) {
  return async (context) => {
    try {
      // Early return if no context params
      if (!context?.params) {
        return { props: {}, revalidate: 10800 }
      }

      let locales = context.params.locales || 'de-de-eur'

      if (!/^[a-z]{2}-[a-z]{2}-[a-z]{3}$/.test(locales)) {
        locales = 'de-de-eur'
      }

      if (handler) {
        const pagePropsTest = await handler(context)
        if (pagePropsTest?.notFound) {
          return {
            notFound: true,
            revalidate: 30,
          }
        }
      }

      // Get locale information - optimize with destructuring
      const { language, country, currency } = normalizeLocale(locales)

      // Resolve domain with cached function
      const domain = resolveDomain(language)

      // Load page data with optimized function
      const { config, i18n, categories } = await loadPageData(language)

      // Prepare common props - create object directly
      const commonProps = {
        language,
        country,
        currency,
        config,
        i18n,
        domain,
        categories,
      }

      // If no handler provided, return common props
      if (!handler) {
        return { props: commonProps, revalidate: 10800 }
      }

      // Execute handler and merge props efficiently
      const pageProps = await handler(context)

      // Ensure revalidate is at least 1 if it exists
      const revalidateValue =
        pageProps?.revalidate !== undefined
          ? Math.max(1, pageProps.revalidate)
          : 10800

      const mergedProps = pageProps.props
        ? { ...commonProps, ...pageProps.props }
        : commonProps

      // Use try-catch for JSON operations to prevent unhandled errors
      try {
        // Ensure props are serializable
        const sanitizedProps = JSON.parse(JSON.stringify(mergedProps))

        return {
          ...pageProps,
          props: sanitizedProps,
          revalidate: revalidateValue,
        }
      } catch (jsonError) {
        console.error('[withGetStaticProps JSON Error]:', jsonError.message)
        return {
          ...pageProps,
          props: commonProps,
          revalidate: revalidateValue,
        }
      }
    } catch (error) {
      console.error('[withGetStaticProps]:', error.message)
      return {
        props: {},
        revalidate: 10800,
      }
    }
  }
}
