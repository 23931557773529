import { apiPost } from '@utils/api'
import { store } from '@features/store'

const createCustomer = async (customerData) => {
  const token = store.getState().user.token
  const body = {
    customerData,
  }
  const res = await apiPost(
    `ext/ggm-sales-agency/save-customer?token=${token}`,
    body
  )
  return res
}

export default createCustomer
