import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { extService } from '@utils/magento2'

const initialState = {
  rates: [],
}

export const actionGetCurrency = createAsyncThunk(
  'currency/getCurrency',
  async () => {
    const response = await extService.getCurrency()
    return { response }
  }
)

export const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    resetCurrency: (state) => {
      state.rates = []
    },
  },
  extraReducers(builder) {
    builder.addCase(actionGetCurrency.fulfilled, (state, action) => {
      const currency = action.payload.response
      if (Array.isArray(currency)) {
        state.rates = currency
      }
    })
  },
})

export const { resetCurrency } = currencySlice.actions
export default currencySlice.reducer
