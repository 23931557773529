import React, { useEffect, useMemo, useCallback } from 'react'
import dynamic from 'next/dynamic'
import Header from '@components/blocks/Layout/Header'
import CheckoutHeader from '@components/blocks/Layout/CheckoutHeader'
import CheckoutFooter from '@components/blocks/Layout/CheckoutFooter'
import {
  setIsMobile,
  setLeasingModal,
  setLoginModal,
} from '@features/ui/uiSlice'
import useWindowSize from '@utils/hooks/useWindowSize'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import cn from '@utils/helpers/cn'
import { createSelector } from '@reduxjs/toolkit'

// Use React.lazy with dynamic imports for better code splitting
const Footer = dynamic(() => import('@components/blocks/Layout/Footer'), {
  ssr: true,
  loading: () => <div className="h-20" />, // Add simple placeholder to avoid layout shift
})

const BackToTop = dynamic(() => import('@components/blocks/Layout/BackToTop'), {
  ssr: false,
})

const Alert = dynamic(() => import('@components/ui/Alert'), {
  ssr: false,
})

const Modal = dynamic(() => import('@components/ui/Modal'), { ssr: false })

const Leasing = dynamic(() => import('@components/ui/Leasing'), {
  ssr: false,
})
const LoginModal = dynamic(() => import('@components/blocks/MyAccount/Login'), {
  ssr: false,
})

// Individual selectors for atomic state pieces
const selectScrollLock = (state) => state.ui.scrollLock
const selectIsLeasingModal = (state) => state.ui.isLeasingModal
const selectMicroCart = (state) => state.ui.microCart
const selectIsLoginModal = (state) => state.ui.isLoginModal
const selectAlertMessage = (state) => state.ui.notification.message

// Memoized selector using createSelector to prevent returning new object references
// when the underlying data hasn't changed
const uiSelector = createSelector(
  [
    selectScrollLock,
    selectIsLeasingModal,
    selectMicroCart,
    selectIsLoginModal,
    selectAlertMessage,
  ],
  (scrollLock, isLeasingModal, microCart, isLoginModal, alertMessage) => ({
    scrollLock,
    isLeasingModal,
    microCart,
    isLoginModal,
    alertMessage,
  }),
)

const DefaultLayout = ({ children, language, country, currency }) => {
  const windowSize = useWindowSize()
  const dispatch = useDispatch()
  const router = useRouter()

  // Use useMemo to avoid recalculating route-based flags on every render
  const routeInfo = useMemo(
    () => ({
      isHomePage: router.pathname === '/[locales]',
      isCheckoutPage:
        router.pathname?.startsWith('/[locales]/checkout') &&
        !router.pathname.startsWith('/[locales]/checkout/thankyou'),
      isCartPage: router.pathname?.startsWith('/[locales]/cart'),
      isSharedCartPage: router.pathname?.startsWith('/[locales]/shared-cart'),
      isStoryblok: router.pathname?.startsWith('/[locales]/storyblok'),
    }),
    [router.pathname],
  )

  const {
    isHomePage,
    isCheckoutPage,
    isCartPage,
    isSharedCartPage,
    isStoryblok,
  } = routeInfo

  // Use optimized selector to get only necessary UI state
  const { scrollLock, isLeasingModal, microCart, isLoginModal, alertMessage } =
    useSelector(uiSelector)

  // Handle responsive state in single effect
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isMobile = windowSize.width < 1024
      dispatch(setIsMobile(isMobile))
    }
  }, [windowSize.width, dispatch])

  // Handle leasing modal in response to microcart
  useEffect(() => {
    if (microCart) {
      dispatch(setLeasingModal(false))
    }
  }, [microCart, dispatch])

  // Optimize scroll lock logic
  useEffect(() => {
    if (typeof window === 'undefined') return

    const shouldLockScroll = scrollLock || isLoginModal || isLeasingModal

    if (shouldLockScroll) {
      // Calculate scrollbar width once and apply
      const scrollbarWidth =
        window.innerWidth - document.getElementById('__next').offsetWidth
      document.body.style.paddingRight = `${scrollbarWidth}px`
      document.body.classList.add('scroll-lock')
    } else {
      document.body.style.removeProperty('padding-right')
      document.body.classList.remove('scroll-lock')
    }

    // Cleanup function to ensure scroll is restored when component unmounts
    return () => {
      document.body.style.removeProperty('padding-right')
      document.body.classList.remove('scroll-lock')
    }
  }, [scrollLock, isLoginModal, isLeasingModal])

  // Memoize callback to avoid recreating on each render
  const closeLoginModal = useCallback(() => {
    dispatch(setLoginModal(false))
  }, [dispatch])

  // Memoize main className to avoid recalculations
  const mainClassName = useMemo(
    () =>
      cn('overflow-x-hidden', {
        'min-h-[40vh]': !isCheckoutPage,
        'min-h-[calc(100vh-200px)]': isCheckoutPage,
        'bg-background':
          isCheckoutPage ||
          isCartPage ||
          isStoryblok ||
          isHomePage ||
          isSharedCartPage,
      }),
    [isCheckoutPage, isCartPage, isStoryblok, isHomePage, isSharedCartPage],
  )

  // Only render components when needed
  return (
    <div className="flex min-h-screen flex-col">
      {isCheckoutPage ? (
        <CheckoutHeader
          language={language}
          country={country}
          currency={currency}
        />
      ) : (
        <Header language={language} country={country} currency={currency} />
      )}
      <main className={mainClassName}>{children}</main>
      {isCheckoutPage ? (
        <CheckoutFooter
          language={language}
          country={country}
          currency={currency}
        />
      ) : (
        <Footer language={language} country={country} currency={currency} />
      )}
      {isLoginModal && (
        <Modal closeCallback={closeLoginModal} escape={false}>
          <LoginModal />
        </Modal>
      )}
      {isLeasingModal && <Leasing />}
      {alertMessage && <Alert />}
      <BackToTop />
    </div>
  )
}

// Prevent unnecessary rerenders with React.memo
export default React.memo(DefaultLayout)
