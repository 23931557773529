import PropTypes from 'prop-types'

const Text = ({ variant, className, children, ...props }) => {
  const types = {
    body: 'div',
    heading: 'h1',
    sectionHeading: 'h2',
    subHeading: 'h3',
    paragraph: 'p',
    inlineText: 'span',
  }

  const Component = types[variant]

  return (
    <Component className={className} {...props}>
      {children}
    </Component>
  )
}

Text.propTypes = {
  children: PropTypes.any,
  variant: PropTypes.oneOf([
    'body',
    'heading',
    'sectionHeading',
    'subHeading',
    'paragraph',
    'inlineText',
  ]),
  className: PropTypes.string,
}

export default Text
