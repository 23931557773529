import { store } from '@features/store'
import { apiPost } from '@utils/api'

const addToWishlist = async ({ buyRequest = [], productId, wishlistId }) => {
  const token = store.getState().user.token

  const res = await apiPost(
    `ext/ggm-wishlist/add-wishlist-item?token=${token}`,
    { buyRequest, productId, wishlistId }
  )
  return res
}

export default addToWishlist
