import { apiPost } from '@utils/api'
import { store } from '@features/store'

const clearWishlist = async () => {
  const userToken = store.getState().user?.token
  const wishlistId = store.getState().user?.wishlist?.wishlist_id
  const wishlistItemIds = store
    .getState()
    .user?.wishlist?.items.map((item) => item.wishlist_item_id)

  const body = {
    wishlistId,
    wishlistItemIds,
  }

  const res = await apiPost(
    `ext/ggm-wishlist/remove-wishlist-item?token=${userToken}`,
    body
  )
  return res
}

export default clearWishlist
