import axios from 'axios'
import { appConfig } from '@utils/appConfig'

/**
 * Creates a URL prefix based on language configuration
 * @param {string} language - Language code (e.g., 'de', 'en')
 * @returns {string} Formatted URL prefix
 */
const createUrlPrefix = (language) => {
  const config = appConfig.i18n[language] || appConfig.i18n.de
  return `${config.language}-${config.country}-${config.currency}`
}

/**
 * Fetches page data from Storyblok with Redis caching through API route
 * @param {string} pageUrl - Full page URL to fetch
 * @returns {Promise<Object|null>} Page data or null if fetch fails
 */
const fetchPageData = async (pageUrl) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_DOMAIN}/api/storyblok/page`,
      {
        params: {
          pageUrl,
        },
      },
    )

    return response?.data || null
  } catch (error) {
    return null
  }
}

export { createUrlPrefix, fetchPageData }
