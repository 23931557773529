import { appConfig } from '@utils/appConfig'
import algoliasearch from 'algoliasearch'

const searchQuery = async (query, locale) => {
  const client = algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
  )

  const queries = [
    {
      indexName: `${appConfig.algoliasearch[locale]}custom_query_suggestions`,
      params: {
        similarQuery: query,
        hitsPerPage: 1000,
      },
    },
    {
      indexName: `${appConfig.algoliasearch[locale]}products_query_suggestions`,
      query: query,
      params: { hitsPerPage: 20 },
    },
  ]

  try {
    const response = await client.multipleQueries(queries)

    if (response.results[0].hits.length > 0) {
      const customList = response.results[0].hits.filter(
        (keyword) => keyword.keywords.toUpperCase() === query.toUpperCase(),
      )
      if (customList.length > 0) {
        return customList[0].list
      }
    }

    if (response.results[1].hits.length > 0) {
      return response.results[1].hits.map((item) => ({
        query: item.query,
        rating: item.popularity,
      }))
    }

    return []
  } catch (error) {
    const filterResponse = await client.multipleQueries(
      queries.filter((item) => !error.message.includes(item.indexName)),
    )
    if (filterResponse.results[0].hits.length > 0) {
      return filterResponse.results[0].hits.map((item) => ({
        query: item.query,
        rating: item.popularity,
      }))
    }
    return []
  }
}

export default searchQuery
