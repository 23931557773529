import { apiGet } from '@utils/api'
import { store } from '@features/store'

const getWarrantyType = async () => {
  const cartId = store.getState().cart.id || ''
  const res = await apiGet(`ext/ggm-warranty/getWarrantyType/${cartId}`)
  return res
}

export default getWarrantyType
