import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { cartService, productService } from '@utils/magento2'
import { store } from '@features/store'

const initialState = {
  products: [],
  totals: {},
  paymentMethods: [],
  shippingMethods: [],
  id: null,
  loading: false,
  error: null,
  highlightedProduct: null,
  leasingInfo: {},
  leasingLoading: false,
  warrantyType: null,
  warranties: {},
  customOptions: {},
}

export const actionGetShippingInformation = createAsyncThunk(
  'cart/getShippingInformation',
  async (params) => {
    const country = params?.country || ''
    const carrier = params?.carrier || ''
    const method = params?.method || ''
    const res = await cartService.getShippingInformation(
      country,
      carrier,
      method,
    )
    return res
  },
)

export const actionCreate = createAsyncThunk(
  'cart/create',
  async (token, thunkAPI) => {
    const res = await cartService.create(token)
    if (res.code === 200) {
      thunkAPI.dispatch(actionGetWarranty())
    }
    return res
  },
)

export const actionClear = createAsyncThunk(
  'cart/clear',
  async (params, { getState }) => {
    const prevCart = getState().cart
    const res = await cartService.clear()
    return {
      ...res,
      prevCart: { products: prevCart.products, totals: prevCart.totals },
    }
  },
)

export const actionSetWarrantyType = createAsyncThunk(
  'cart/setWarrantyType',
  async (params, thunkAPI) => {
    const res = await cartService.setWarrantyType(params)
    if (res.code === 200) {
      thunkAPI.dispatch(actionGetShippingInformation())
    }
    return res
  },
)

export const actionGetWarrantyType = createAsyncThunk(
  'cart/getWarrantyType',
  async () => {
    const res = await cartService.getWarrantyType()
    return res
  },
)
export const actionGetWarranty = createAsyncThunk(
  'cart/getWarranty',
  async () => {
    const res = await cartService.getWarranty()
    return res
  },
)

export const actionGetLeasingInfo = createAsyncThunk(
  'cart/getLeasingInfo',
  async (price, { getState }) => {
    if (getState().cart.leasingInfo[price.toString()])
      return getState().cart.leasingInfo[price.toString()]

    const res = await cartService.getLeasingInfo(price)
    return { ...res, price }
  },
)

export const actionPull = createAsyncThunk(
  'cart/pull',
  async (params, thunkAPI) => {
    const token = params?.token || store.getState().user.token || ''
    const cartId = params?.cartId || store.getState().cart.id
    const storeCode = params?.language || store.getState().store.language.code
    const res = await cartService.pull(token, cartId, storeCode)
    if (res.code !== 200) {
      thunkAPI.dispatch(resetCart())
      return []
    }
    const products = await productService.getProductsBySku(
      res.result.map((product) => product.sku),
      storeCode,
    )
    const cartProducts = []

    const actualProducts = thunkAPI.getState().cart.products || []

    await res.result.forEach((item) => {
      const product = products.products.find((p) => p._source.sku === item.sku)
      if (product) {
        cartProducts.push({
          ...item,
          id: product.id,
          name: product._source.name,
          rrp: product._source.rrp,
          small_image: product._source.small_image,
          deliverytime: product._source.deliverytime,
          id: product._source.id,
          url_path: product._source.url_path,
          no_leasing: product._source.no_leasing,
          breadcrumbs:
            actualProducts.find((p) => p.sku === item.sku)?.breadcrumbs || [],
        })
      }
    })
    if (cartProducts.length > 0) {
      thunkAPI.dispatch(actionGetShippingInformation())
    }
    if (res.code === 200) {
      thunkAPI.dispatch(actionGetWarrantyType())
    }
    return cartProducts
  },
)

export const actionDeleteItem = createAsyncThunk(
  'cart/deleteItem',
  async (params, thunkAPI) => {
    const res = await cartService.deleteItem(params)
    if (res.code === 200) {
      thunkAPI.dispatch(actionPull())
    }
    return res
  },
)

export const actionAddToCart = createAsyncThunk(
  'cart/addToCart',
  async (params, thunkAPI) => {
    const sku = params?.product.sku
    const qty = params?.qty || 1
    const force = params?.force || false
    if (!sku) return { code: 400, message: 'Missing product sku' }
    const customOptions = thunkAPI.getState().cart?.customOptions || {}

    const res = await cartService.addToCart(
      sku,
      qty,
      force,
      customOptions[sku] || false,
    )
    thunkAPI.dispatch(actionGetShippingInformation())
    const breadcrumbs = thunkAPI.getState().seo.breadcrumbs || []
    return {
      code: res.code,
      result: {
        ...res.result,
        id: params.product.id,
        rrp: params.product.rrp,
        small_image: params.product.small_image
          ? params.product.small_image
          : params.product.image,
        deliverytime: params.product.deliverytime,
        url_path: params.product.url_path,
        prev_qty: force ? params.product.qty : 0,
        custom_options: params.product.custom_options,
        no_leasing: params.product.no_leasing,
        storePrice: params.product.storePrice,
        breadcrumbs,
      },
    }
  },
)

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setHighlightedProduct: (state, action) => {
      state.highlightedProduct = action.payload
    },
    setShippingMethods: (state, action) => {
      state.shippingMethods = action.payload
    },
    resetCart: () => initialState,
    setCustomOption: (state, action) => {
      state.customOptions = {
        ...state.customOptions,
        [action.payload.sku]: {
          id: action.payload.id,
          value: action.payload.value,
          price: action.payload.price,
        },
      }
    },
    clearCustomOptions: (state) => {
      state.customOptions = {}
    },
  },
  extraReducers: (builder) => {
    return builder
      .addCase(actionGetShippingInformation.pending, (state) => {
        state.loading = true
      })
      .addCase(actionGetShippingInformation.fulfilled, (state, action) => {
        if (action.payload?.code === 200) {
          state.paymentMethods = action.payload.result.payment_methods
          state.totals = action.payload.result.totals
          state.products = state.products.map((product) => ({
            ...product,
            totals: action.payload.result.totals.items.find(
              (item) => item.item_id === product.item_id,
            ),
          }))
        }
        state.loading = false
      })
      .addCase(actionGetShippingInformation.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
      .addCase(actionGetLeasingInfo.pending, (state) => {
        state.leasingLoading = true
      })
      .addCase(actionGetWarranty.fulfilled, (state, action) => {
        if (action.payload?.code === 200) {
          state.warranties =
            action.payload.result?.length > 0 ? action.payload.result[0] : []
        }
      })
      .addCase(actionGetWarrantyType.fulfilled, (state, action) => {
        if (action.payload?.code === 200) {
          state.warrantyType = action.payload.result
        }
      })
      .addCase(actionGetLeasingInfo.fulfilled, (state, action) => {
        if (action.payload?.code === 200) {
          state.leasingInfo[action.payload.price.toString()] =
            action.payload.result.leasing_info
        }
        state.leasingLoading = false
      })
      .addCase(actionGetLeasingInfo.rejected, (state, action) => {
        state.leasingLoading = false
        state.error = action.error
      })
      .addCase(actionCreate.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          state.id = action.payload.result
        }
      })
      .addCase(actionClear.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          return { ...initialState, id: state.id, leasing: state.leasing }
        }
      })
      .addCase(actionPull.fulfilled, (state, action) => {
        state.products = action.payload
      })
      .addCase(actionAddToCart.pending, (state) => {
        state.loading = true
      })
      .addCase(actionAddToCart.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          const currentProduct = state.products.find(
            (product) => product.sku === action.payload.result.sku,
          )
          if (currentProduct) {
            state.products = state.products.map((product) => {
              if (product.sku === action.payload.result.sku) {
                product.qty = action.payload.result.qty
              }
              return product
            })
          } else {
            state.products = [...state.products, action.payload.result]
          }
          state.highlightedProduct = action.payload.result.sku
        }
      })
      .addCase(actionAddToCart.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
      .addCase(actionSetWarrantyType.fulfilled, (state, action) => {
        state.warrantyType = action.meta.arg
      })
  },
})

export const {
  setHighlightedProduct,
  setShippingMethods,
  resetCart,
  setCustomOption,
  clearCustomOptions,
} = cartSlice.actions

export default cartSlice.reducer
