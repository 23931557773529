import { apiGet } from '@utils/api'
import { store } from '@features/store'

const orderHistory = async (
  token = store.getState().user.token,
  pageSize = 10,
  currentPage = 1,
) => {
  // Get user's order history
  const res = await apiGet(
    `ext/ggm-order-history/order-history/${pageSize}/${currentPage}/desc?token=${token}`,
  )
  return res
}

export default orderHistory
