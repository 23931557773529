import { apiGet } from '@utils/api'
import { store } from '@features/store'

const getGiroCode = async () => {
  const { id, backendOrderId } = store.getState().checkout.lastOrder
  const res = await apiGet(
    `ext/n98-girocode/getGiroCode/${backendOrderId}/${id}`
  )
  if (res.code === 200) {
    return res.result
  }
  return {}
}

export default getGiroCode
