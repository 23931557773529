import { apiPost } from '@utils/api'
import { store } from '@features/store'
import { appConfig } from '@utils/appConfig'

const getTax = async (locale) => {
  const elasticsearch = locale
    ? appConfig.elasticsearch[locale]
    : store.getState().store.elasticsearch

  const res = await apiPost(`catalog/${elasticsearch}/taxrule/_search`)

  if (res.hits && res.hits.hits && res.hits.hits.length > 0) {
    const hits = res.hits.hits
    return hits.map((hit) => hit._source)
  }

  return res
}

export default getTax
