import PropTypes from 'prop-types'
import Link from '@components/ui/Link'
import Icon from '@components/ui/Icon'
import { useDispatch, useSelector } from 'react-redux'
import { setMenuModal } from '@features/ui/uiSlice'
import Image from '@components/ui/Image'
import { useRouter } from 'next/router'
import Searchbar from '@components/blocks/Layout/Layout-ui/Searchbar'
import cn from '@utils/helpers/cn'
import { storeView } from '@utils/storeView'
import useI18n from '@utils/hooks/useI18n'

const logo20 = [
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es',
  'fr',
  'hr',
  'hu',
  'it',
  'nl',
  'pl',
  'pt',
  'ro',
  'sv',
  'tr',
]

const MobileHeader = ({ categories, checkout }) => {
  const i18n = useI18n()
  const dispatch = useDispatch()
  const router = useRouter()
  const route = router.asPath
  const stateStore = useSelector((state) => state.store)
  const currentStore = storeView(stateStore)
  const { language, country, currency } = useSelector((state) => state.store)
  const currentRoute = `/${language?.code || 'de'}-${country?.code || 'de'}-${currency?.code || 'eur'}`
  return (
    <>
      <>
        {!checkout && (
          <div
            className="absolute -left-1 top-1.5 flex items-center lg:hidden"
            onClick={() => dispatch(setMenuModal(true))}
          >
            <button className="outline-none">
              <svg
                className="size-9"
                strokeWidth="1"
                viewBox="0 0 24 24"
                stroke="white"
              >
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </button>
          </div>
        )}
        <Link href="" locale data-test="logo">
          {logo20.includes(language.code) ? (
            <Image
              src={`/logo20/${language.code}.svg`}
              alt="GGM logo"
              className={cn(
                'w-full cursor-pointer max-lg:w-[153px] lg:min-h-[36px] lg:min-w-[260px] lg:max-w-[260px]',
                {
                  'min-w-[143px] max-lg:pt-[12px]': !checkout,
                },
              )}
              width={260}
              height={36}
            />
          ) : (
            <Image
              src="/logo.svg"
              alt="GGM logo"
              className={cn(
                'w-full cursor-pointer max-lg:w-[153px] lg:min-h-[36px] lg:min-w-[215px] lg:max-w-[215px]',
                {
                  'min-w-[143px] max-lg:pt-[12px]': !checkout,
                },
              )}
              width={215}
              height={36}
            />
          )}
        </Link>
      </>
      {!checkout && (
        <div className="flex min-h-[38px] w-full items-center gap-6">
          {route !== currentRoute && (
            <Icon
              icon="icon-chevron-down"
              className="ml-3 w-3 rotate-90 text-lg text-white lg:hidden"
              onClick={router.back}
            />
          )}
          <Searchbar key={route} />
        </div>
      )}
      {route !== currentRoute + '/categorylist' && !checkout && (
        <div className="w-full lg:hidden">
          <ul className="container-scroll relative flex w-full flex-nowrap gap-4 overflow-x-auto pb-[13px] pt-[5px] text-sm">
            {categories?.map((category, i) => (
              <li key={i}>
                <Link
                  href={category?.url_path}
                  className="w-max text-nowrap text-white"
                  locale
                >
                  {category?.name}
                </Link>
              </li>
            ))}
            {currentStore.additionalMainNavItems.map((item, i) => (
              <li key={i}>
                <Link
                  href={
                    item.url.includes('ggmmoebel.com')
                      ? `${item.url}${currentRoute}`
                      : item.url
                  }
                  external={item.external}
                  locale={item.locale}
                  className="w-max text-nowrap text-white"
                >
                  {i18n.t(item.title)}
                </Link>
              </li>
            ))}
            <li className="min-w-[50px]" />
          </ul>
          <div className="pointer-events-none absolute bottom-2 right-0 h-6 w-16 bg-gradient-to-r from-transparent to-[#282d34] text-white" />
        </div>
      )}
    </>
  )
}

MobileHeader.propTypes = {
  categories: PropTypes.array,
  checkout: PropTypes.bool,
}
export default MobileHeader
