import { apiPost } from '@utils/api'
import paymentData from '@utils/helpers/paymentData'
import { checkoutService } from '@utils/magento2'
import { cartService } from '@utils/magento2'
import { store } from '@features/store'
import { setPaymentError } from '@features/checkout/checkoutSlice'

const placeOrder = async (order_uuid = null, method_code = null) => {
  const body = paymentData(order_uuid, method_code)
  const storeCode = store.getState().store.language.code
  const countryCode = store.getState().store.country.code
  const currencyCode = store.getState().store.currency.code

  const shippingInformationData = {
    currencySign: currencyCode.toUpperCase(),
    addressInformation: {
      shippingAddress: body.addressInformation.shippingAddress,
      billingAddress: body.addressInformation.billingAddress,
      shippingCarrierCode: body.addressInformation.shipping_carrier_code,
      shippingMethodCode: body.addressInformation.shipping_method_code,
    },
  }

  const shippingInformation = await cartService.getShippingInformation(
    countryCode,
    body.addressInformation.shipping_method_code,
    body.addressInformation.shipping_carrier_code,
    shippingInformationData,
  )

  if (shippingInformation.code === 200) {
    const res = await apiPost(`order?storeCode=${storeCode}`, body)

    if (res.code === 200) {
      checkoutService.orderComplete(
        res.result.orderNumber,
        res.result.backendOrderId || res.result.magentoOrderId,
      )
    } else if (res.code > 300 || res.code < 200) {
      store.dispatch(
        setPaymentError({
          message:
            res.result || i18n.t('Payment method failed. Please try again.'),
        }),
      )
    }

    return res
  }
  store.dispatch(
    setPaymentError({
      message:
        shippingInformation.result ||
        i18n.t('Payment method failed. Please try again.'),
    }),
  )
  return {}
}

export default placeOrder
