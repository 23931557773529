import { apiPost } from '@utils/api'

const setAddress = async (token, type, addressId, storeCode) => {
  const res = await apiPost(
    `ext/n98-customer-address/set-default?token=${token}&type=${type}&address_id=${addressId}&storeCode=${storeCode}`
  )
  return res
}

export default setAddress
