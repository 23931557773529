import create from '@utils/magento2/cart/create'
import getShippingInformation from '@utils/magento2/cart/getShippingInformation'
import getShippingMethods from '@utils/magento2/cart/getShippingMethods'
import pull from '@utils/magento2/cart/pull'
import assign from '@utils/magento2/cart/assign'
import addToCart from '@utils/magento2/cart/addToCart'
import clear from '@utils/magento2/cart/clear'
import deleteItem from '@utils/magento2/cart/deleteItem'
import getLeasingInfo from '@utils/magento2/cart/getLeasingInfo'
import getLeasingLegal from '@utils/magento2/cart/getLeasingLegal'
import setWarrantyType from '@utils/magento2/cart/setWarrantyType'
import shareCart from '@utils/magento2/cart/shareCart'
import fetchSharedCart from '@utils/magento2/cart/fetchSharedCart'
import mergeSharedCart from '@utils/magento2/cart/mergeSharedCart'
import getWarranty from '@utils/magento2/cart/getWarranty'
import getWarrantyType from '@utils/magento2/cart/getWarrantyType'
import applyCoupon from '@utils/magento2/cart/applyCoupon'
import deleteCoupon from '@utils/magento2/cart/deleteCoupon'

const cart = {
  create,
  getWarranty,
  getWarrantyType,
  getShippingInformation,
  getShippingMethods,
  pull,
  assign,
  addToCart,
  clear,
  deleteItem,
  getLeasingInfo,
  getLeasingLegal,
  setWarrantyType,
  shareCart,
  fetchSharedCart,
  mergeSharedCart,
  applyCoupon,
  deleteCoupon,
}

export default cart
