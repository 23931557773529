import { store } from '@features/store'
import { apiPost } from '@utils/api'

const createPayByLink = async (
  countryCode,
  customerEmail,
  orderReference,
  requestedAmount,
  requestedAmountCurrency
) => {
  const token = store.getState().user.token
  const body = {
    payload: {
      createPayByLinkRequest: {
        countryCode,
        customerEmail,
        orderReference,
        requestedAmount,
        requestedAmountCurrency,
      },
    },
    token,
  }
  const res = await apiPost(`ext/ggm-sales-agency/create-pay-by-link`, body)
  return res
}

export default createPayByLink
