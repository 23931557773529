import { apiPost } from '@utils/api'
import { store } from '@features/store'
import { appConfig } from '@utils/appConfig'
import { breadcrumbsService, categoryService } from '@utils/magento2'
import bodybuilder from 'bodybuilder'

const getBreadcrumbsByCategoryId = async (ids, locale) => {
  if (!ids) {
    return {}
  }

  const body = bodybuilder().filter('terms', '_id', ids).build()

  const sourceInclude = ['path', 'url_path']

  const elasticsearch = locale
    ? appConfig.elasticsearch[locale]
    : store.getState().store.elasticsearch

  const res = await apiPost(
    `catalog/${elasticsearch}/category/_search?_source_exclude=sgn&_source_include=${sourceInclude.join(
      ',',
    )}&from=0&size=100&sort=position:asc`,
    JSON.stringify(body),
  )

  const rootCategory = await categoryService.getSubCategoryById(
    'root',
    locale,
    ['path', 'show_on_storefront'],
  )
  const filterRootCategory =
    rootCategory && Array.isArray(rootCategory)
      ? rootCategory
          ?.filter((item) => item.show_on_storefront === 1)
          ?.map((item) => item.path) || []
      : []

  if (res?.hits?.hits?.length > 0) {
    const hits = res.hits.hits
    const filter = hits.filter((hit) => {
      return filterRootCategory.some((path) =>
        hit?._source?.path?.startsWith(path),
      )
    })
    if (Array.isArray(filter) && filter.length > 0) {
      const path = filter[0]._source.path
      const breadcrumbs = await breadcrumbsService.getBreadcrumbsByCategoryPath(
        path,
        locale,
      )
      return breadcrumbs
    }
  }

  return []
}

export default getBreadcrumbsByCategoryId
