import { store } from '@features/store'

const formatPrice = (value, convert = true, format = true, prefix) => {
  // Get store state data
  const {
    store: { currency, language },
    currency: { rates },
  } = store.getState()

  // Initialize currency and locale codes
  let currencyCode = currency.code
  let localeCode = language.code

  // Handle prefix if provided
  if (prefix?.split('-').length === 3) {
    const [locale, , curr] = prefix.split('-')
    localeCode = locale
    currencyCode = curr
  }

  // Calculate currency rate
  const currencyRate = convert
    ? rates?.find(
        (rate) => rate.currency_to.toUpperCase() === currencyCode.toUpperCase(),
      )?.rate || 1
    : 1

  // Calculate final price
  const finalPrice = convert ? value * currencyRate : value

  // Return unformatted price if format is false
  if (!format) {
    return parseFloat(finalPrice).toFixed(2)
  }

  // Handle Arabic locale special case
  const formatterLocale = localeCode?.toLowerCase().startsWith('ar-')
    ? 'de-DE'
    : localeCode

  // Format price using Intl
  try {
    const formatter = new Intl.NumberFormat(formatterLocale, {
      style: 'currency',
      currency: currencyCode,
    })
    return formatter.format(parseFloat(finalPrice))
  } catch (error) {
    console.error('Price formatting error:', error)
    return parseFloat(finalPrice).toFixed(2)
  }
}

export default formatPrice
