import { apiPost } from '@utils/api'
import { store } from '@features/store'
import countriesData from '@utils/json/countries.json'

const getPhoneCode = (countryCode) =>
  countriesData.find((c) => c.code.toLowerCase() === countryCode?.toLowerCase())
    ?.phone || '49'

const getShippingInformation = async (
  country,
  carrier,
  method,
  data = null,
) => {
  const languageCode = store.getState().store.language.code
  const countryCode = store.getState().store.country.code
  const currencyCode = store.getState().store.currency.code
  const cartId = store.getState().cart.id
  const token = store.getState().user.token
  const checkoutData = store.getState().checkout || {}

  let body = {
    currencySign: currencyCode.toUpperCase(),
    addressInformation: {
      shippingAddress: {
        countryId: country?.toUpperCase() || countryCode?.toUpperCase(),
      },
      billingAddress: {},
      shippingCarrierCode: carrier || 'uninsured',
      shippingMethodCode: method || 'uninsured',
    },
  }

  if (data) {
    body = data
  } else if (
    country === '' &&
    carrier === '' &&
    method === '' &&
    checkoutData?.shippingDetails &&
    checkoutData?.paymentDetails &&
    checkoutData?.personalDetails &&
    checkoutData?.shippingMethod &&
    Object.keys(checkoutData?.shippingMethod)?.length > 0 &&
    Object.keys(checkoutData?.personalDetails)?.length > 0 &&
    Object.keys(checkoutData?.paymentDetails)?.length > 0 &&
    Object.keys(checkoutData?.shippingDetails)?.length > 0
  ) {
    const billingPhoneCode = getPhoneCode(
      checkoutData.paymentDetails.phoneNumberCountryCode,
    )
    const billingMobilePhoneCode = getPhoneCode(
      checkoutData.paymentDetails.mobilePhoneNumberCountryCode,
    )
    const shippingPhoneCode = getPhoneCode(
      checkoutData.shippingDetails.phoneNumberCountryCode,
    )
    const shippingMobilePhoneCode = getPhoneCode(
      checkoutData.shippingDetails.mobilePhoneNumberCountryCode,
    )

    body = {
      currencySign: currencyCode.toUpperCase(),
      addressInformation: {
        shippingAddress: {
          countryId: checkoutData.shippingDetails.countryId.toUpperCase(),
          firstname: checkoutData.shippingDetails.firstname,
          lastname: checkoutData.shippingDetails.lastname,
          city: checkoutData.shippingDetails.city,
          postcode: checkoutData.shippingDetails.postcode,
          street: [
            checkoutData.shippingDetails.street,
            checkoutData.shippingDetails.houseNumber,
          ],
          telephone:
            checkoutData.shippingDetails.phoneNumber?.length > 0
              ? `+${shippingPhoneCode}${checkoutData.shippingDetails.phoneNumber}`
              : '',
          extension_attributes: {
            mobilephone:
              checkoutData.shippingDetails.mobilePhoneNumber?.length > 0
                ? `+${shippingMobilePhoneCode}${checkoutData.shippingDetails.mobilePhoneNumber}`
                : '',
          },
        },
        billingAddress: {
          email: checkoutData.personalDetails.email,
          firstname: checkoutData.paymentDetails.firstname,
          lastname: checkoutData.paymentDetails.lastname,
          city: checkoutData.paymentDetails.city,
          postcode: checkoutData.paymentDetails.postcode,
          street: [
            checkoutData.paymentDetails.street,
            checkoutData.paymentDetails.houseNumber,
          ],
          telephone:
            checkoutData.paymentDetails.phoneNumber?.length > 0
              ? `+${billingPhoneCode}${checkoutData.paymentDetails.phoneNumber}`
              : '',
          countryId: checkoutData.paymentDetails.countryId.toUpperCase(),
          extension_attributes: {
            mobilephone:
              checkoutData.paymentDetails.mobilePhoneNumber?.length > 0
                ? `+${billingMobilePhoneCode}${checkoutData.paymentDetails.mobilePhoneNumber}`
                : '',
            nif:
              checkoutData.paymentDetails.taxId?.length > 3
                ? checkoutData.paymentDetails.taxId
                : '',
          },
        },
        shippingCarrierCode: checkoutData.shippingMethod.carrier,
        shippingMethodCode: checkoutData.shippingMethod.code,
      },
    }
  }

  const res = await apiPost(
    `cart/shipping-information?token=${
      token ? token : ''
    }&cartId=${cartId}&storeCode=${languageCode}`,
    JSON.stringify(body),
  )

  return res
}

export default getShippingInformation
