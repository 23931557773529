import { apiPost } from '@utils/api'
import { store } from '@features/store'
import { appConfig } from '@utils/appConfig'
import bodybuilder from 'bodybuilder'

const getReviews = async (id, locale) => {
  const groupToken = store.getState().user.groupToken || ''
  if (!id) {
    return []
  }

  const body = bodybuilder()
    .filter('terms', 'product_id', [parseInt(id)])
    .filter('terms', 'review_status', [1])
    .build()

  body.groupToken = groupToken

  const res = await apiPost(
    `catalog/${appConfig.elasticsearch[locale]}/review/_search?from=0&size=50&sort=`,
    JSON.stringify(body)
  )

  if (res.hits && res.hits.hits && res.hits.hits.length > 0) {
    return { reviews: res.hits.hits, total: res.hits.total.value }
  }

  return res
}

export default getReviews
