import { apiPost } from '@utils/api'
import { store } from '@features/store'

const register = async (body) => {
  const storeCode = store.getState().store.language.code
  const res = await apiPost(`user/create?storeCode=${storeCode}`, body)
  return res
}

export default register
