import axios from 'axios'
import { store } from '@features/store'
import { setNotification } from '@features/ui/uiSlice'
import { resetUser, setToken } from '@features/user/userSlice'
import { resetCart } from '@features/cart/cartSlice'
import { userService } from '@utils/magento2'

// Constants
const API_BASE_URL = process.env.NEXT_PUBLIC_API_ENDPOINT
const API_TIMEOUT = 30000

// Create axios instance
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'content-type': 'application/json; charset=utf-8',
  },
  timeout: API_TIMEOUT,
})

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const {
      user: { token },
    } = store.getState()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => Promise.reject(error),
)

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    const {
      user: { refreshToken },
    } = store.getState()

    // Token refresh process
    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      refreshToken
    ) {
      originalRequest._retry = true
      try {
        const { result: newToken } = await userService.refresh(refreshToken)
        store.dispatch(setToken(newToken))
        originalRequest.headers.Authorization = `Bearer ${newToken}`
        return axiosInstance(originalRequest)
      } catch (refreshError) {
        store.dispatch(resetUser())
        store.dispatch(resetCart())
        return Promise.reject(refreshError)
      }
    }
    return Promise.reject(error)
  },
)

// Error message handler
const handleErrorMessage = (error, endpoint) => {
  const message =
    error.response?.data?.message ||
    error.response?.data?.result?.error?.message ||
    error.response?.data?.result?.errorMessage ||
    error.response?.data?.result ||
    'Error'

  // Filter specific error messages
  const shouldShowError =
    !endpoint?.startsWith('ext/ggm-leasing/rate-price') &&
    message &&
    message !== 'Error' &&
    typeof message === 'string' &&
    !message.includes('No such entity with')

  if (shouldShowError) {
    store.dispatch(setNotification({ message, type: 'error' }))
  }

  return error?.response?.data || null
}

// Main request handler
const makeRequest = async (method, endpoint, body = null) => {
  // Format the endpoint
  const formattedEndpoint = endpoint.startsWith('/') ? endpoint : `/${endpoint}`

  try {
    // Create request configuration
    const requestConfig = {
      method,
      url: formattedEndpoint,
      ...(body && { data: body }),
    }

    const { data } = await axiosInstance(requestConfig)
    return data
  } catch (error) {
    return handleErrorMessage(error, endpoint)
  }
}

// Exported API methods
export const apiGet = (endpoint) => makeRequest('GET', endpoint)
export const apiPost = (endpoint, body) => makeRequest('POST', endpoint, body)
export const apiPut = (endpoint, body) => makeRequest('PUT', endpoint, body)
export const apiDelete = (endpoint, body) =>
  makeRequest('DELETE', endpoint, body)
