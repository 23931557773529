import setPaypalExpressCheckout from '@utils/magento2/checkout/setPaypalExpressCheckout'
import reserveOrderId from '@utils/magento2/checkout/reserveOrderId'
import placeOrder from '@utils/magento2/checkout/placeOrder'
import adyenPaymentMethods from '@utils/magento2/checkout/adyenPaymentMethods'
import adyenInitPayment from '@utils/magento2/checkout/adyenInitPayment'
import adyenAdditionalPayment from '@utils/magento2/checkout/adyenAdditionalPayment'
import orderComplete from '@utils/magento2/checkout/orderComplete'
import getGiroCode from '@utils/magento2/checkout/getGiroCode'
import setShippingOptions from '@utils/magento2/checkout/setShippingOptions'
import albisLeasingPropose from '@utils/magento2/checkout/albisLeasingPropose'
import monduPayment from '@utils/magento2/checkout/monduPayment'

const checkout = {
  setPaypalExpressCheckout,
  reserveOrderId,
  placeOrder,
  adyenPaymentMethods,
  adyenInitPayment,
  adyenAdditionalPayment,
  orderComplete,
  getGiroCode,
  setShippingOptions,
  albisLeasingPropose,
  monduPayment,
}

export default checkout
