import { apiPost } from '@utils/api'
import { store } from '@features/store'

const deleteCoupon = async () => {
  const storeCode = store.getState().store.language.code
  const token = store.getState().user.token || ''
  const cartId = store.getState().cart.id

  const res = await apiPost(
    `cart/delete-coupon?token=${token}&cartId=${cartId}&storeCode=${storeCode}`
  )
  return res
}

export default deleteCoupon
