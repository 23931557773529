import PropTypes from 'prop-types'
import cn from '@utils/helpers/cn'
import Link from '@components/ui/Link'
import Text from '@components/ui/Text'
import Price from '@components/ui/Price'
import DeliveryTime from '@components/ui/DeliveryTime'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Icon from '@components/ui/Icon'
import RRP from '@components/ui/RRP'
import {
  actionAddToCart,
  setHighlightedProduct,
} from '@features/cart/cartSlice'
import useI18n from '@utils/hooks/useI18n'
import { actionDeleteItem } from '@features/cart/cartSlice'
import { setNotification } from '@features/ui/uiSlice'
import Image from '@components/ui/Image'
import Quantity from '@components/ui/Quantity'

const CartProduct = ({ product, className }) => {
  const dispatch = useDispatch()
  const i18n = useI18n()
  const quoteId = useSelector((state) => state.cart.id)
  const country = useSelector((state) => state.store.country)
  const highlightedProduct = useSelector(
    (state) => state.cart.highlightedProduct,
  )
  const selectedCountry = product?.deliverytime?.find(
    (item) => item.country_id === country.code.toUpperCase(),
  )

  const addToCart = (e) => {
    dispatch(
      actionAddToCart({
        product,
        qty: e,
        force: true,
      }),
    )
  }

  const deleteItem = () => {
    dispatch(
      setNotification({
        type: 'removeItems',
        message: i18n.t(
          'Are you sure you would like to remove this item from the shopping cart?',
        ),
        callback: () =>
          dispatch(
            actionDeleteItem({
              quoteId,
              product,
            }),
          ),
      }),
    )
  }
  useEffect(() => {
    if (highlightedProduct === product?.sku) {
      setTimeout(() => {
        dispatch(setHighlightedProduct(null))
      }, 2000)
    }
  }, [highlightedProduct])
  return (
    <div
      className={cn(
        'transition-color flex flex-row border-b border-[#E2E2E2] bg-white px-2 py-3 delay-500',
        { 'bg-yellow-100': highlightedProduct === product?.sku },
        className,
      )}
    >
      <div>
        <Link href={product?.url_path} locale className="flex items-center">
          <Image
            src={`${process.env.NEXT_PUBLIC_IMG_ENDPOINT}media/catalog/product${product?.small_image}`}
            alt={product?.name}
            blurDataURL="/logo_placeholder.png"
            placeholder="blur"
            width={130}
            height={130}
            className="aspect-[6/5] w-32 object-contain mix-blend-multiply"
          />
        </Link>
      </div>
      <div className="flex w-full flex-col items-start">
        <Link
          href={product?.url_path}
          locale
          className="mb-1 flex w-full justify-between font-semibold leading-4 text-[#282D34]"
        >
          {product?.name}
        </Link>
        <Text variant="inlineText" className="text-xs text-[#7B8491]">
          {i18n.t('SKU:')} {product?.sku}
        </Text>
        {product?.product_option?.extension_attributes?.custom_options?.map(
          (po) => (
            <Text
              variant="inlineText"
              className="mt-3 w-full text-xs text-[#7B8491] print:text-sm"
              key={po.option_id}
            >
              {product?.custom_options?.find(
                (option) =>
                  option.option_id.toString() === po.option_id.toString(),
              )?.title + ':' || ''}{' '}
              {product?.custom_options
                ?.find(
                  (option) =>
                    option.option_id.toString() === po.option_id.toString(),
                )
                ?.values?.find(
                  (value) =>
                    value.option_type_id.toString() ===
                    po.option_value.toString(),
                )?.title || ''}
            </Text>
          ),
        ) || null}
        <div className="mt-3 flex w-full items-center justify-between">
          <DeliveryTime
            deliveryTimeMin={selectedCountry?.deliverytime_min}
            deliveryTimeMax={selectedCountry?.deliverytime_max}
            deliveryTimeUnit={selectedCountry?.deliverytime_unit}
          />
          <div
            className="flex cursor-pointer items-center justify-start gap-1 text-[#C7C7C7] print:hidden"
            onClick={deleteItem}
          >
            <Icon icon="icon-delete" className="text-[18px]" />
            <Text variant="inlineText" className="text-xs">
              {i18n.t('Remove')}
            </Text>
          </div>
        </div>
        <div className="mt-2 flex w-full items-center justify-between">
          <span className="flex flex-col">
            <RRP
              oldPrice={
                product?.storePrice?.rrp ? product.storePrice.rrp : product?.rrp
              }
              type="cart"
            />
            <Price
              price={
                product?.totals?.row_total ||
                product?.storePrice?.price * product?.qty ||
                product.price
              }
              type="cartProduct"
            />
          </span>
          <Quantity
            current={product?.qty}
            callback={addToCart}
            key={`${product?.item_id}-${product?.qty}`}
          />
        </div>
      </div>
    </div>
  )
}

CartProduct.propTypes = {
  product: PropTypes.object,
}

export default CartProduct
