import LanguageModal from '@components/blocks/Layout/Layout-ui/LanguageModal'
import Icon from '@components/ui/Icon'
import { useSelector } from 'react-redux'
import useI18n from '@utils/hooks/useI18n'
import Image from '@components/ui/Image'

const LanguageMobile = () => {
  const { language } = useSelector((state) => state.store)
  const i18n = useI18n()
  return (
    <span className="w-full cursor-pointer text-white">
      <details type="checkbox" className="group cursor-pointer">
        <summary className="mx-3 flex items-baseline justify-between border-b border-gray-500 pb-5">
          <span className="flex items-center gap-2 pl-1 pt-5 text-base font-medium">
            {i18n.t('language')} & {i18n.t('currency')}
            <Image
              width={24}
              height={24}
              src={`/vsf/flags/1x1/${language.flagCode}.svg`}
              alt="Country flag"
            />
          </span>
          <Icon
            icon="icon-chevron-down"
            className="-translate-x-2 -rotate-90 group-open:rotate-0"
          />
        </summary>
        <span className="relative m-3 flex flex-col flex-wrap gap-1 px-4 text-black transition-all">
          <LanguageModal />
        </span>
      </details>
    </span>
  )
}
export default LanguageMobile
