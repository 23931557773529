import { apiGet } from '@utils/api'
import { store } from '@features/store'

const fetchSharedCart = async (id) => {
  const userToken = store.getState().user?.token || ''
  const res = await apiGet(
    `ext/ggm-shared-cart/get-shared-cart/${id}?token=${userToken}`
  )
  return res
}

export default fetchSharedCart
