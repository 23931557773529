import React from 'react'
import PropTypes from 'prop-types'
import cn from '@utils/helpers/cn'

const Button = ({ variant = 'normal', className, children, ...props }) => {
  const variants = {
    primary:
      'disabled:pointer-events-none bg-gradient-to-b from-primaryButtonColor to-secondaryButtonColor hover:bg-gradient-to-b hover:from-primaryButtonColorHover hover:to-secondaryButtonColorHover border border-buttonGreenBorder text-white',
    normal:
      'bg-gradient-to-b from-background to-backgroundBtn hover:bg-gradient-to-b hover:from-normalBtnStartHover hover:to-normalBtnEndHover border-buttonNormalBorder border',
    dark: 'bg-gradient-to-b from-darkButtonColor to-darkButtonColor hover:bg-gradient-to-b hover:from-redButtonColor hover:to-redButtonColor text-white',
    red: 'bg-[#B90202] hover:bg-[#9A0000] text-white',
    gray: 'bg-[#9D9D9D] hover:bg-[#707070] text-white',
    leasing:
      'disabled:pointer-events-none bg-gradient-to-b from-[#7C849C] to-[#000] hover:bg-gradient-to-b hover:from-[#000] hover:to-[#7C8490] border border-[#4B515A] text-white',
    blue: 'bg-gradient-to-b from-[#45A1F0] to-[#004FA5] hover:bg-gradient-to-b hover:from-[#004FA5] hover:to-[#45A1F0] border border-[#1161B1] text-white',
  }

  return (
    <button
      className={cn(
        'flex w-full items-center justify-center overflow-hidden rounded px-1 font-semibold outline-none disabled:opacity-80',
        variants[variant],
        className,
      )}
      {...props}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf([
    'primary',
    'dark',
    'normal',
    'red',
    'gray',
    'blue',
    'leasing',
  ]),
  onClick: PropTypes.func,
}

export default Button
