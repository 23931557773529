import { store } from '@features/store'
import { apiPost } from '@utils/api'

const setOrderCustomer = async (customerIdToQuote) => {
  const token = store.getState().user.token
  const body = {
    customerIdToQuote,
  }
  const res = await apiPost(
    `ext/ggm-sales-agency/set-order-customer?token=${token}`,
    body
  )
  return res
}

export default setOrderCustomer
