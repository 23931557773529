import { apiPost } from '@utils/api'

const resetPasswordValidateCode = async (email, passCode, storeCode) => {
  const res = await apiPost(
    `ext/ggm-reset-password/validate-code?storeCode=${storeCode}`,
    {
      email,
      passCode,
    },
  )
  return res
}

export default resetPasswordValidateCode
