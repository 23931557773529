import { apiPost } from '@utils/api'
import { store } from '@features/store'

const mergeSharedCart = async (cartToken, overrideCart = false, shareCode) => {
  const userToken = store.getState().user?.token || ''
  const body = {
    cartToken,
    overrideCart,
    shareCode,
  }
  const res = await apiPost(
    `ext/ggm-shared-cart/merge-shared-cart?token=${userToken}`,
    body
  )
  return res
}

export default mergeSharedCart
