import customerList from '@utils/magento2/user/salesAgency/customerList' // get customers list
import deassignCustomer from '@utils/magento2/user/salesAgency/deassignCustomer' // deassign customer
import assignCustomer from '@utils/magento2/user/salesAgency/assignCustomer' // assign customer
import createCustomer from '@utils/magento2/user/salesAgency/createCustomer' // create customer
import fetchCustomerOrders from '@utils/magento2/user/salesAgency/fetchCustomerOrders' // fetch customer orders
import fetchAllOrders from '@utils/magento2/user/salesAgency/fetchAllOrders' // fetch all orders
import getPayByLink from '@utils/magento2/user/salesAgency/getPayByLink' // get pay by link
import createPayByLink from '@utils/magento2/user/salesAgency/createPayByLink' // get pay by link
import setOrderCustomer from '@utils/magento2/user/salesAgency/setOrderCustomer' // get pay by link

const salesAgency = {
  customerList,
  assignCustomer,
  deassignCustomer,
  createCustomer,
  fetchCustomerOrders,
  fetchAllOrders,
  getPayByLink,
  createPayByLink,
  setOrderCustomer,
}

export default salesAgency
