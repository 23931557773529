import { store } from '@features/store'
import { apiPost } from '@utils/api'

const removeWishListItem = async (wishlistItemIds, id) => {
  const token = store.getState().user.token
  const wishlistId = id.toString()
  const res = await apiPost(
    `ext/ggm-wishlist/remove-wishlist-item?token=${token}`,
    { wishlistId, wishlistItemIds }
  )
  return res
}

export default removeWishListItem
