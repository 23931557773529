import { store } from '@features/store'
import { apiPost } from '@utils/api'

const setWarrantyType = async (warranty_type) => {
  const cart_id = store.getState().cart.id
  const body = {
    warranty_type,
    cart_id,
  }

  const res = await apiPost(`ext/ggm-warranty/setWarrantyType`, body)
  return res
}

export default setWarrantyType
