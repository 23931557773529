import { apiGet } from '@utils/api'
import { store } from '@features/store'

const me = async (token) => {
  const state = store.getState()
  let userToken = token || null

  if (!userToken && store.getState().user?.token) {
    userToken = store.getState().user?.token
  }
  // Get the current store code
  const storeCode = state.store.language.code

  // Make the request
  const res = await apiGet(`user/me?token=${userToken}&storeCode=${storeCode}`)
  return res
}

export default me
