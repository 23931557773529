import PropTypes from 'prop-types'
import cn from '@utils/helpers/cn'
import Text from '@components/ui/Text'
import Link from '@components/ui/Link'
import useI18n from '@utils/hooks/useI18n'

const PrintDeliveryTime = ({
  status,
  deliveryTimeMin,
  deliveryTimeMax,
  deliveryTimeUnit,
  productPage,
  cart,
  className,
  ...props
}) => {
  const i18n = useI18n()
  return (
    <span
      className={cn(
        'flex max-w-fit items-center gap-1 rounded-md px-2 py-[4px] font-medium',
        {
          'font-medium': deliveryTimeUnit === 'days',
        },
        {
          'text-[#7B8491]': !deliveryTimeUnit,
        },
        cart ? 'text-sm' : 'text-base',
        className
      )}
      {...props}
    >
      {(deliveryTimeUnit === 'included' || deliveryTimeUnit === 'days') && (
        <svg
          data-v-6b21d8f3=""
          data-v-480691e4=""
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          aria-labelledby=""
          role="presentation"
        >
          <g data-v-6b21d8f3="" fill="currentColor">
            <g
              data-v-480691e4=""
              transform="translate(.146)"
              data-v-6b21d8f3=""
            >
              <circle cx="6" cy="6" r="6" transform="translate(-.146)"></circle>
              <path
                fill="#fff"
                stroke="#fff"
                d="M4.935 7.497L3.429 5.991l-.513.509 2.019 2.018 4.491-4.2-.509-.508z"
              ></path>
            </g>
          </g>
        </svg>
      )}
      {!deliveryTimeUnit && (
        <Text
          variant="inlineText"
          className={cn(
            'font-medium',
            cart ? 'text-sm text-black' : 'text-base  text-[#1160B1]'
          )}
        >
          {i18n.t(['We will contact you with the exact delivery time.'])}
        </Text>
      )}
      {deliveryTimeUnit === 'days' && (
        <span className="font-medium ">
          {i18n.t('In stock!')}
          <span className={cn('font-medium', cart ? 'text-sm' : 'text-base')}>
            : {deliveryTimeMin} - {deliveryTimeMax} {i18n.t(deliveryTimeUnit)}
          </span>
        </span>
      )}
      {deliveryTimeUnit === 'weeks' && (
        <Text
          variant="inlineText"
          className={
            ('font-medium ',
            cart ? 'text-sm text-black' : 'text-base text-black')
          }
        >
          <Text variant="inlineText">{i18n.t('Delivery time') + ':'}</Text>
          {' ' +
            deliveryTimeMin +
            ' - ' +
            deliveryTimeMax +
            ' ' +
            i18n.t(deliveryTimeUnit)}
        </Text>
      )}
      {deliveryTimeUnit === 'date' && (
        <Text
          variant="inlineText"
          className={cn(cart ? 'text-sm text-black' : 'text-base text-black')}
        >
          <Text variant="inlineText">{i18n.t('Available from') + ' '}</Text>
          {deliveryTimeMin}
        </Text>
      )}
      {deliveryTimeUnit === 'none' && (
        <Text
          variant="inlineText"
          className={cn(cart ? 'text-sm text-black' : 'text-base text-black')}
        >
          Nur Selbstabholung
        </Text>
      )}
      {deliveryTimeUnit === 'included' && (
        <Text
          variant="inlineText"
          className={cn(cart ? 'text-sm' : 'text-base')}
        >
          {i18n.t('incl')}.{' '}
          <Link
            locale
            href="best-price"
            className={cn(cart ? 'text-black' : 'text-[#0000ee] underline')}
          >
            {i18n.t('Lowest Price Guarantee')}
          </Link>
        </Text>
      )}
    </span>
  )
}

PrintDeliveryTime.propTypes = {
  deliveryTimeMin: PropTypes.string,
  deliveryTimeMax: PropTypes.string,
  deliveryTimeUnit: PropTypes.string,
}

export default PrintDeliveryTime
