import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import useI18n from '@utils/hooks/useI18n'
import Certifications from '@components/blocks/Layout/Layout-ui/Certifications'
import MobileHeader from '@components/blocks/Layout/Layout-ui/MobileHeader'
import Container from '@components/ui/Container/Container'
import Text from '@components/ui/Text/Text'
import { useRouter } from 'next/router'
import Icon from '@components/ui/Icon/Icon'
import dynamic from 'next/dynamic'

const LoginSkeleton = () => (
  <div className="flex h-full animate-pulse items-center justify-center space-x-2 max-lg:hidden">
    <div className="size-[37px] rounded-full bg-gray-300" />
    <div className="flex flex-col space-y-2">
      <div className="h-[10px] w-[50px] rounded-md bg-gray-300" />
      <div className="h-[10px] w-[70px] rounded-md bg-gray-300" />
    </div>
  </div>
)

const Login = dynamic(
  () => import('@components/blocks/Layout/Layout-ui/Login'),
  { loading: () => <LoginSkeleton />, ssr: false },
)

const CheckoutHeader = ({ country = 'de' }) => {
  const i18n = useI18n()
  const router = useRouter()
  const { isMobile } = useSelector((state) => state.ui)
  const [loginPopup, setLoginPopup] = useState(false)
  return (
    <header className="sticky top-0 z-10 w-full bg-[#282d34]">
      <Container>
        <div className="flex w-full items-center">
          <Icon
            icon="icon-chevron-down"
            className="ml-3 w-3 rotate-90 text-base text-white min-[575px]:hidden"
            onClick={router.back}
          />
          <div className="flex min-h-[80px] w-full items-center justify-center min-[575px]:justify-between">
            <MobileHeader checkout />
            <div className="flex max-lg:flex-row-reverse max-[575px]:hidden">
              <Certifications className="min-w-fit lg:pr-12" />
              <Login
                setLoginPopup={setLoginPopup}
                checkout
                loginPopup={loginPopup}
              />
            </div>
          </div>
          {!isMobile && (
            <div className="flex min-w-fit flex-col items-center font-normal text-white">
              <Text variant="body">{i18n.t('Do you need help?')}</Text>
              <a
                href={
                  country.toLowerCase() === 'de'
                    ? 'tel:08007000220'
                    : 'tel:+490255372200'
                }
                className="flex items-center gap-2"
              >
                <Icon icon="icon-phone" className="text-[#7C8490]" />
                {country.toLowerCase() === 'de'
                  ? '0800 7000 220'
                  : '+49 (0) 2553 7220 0'}
              </a>
            </div>
          )}
        </div>
      </Container>
    </header>
  )
}

export default CheckoutHeader
