import { apiPost } from '@utils/api'

const validateAddress = async (address_data, storeCode) => {
  const isValid = await apiPost(
    `ext/address-validation/validate?storeCode=${storeCode}`,
    {
      address_data,
    }
  )
  return isValid
}

export default validateAddress
