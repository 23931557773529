import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import searchQuery from '@utils/algolia/searchQuery'

const initialState = {
  query: {},
}

let searchTimeout = null

export const actionGetSearchQuery = createAsyncThunk(
  'search/getSearchQuery',
  async (params, thunkAPI) => {
    const locale = params?.locale || 'de'
    const query = params?.query || 'homepage_suggestions'
    const state = thunkAPI.getState()

    if (state.search.query[query]) {
      return { response: state.search.query[query], query }
    }
    const response = await searchQuery(query, locale)

    clearTimeout(searchTimeout)

    searchTimeout = setTimeout(() => {
      thunkAPI.dispatch(resetSearch())
    }, 600000)

    return { response, query }
  },
)

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    resetSearch: (state) => {
      state.query = {}
    },
  },
  extraReducers(builder) {
    builder.addCase(actionGetSearchQuery.fulfilled, (state, action) => {
      const { response, query } = action.payload
      state.query = { ...state.query, [query]: response || [] }
    })
  },
})

export const { resetSearch } = searchSlice.actions
export default searchSlice.reducer
