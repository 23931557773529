import Icon from '@components/ui/Icon'
import Text from '@components/ui/Text'
import Button from '@components/ui/Button'
import Spinner from '@components/ui/Spinner'
import Link from '@components/ui/Link'
import CartProduct from '@components/ui/CartProduct'
import { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setMicroCart, setNotification } from '@features/ui/uiSlice'
import useOnClickOutside from '@utils/hooks/useClose'
import cn from '@utils/helpers/cn'
import useI18n from '@utils/hooks/useI18n'
import formatPrice from '@utils/helpers/price'
import { actionClear } from '@features/cart/cartSlice'
import ShareCart from '@components/blocks/Layout/Layout-ui/ShareCart'

const Cart = () => {
  const i18n = useI18n()
  const [productCount, setProductCount] = useState(0)
  const [isShare, setIsShare] = useState(false)
  const dispatch = useDispatch()
  const shoppingCartRef = useRef(null)
  const { microCart = false } = useSelector((state) => state.ui)
  const { products, totals } = useSelector((state) => state.cart)
  const { loading } = useSelector((state) => state.cart)

  const emptyCart = () => {
    dispatch(
      setNotification({
        type: 'removeItems',
        message: i18n.t(
          'Are you sure you would like to remove all the items from the shopping cart?',
        ),
        callback: () => {
          dispatch(actionClear()), dispatch(setMicroCart(true))
        },
      }),
    )
  }

  useEffect(() => {
    let count = 0
    products.forEach((product) => {
      count += product.qty
    })
    setProductCount(count)
  }, [products])

  useOnClickOutside(shoppingCartRef, () => dispatch(setMicroCart(false)))
  const { scrollLock } = useSelector((state) => state.ui)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (scrollLock || microCart) {
        const scrollbarWidth =
          window.innerWidth - document.getElementById('__next').offsetWidth
        document.body.style.paddingRight = `${scrollbarWidth}px`
        document.body.classList.add('scroll-lock')
      } else {
        document.body.style.removeProperty('padding-right')
        document.body.classList.remove('scroll-lock')
      }
    }
  }, [scrollLock, microCart])

  return (
    <>
      <div
        className="ml-2 hidden h-[47px] max-h-max max-w-max cursor-pointer items-center gap-x-2 whitespace-nowrap rounded-sm p-2 text-sm font-semibold text-white hover:bg-[#4B515A] lg:flex"
        onClick={() => dispatch(setMicroCart(true))}
      >
        <Icon
          icon="icon-local_grocery_store"
          className="relative -mt-[2px] text-[26px]"
        >
          {productCount > 0 && (
            <span className="absolute left-[18px] top-[-6px] flex min-h-[18px] min-w-[18px] items-center justify-center rounded-full bg-red-600 font-roboto text-xs font-semibold text-white">
              {productCount}
            </span>
          )}
        </Icon>
        {i18n.t('cart')}
      </div>
      <div
        ref={shoppingCartRef}
        className={cn(
          'fixed -right-[100%] top-0 z-20 h-screen min-h-screen w-11/12 overflow-auto border bg-white text-black shadow-xl shadow-gray-700 transition-all duration-500 lg:-right-[40%] lg:w-1/3 xl:-right-[30%] xl:w-1/4',
          {
            'right-0 lg:right-0 xl:right-0': microCart,
          },
        )}
      >
        <div className="flex cursor-pointer items-center gap-2 bg-[#f7f7f7] py-[7px] pl-[19px] text-[#9D9D9D]">
          <Text
            variant="inlineText"
            className="text-[19px] font-bold leading-7"
            onClick={() => dispatch(setMicroCart(false))}
          >
            &#10005;
          </Text>
          <div
            className="text-[13px] font-semibold"
            onClick={() => dispatch(setMicroCart(false))}
          >
            {i18n.t('Close')}
          </div>
        </div>
        {productCount === 0 ? (
          loading ? (
            <div className="flex h-[300px] items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="bg-white pb-6 pl-7 pr-1 pt-14">
              <Text variant="body" className="pb-4 pt-5 font-bold">
                {i18n.t(['Your shopping cart is empty.'])}
              </Text>
              <Link
                href=""
                locale
                className="cursor-pointer text-sm text-blue-900 underline hover:text-blue-700"
              >
                {i18n.t('Browse our catalog')}
              </Link>{' '}
              {i18n.t(['to find something beautiful for You!'])}
            </div>
          )
        ) : (
          <>
            <div className="bg-[#f7f7f7] px-4">
              <Link
                locale
                href="cart"
                onClick={() => dispatch(setMicroCart(false))}
              >
                <Button variant="primary" className="mb-3.5 p-2 text-base">
                  {i18n.t('Go to shopping cart')}
                </Button>
              </Link>
              {loading ? (
                <>
                  <div className="flex justify-between py-2">
                    <Text variant="inlineText" className="font-light">
                      {i18n.t('Subtotal')}
                    </Text>
                    <Text variant="inlineText" className="font-light">
                      <Spinner />
                    </Text>
                  </div>
                  <div className="flex justify-between py-2">
                    <Text
                      variant="inlineText"
                      className="font-light text-buttonGreenBorder"
                    >
                      {i18n.t('Shipping & Handling')}
                    </Text>
                    <Text variant="inlineText" className="font-light">
                      <Spinner />
                    </Text>
                  </div>
                  <div className="flex justify-between py-2">
                    <Text variant="inlineText" className="font-light">
                      {i18n.t('Tax-short')}
                    </Text>
                    <Text variant="inlineText" className="font-light">
                      <Spinner />
                    </Text>
                  </div>
                </>
              ) : (
                totals?.total_segments?.map((total) => {
                  if (total.code === 'grand_total') return null
                  if (
                    total.value ||
                    total.code === 'shipping' ||
                    total.code === 'tax'
                  ) {
                    return (
                      <div
                        className="flex justify-between py-2"
                        key={total.code}
                      >
                        <Text
                          variant="inlineText"
                          className={cn('font-light', {
                            'text-buttonGreenBorder':
                              total.code === 'shipping' && total.value === 0,
                          })}
                        >
                          {total.title}
                        </Text>
                        <Text
                          variant="inlineText"
                          className={cn('font-light', {
                            'text-buttonGreenBorder':
                              total.code === 'shipping' && total.value === 0,
                          })}
                        >
                          {formatPrice(total.value, false)}
                        </Text>
                      </div>
                    )
                  }

                  return null
                })
              )}
              <div className="flex justify-between border-t border-[#C7C7C7] py-4 text-base font-bold">
                <Text variant="inlineText">{i18n.t('Grand Total')}</Text>
                <Text variant="inlineText">
                  {loading ? (
                    <Spinner />
                  ) : (
                    formatPrice(
                      totals?.total_segments?.find(
                        (total) => total.code === 'grand_total',
                      )?.value || 0,
                      false,
                    )
                  )}
                </Text>
              </div>
            </div>
            <div className="flex justify-between p-4">
              <Text variant="inlineText" className="text-xl font-bold">
                {i18n.t('cart') + ' (' + productCount + ')'}
              </Text>
              <div className="flex gap-2">
                <Button
                  className="w-auto border border-[#C7C7C7] px-[8px] py-2 font-bold text-black"
                  onClick={() => setIsShare(true)}
                >
                  <Icon icon="icon-share" />
                </Button>
                <Button
                  className="w-auto border border-[#C7C7C7] px-[8px] font-semibold text-black"
                  onClick={emptyCart}
                >
                  <Icon icon="icon-delete" className="text-xl" />
                </Button>
              </div>
            </div>
            {products.map((product) => (
              <CartProduct
                className="pr-4"
                product={product}
                key={product.item_id}
              />
            ))}
          </>
        )}
        {isShare && <ShareCart setIsShare={setIsShare} />}
      </div>
    </>
  )
}

export default Cart
