import { apiPost } from '@utils/api'
import { store } from '@features/store'

const shareCart = async ({ comment = '' }) => {
  const userToken = store.getState().user?.token
  const customer_id = store.getState().user?.current?.id
  const cartToken = store.getState().cart?.id
  const languageCode = store.getState().store.language.code
  const currencyCode = store.getState().store.currency.code
  const countryCode = store.getState().store.country.code
  const store_code_prefix = `${languageCode}-${countryCode}-${currencyCode}`
  const body = {
    cartToken,
    comment,
    customer_id,
    store_code_prefix,
  }

  const res = await apiPost(
    `/ext/ggm-shared-cart/get-share-code?token=${userToken ? userToken : ''}`,
    body,
  )
  return res
}

export default shareCart
