import React, { useRef, useState } from 'react'
import Icon from '@components/ui/Icon'
import cn from '@utils/helpers/cn'
import PropTypes from 'prop-types'
import useOnClickOutside from '@utils/hooks/useClose'
import Image from '@components/ui/Image'
import useI18n from '@utils/hooks/useI18n'

const Dropdown = ({
  list,
  isTranslate = false,
  currentValue,
  type,
  onChange,
  placeholder,
  absolute = true,
}) => {
  const openRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')
  const i18n = useI18n()
  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }
  const [value, setValue] = useState(currentValue)
  const languageFlagCode = list.find(
    (item) => item.code?.toLowerCase() === value.toLowerCase(),
  )?.flagCode
  useOnClickOutside(openRef, () => setIsOpen(false))
  const selectCountry = (value) => {
    setSearch('')
    setValue(value)
    onChange(value)
    setIsOpen(false)
  }
  return (
    <div className="relative w-full" ref={openRef}>
      <div
        className={cn(
          'focus:shadow-outline w-full rounded-sm border border-[#c7c7c7] text-left text-gray-700 focus:outline-none',
        )}
        onClick={toggleDropdown}
        data-test="register-country"
      >
        {value ? (
          <div className="flex justify-between">
            <div
              onClick={() => setIsOpen(!isOpen)}
              className={cn(
                'flex w-full cursor-pointer items-center justify-between rounded-sm bg-white px-0.5 py-1.5',
              )}
            >
              <div className="flex py-1">
                {type === 'icon' && (
                  <span className=" ml-1 mr-2.5 flex items-center">
                    <Image
                      className="h-5"
                      src={`/vsf/flags/1x1/${
                        languageFlagCode?.toLowerCase() || value.toLowerCase()
                      }.svg`}
                      alt={value}
                      width={20}
                      height={20}
                    />
                  </span>
                )}
                <div className="text-sm">
                  {isTranslate
                    ? i18n.t(
                        list.find(
                          (item) =>
                            item.code?.toLowerCase() === value.toLowerCase(),
                        )?.name,
                      )
                    : list.find(
                        (item) =>
                          item.code?.toLowerCase() === value.toLowerCase(),
                      )?.name}
                </div>
              </div>
              <div className="flex h-full w-[24px] items-center justify-center border-l border-[#c7c7c7]">
                <Icon
                  icon={cn(isOpen ? 'icon-chevron-up' : 'icon-chevron-down')}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-between pl-2">
            <span>{placeholder}</span>
            <div className="flex h-full w-[24px] items-center border-l border-[#c7c7c7]">
              <span>
                <Icon
                  icon={cn(isOpen ? 'icon-chevron-up' : 'icon-chevron-down')}
                />
              </span>
            </div>
          </div>
        )}
      </div>

      <div
        className={cn('w-full border border-t-0 border-[#c7c7c7] bg-white', {
          'absolute z-10 shadow-lg': absolute,
          hidden: !isOpen,
        })}
      >
        {type === 'icon' && (
          <div className="flex justify-between">
            <input
              type="text"
              placeholder={i18n.t('Search')}
              className="w-full border-b border-[#c7c7c7] p-2 text-sm outline-none"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        )}

        <ul className="list-reset max-h-[40vh] overflow-y-auto">
          {list
            .filter(
              (item, index) =>
                search === '' ||
                (item.name.toLowerCase().includes(search.toLowerCase()) &&
                  item.hasOwnProperty('code') &&
                  index > 0),
            )
            .map((country, index) =>
              country.hasOwnProperty('code') ? (
                <li
                  key={index}
                  className="flex cursor-pointer items-center gap-x-2.5 px-3 py-2 first:pt-3 hover:bg-gray-100"
                  onClick={() => selectCountry(country.code)}
                  data-test={`countryCode-${country.code.toUpperCase()}`}
                >
                  {type === 'icon' && (
                    <Image
                      className="size-[18px] object-fill"
                      src={`/vsf/flags/1x1/${
                        country.flagCode?.toLowerCase() ||
                        country.code.toLowerCase()
                      }.svg`}
                      alt="country flag"
                      width={18}
                      height={18}
                    />
                  )}
                  <div className="text-sm">
                    {country.name
                      ? isTranslate
                        ? i18n.t(country.name)
                        : country.name
                      : country.sign}
                  </div>
                </li>
              ) : (
                <li
                  className="my-1 bg-gray-200 px-3 py-2 text-base"
                  key={index}
                >
                  {isTranslate ? i18n.t(country.name) : country.name}
                </li>
              ),
            )}
        </ul>
      </div>
    </div>
  )
}

Dropdown.propTypes = {
  selectedValue: PropTypes.string,
  setSelectedValue: PropTypes.func,
  placeholder: PropTypes.string,
  datas: PropTypes.array,
  type: PropTypes.string,
  absolute: PropTypes.bool,
}

export default Dropdown
