import { apiGet } from '@utils/api'

const getCountryPrice = async (ids, language, country) => {
  const res = await apiGet(
    `ext/ggm-country-price/get-batch/${country}/${ids}?storeCode=${language}`,
  )
  if (res && res.code === 200) {
    return res.result
  }
  return res
}

export default getCountryPrice
