import { apiGet } from '@utils/api'

const pullAddress = async (token, storeCode) => {
  const res = await apiGet(
    `ext/n98-customer-address/list-all?token=${token}&storeCode=${storeCode}`
  )
  return res
}

export default pullAddress
