import { apiGet } from '@utils/api'

const getCurrency = async () => {
  const res = await apiGet('ext/n98-currency/list')
  if (res && res.code === 200) {
    return res.result
  }
  return res
}

export default getCurrency
