import { apiPost } from '@utils/api'
import { store } from '@features/store'

const getShippingMethods = async (country = 'DE') => {
  const body = {
    address: {
      countryId: country.toUpperCase(),
      city: '',
      region: '',
      region_code: '',
      region_id: '0',
      postcode: '',
      street: [''],
    },
  }

  const languageCode = store.getState().store.language.code
  const cartId = store.getState().cart.id
  const token = store.getState().user?.token || ''

  const res = await apiPost(
    `cart/shipping-methods?token=${
      token ? token : ''
    }&cartId=${cartId}&storeCode=${languageCode}`,
    JSON.stringify(body)
  )

  return res
}

export default getShippingMethods
