import { apiGet } from '@utils/api'
import { store } from '@features/store'

const wishlist = async (token) => {
  let userToken = token || null
  if (!userToken && store.getState().user?.token) {
    userToken = store.getState().user?.token
  }

  const res = await apiGet(`ext/ggm-wishlist/fetch-wishlist?token=${userToken}`)
  return res
}

export default wishlist
