import { store } from '@features/store'
import { apiPost } from '@utils/api'

const changePassword = async (currentpassword, newpassword, token) => {
  const state = store.getState()
  let userToken = token || null

  if (!userToken && store.getState().user?.token) {
    userToken = store.getState().user?.token
  }
  const storeCode = state.store.language.code

  const res = await apiPost(
    `user/change-password?token=${userToken}&storeCode=${storeCode}`,
    {
      currentPassword: currentpassword,
      newPassword: newpassword,
    }
  )
  return res
}

export default changePassword
