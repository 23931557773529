import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { taxService } from '@utils/magento2'

const initialState = {
  rules: [],
}

export const actionGetTax = createAsyncThunk('tax/getTax', async (params) => {
  const locale = params?.locale || 'de'
  const response = await taxService.getTax(locale)
  return { response }
})

export const taxSlice = createSlice({
  name: 'tax',
  initialState,
  reducers: {
    resetTax: (state) => {
      state.rules = []
    },
  },
  extraReducers(builder) {
    builder.addCase(actionGetTax.fulfilled, (state, action) => {
      const taxes = action.payload.response
      if (Array.isArray(taxes)) {
        state.rules = taxes
      }
    })
  },
})

export const { resetTax } = taxSlice.actions
export default taxSlice.reducer
