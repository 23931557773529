import { apiPost } from '@utils/api'
import { store } from '@features/store'

const login = async (username, password) => {
  const storeCode = store.getState().store.language.code
  const url = `user/login?storeCode=${storeCode}`
  const res = await apiPost(url, { username, password })
  return res
}

export default login
