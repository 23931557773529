import { apiPost } from '@utils/api'
import { store } from '@features/store'
import { appConfig } from '@utils/appConfig'
import bodybuilder from 'bodybuilder'

const getBreadcrumbsByCategoryPath = async (path, locale) => {
  if (!path) {
    return {}
  }

  const elasticsearch = locale
    ? appConfig.elasticsearch[locale]
    : store.getState().store.elasticsearch

  const pathArray = path.split('/')

  if (pathArray.length > 2) {
    const categoryPath = pathArray.slice(2)
    const breadcrumbsSourceInclude = ['id', 'name', 'url_path']
    const breadcrumbsBody = bodybuilder()
      .filter('terms', '_id', categoryPath)
      .build()

    const data = await apiPost(
      `catalog/${elasticsearch}/category/_search?_source_exclude=sgn&_source_include=${breadcrumbsSourceInclude.join(
        ','
      )}&from=0&size=100&sort=position:asc`,
      JSON.stringify(breadcrumbsBody)
    )

    if (data?.hits?.hits?.length > 0) {
      const hits = data.hits.hits
      return hits.map((hit) => hit._source)
    }
  }

  return []
}

export default getBreadcrumbsByCategoryPath
