import { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useI18n from '@utils/hooks/useI18n'
import useOnClickOutside from '@utils/hooks/useClose'
import { setIsSearchbarFocus, setScrollLock } from '@features/ui/uiSlice'
import Icon from '@components/ui/Icon'
import Input from '@components/ui/Input'
import { useRouter } from 'next/router'
import { actionGetSearchQuery } from '@features/search/searchSlice'
import cn from '@utils/helpers/cn'
import dynamic from 'next/dynamic'

// Lazy load SpeechRecognition component to improve initial load performance
const SpeechRecognition = dynamic(() => import('./SpeechRecognition'), {
  ssr: false,
})

const Searchbar = () => {
  const i18n = useI18n()
  const router = useRouter()
  const dispatch = useDispatch()
  const searchRef = useRef(null)
  const searchBarRef = useRef(null)

  const initialQuery = useMemo(() => router?.query?.q || '', [router?.query?.q])
  const [query, setQuery] = useState(initialQuery)
  const [isOpened, setIsOpened] = useState(false)
  const [activeIndex, setActiveIndex] = useState(-1)
  const searchTimeout = useRef(null)

  // Memoize selectors to prevent unnecessary re-renders
  const scrollLock = useSelector((state) => state.ui?.scrollLock)
  const isSearchbarFocus = useSelector((state) => state.ui.isSearchbarFocus)
  const locale = useSelector((state) => state.store?.language?.code || 'de')
  const querySuggestions = useSelector((state) => state.search.query)

  // Focus search input when searchbar focus state changes
  useEffect(() => {
    if (isSearchbarFocus && searchRef.current) {
      searchRef.current.focus()
    }
  }, [isSearchbarFocus])

  // Close searchbar when clicking outside
  const handleClickOutside = useCallback(() => {
    if (isSearchbarFocus) {
      dispatch(setIsSearchbarFocus(false))
    }
    setIsOpened(false)
  }, [isSearchbarFocus, dispatch, setIsOpened])

  useOnClickOutside(searchBarRef, handleClickOutside)

  // Memoized search function to prevent recreation on each render
  const search = useCallback(
    (e) => {
      e.preventDefault()
      setIsOpened(false)
      dispatch(setIsSearchbarFocus(false))
      if (searchRef.current) {
        searchRef.current.blur()
      }
      if (query && query.length > 0) {
        router.push(
          `/${router.query.locales}/search?q=${encodeURIComponent(query)}`,
        )
      }
    },
    [query, router.query.locales, dispatch],
  )

  // Memoized redirect function to prevent recreation on each render
  const redirectToSearch = useCallback(
    (searchQuery) => {
      setQuery(searchQuery)
      setIsOpened(false)
      dispatch(setIsSearchbarFocus(false))
      if (searchRef.current) {
        searchRef.current.blur()
      }
      if (searchQuery && searchQuery.length > 0) {
        router.push(
          `/${router.query.locales}/search?q=${encodeURIComponent(searchQuery)}`,
        )
      }
    },
    [router.query.locales, dispatch, router],
  )

  // Memoized keyboard handler to prevent recreation on each render
  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        if (activeIndex === -1) {
          redirectToSearch(query)
          return
        }
        const suggestions =
          querySuggestions[query || 'homepage_suggestions'] || []
        const newQuery = suggestions[activeIndex]?.query || ''
        redirectToSearch(newQuery)
      }

      if (e.key === 'Escape') {
        setIsOpened(false)
        dispatch(setIsSearchbarFocus(false))
      }

      if (e.key === 'ArrowUp') {
        e.preventDefault()
        setIsOpened(true)
        setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : -1))
      }

      if (e.key === 'ArrowDown') {
        e.preventDefault()
        setIsOpened(true)
        const suggestions =
          querySuggestions[query || 'homepage_suggestions'] || []
        setActiveIndex((prevIndex) =>
          prevIndex < suggestions.length - 1
            ? prevIndex + 1
            : suggestions.length - 1,
        )
      }
    },
    [activeIndex, query, querySuggestions, redirectToSearch, dispatch],
  )

  // Clear search input when navigating away from search page
  useEffect(() => {
    if (
      !router.asPath.includes('/search') &&
      searchRef?.current?.value?.length > 0
    ) {
      searchRef.current.value = ''
    }
  }, [router.asPath])

  // Debounced search query update
  useEffect(() => {
    // Clear previous timeout to prevent unnecessary API calls
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current)
    }

    // Set new timeout for search query
    searchTimeout.current = setTimeout(() => {
      dispatch(actionGetSearchQuery({ query, locale }))
    }, 500)

    // Cleanup timeout on component unmount
    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current)
      }
    }
  }, [query, locale, dispatch])

  // Handle scroll lock when search suggestions are open
  useEffect(() => {
    if (isOpened && !scrollLock) {
      dispatch(setScrollLock(true))
    } else if (!isOpened && scrollLock) {
      dispatch(setScrollLock(false))
    }
  }, [isOpened, scrollLock, dispatch])

  // Memoize search suggestions for performance
  const currentSuggestions = useMemo(
    () => querySuggestions[query || 'homepage_suggestions'] || [],
    [querySuggestions, query],
  )

  // Memoized handler for input change to reduce function creation
  const handleInputChange = useCallback((e) => {
    setQuery(e.target.value)
  }, [])

  // Memoized handler for input focus
  const handleInputFocus = useCallback(() => {
    setIsOpened(true)
  }, [])

  return (
    <form
      action=""
      onSubmit={search}
      className="w-full max-lg:pb-[10px] max-lg:pt-[15px] lg:pl-[10px] xl:pl-[50px]"
    >
      <div className="relative" ref={searchBarRef}>
        <div className="flex w-full">
          <Input
            innerRef={searchRef}
            defaultValue={initialQuery}
            type="search"
            area-label="Search"
            useLabel
            isFullWidth
            placeholder={i18n.t(['Search for more than 350.000 items...'])}
            className="h-[47px] grow overflow-hidden rounded-l-[3px] rounded-r-none p-[10px] pr-[55px] font-light outline-none placeholder:text-[#7B8491] hover:border-inherit focus:border-[1.5px] focus:border-red-600 lg:pr-[10px]"
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={handleInputFocus}
          />
          <SpeechRecognition />
          <button
            className="flex h-[47px] w-[52px] items-center justify-center rounded-r-sm bg-[#d80000] text-white"
            type="submit"
            aria-label={i18n.t('Search')}
          >
            <Icon icon="icon-search" className="cursor-pointer text-xl" />
          </button>
        </div>

        {isOpened && currentSuggestions.length > 0 && (
          <ul className="absolute z-50 max-h-[40vh] w-full overflow-y-auto border border-[#c7c7c7] bg-white lg:max-h-[60vh]">
            {currentSuggestions.map((item, index) => (
              <li
                key={item.query}
                className={cn(
                  'border-[#F7F7F7; flex cursor-pointer items-center justify-between border-t p-2.5 first:border-t-0 hover:bg-[#f3f3f3]',
                  {
                    'bg-[#f3f3f3]': activeIndex === index,
                  },
                )}
                onClick={() => redirectToSearch(item.query)}
              >
                {item.query}
              </li>
            ))}
          </ul>
        )}
      </div>
    </form>
  )
}

// Wrap with memo to prevent unnecessary re-renders
export default Searchbar
