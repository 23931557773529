import { apiPost } from '@utils/api'
import { store } from '@features/store'

const applyCoupon = async (coupon) => {
  const storeCode = store.getState().store.language.code
  const token = store.getState().user.token || ''
  const cartId = store.getState().cart.id

  const res = await apiPost(
    `cart/apply-coupon?token=${token}&cartId=${cartId}&coupon=${coupon}&storeCode=${storeCode}`
  )
  return res
}

export default applyCoupon
