import Text from '@components/ui/Text'
import Dropdown from '@components/ui/Dropdown'
import Button from '@components/ui/Button'
import { appConfig } from '@utils/appConfig'
import { setMenuModal, setLanguageModal } from '@features/ui/uiSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import useI18n from '@utils/hooks/useI18n'
import { actionPull } from '@features/cart/cartSlice'

const LanguageModal = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const i18n = useI18n()
  const languages = useMemo(
    () => appConfig.languages.sort((a, b) => a.name.localeCompare(b.name)),
    [appConfig.languages],
  )
  const currencies = useMemo(
    () => appConfig.currency.sort((a, b) => a.name.localeCompare(b.name)),
    [appConfig.currency],
  )

  const countries = useMemo(
    () =>
      appConfig.deliveryCountry.sort((a, b) =>
        i18n.t(a.name).localeCompare(i18n.t(b.name)),
      ),
    [appConfig.deliveryCountry],
  )

  const { language, country, currency } = useSelector((state) => state.store)
  const { hrefLang, currentPage } = useSelector((state) => state.seo)
  const { id: cartId } = useSelector((state) => state.cart)

  const [languageInfo, setLanguageInfo] = useState(language.code || 'de')
  const [countryInfo, setCountryInfo] = useState(country.code || 'de')
  const [currencyInfo, setCurrencyInfo] = useState(currency.code || 'eur')

  const routeChange = () => {
    const { query, asPath } = router
    let page = `/${languageInfo}-${countryInfo}-${currencyInfo}`

    if (
      currentPage &&
      ['product', 'category', 'cms_page'].includes(currentPage)
    ) {
      if (query && query.locales && query.locales.startsWith(languageInfo)) {
        const queryPath = asPath.split('/').slice(2).join('/')
        if (queryPath) {
          page = `${page}/${queryPath}`
        }
      } else {
        const currentHrefLang =
          hrefLang && hrefLang.length > 0
            ? hrefLang.find((item) => item.languageCode === languageInfo)
            : null

        if (currentHrefLang && currentHrefLang.url) {
          const slug = currentHrefLang.url.split('/').slice(4).join('/')
          if (slug) {
            page = `${page}/${slug}`
          }
        }
      }
    } else {
      const queryPath = asPath.split('/').slice(2).join('/')
      if (queryPath) {
        page = `${page}/${queryPath}`
      }
    }

    if (cartId) {
      dispatch(actionPull({ language: languageInfo }))
    }
    dispatch(setLanguageModal(false))
    dispatch(setMenuModal(false))
    router.push(page)
  }
  return (
    <>
      <Text
        variant="inlineText"
        className="text-sm font-bold text-[#282D34] max-lg:text-white"
      >
        {i18n.t('Choose your settings:')}
      </Text>
      <Text
        variant="inlineText"
        className="absolute right-4 top-3 cursor-pointer text-lg font-bold text-[#9d9d9d] max-lg:hidden max-lg:text-white"
        onClick={() => dispatch(setLanguageModal(false))}
      >
        &#10005;
      </Text>
      <Text
        variant="body"
        className="pt-3 text-sm font-bold text-[#282D34] max-lg:text-white"
      >
        {i18n.t('language')}
      </Text>
      <Dropdown
        type="icon"
        list={languages}
        currentValue={languageInfo}
        onChange={setLanguageInfo}
        setSelectedValue={setLanguageInfo}
        absolute={false}
      />
      <Text
        variant="body"
        className="pt-3 text-sm font-bold text-[#282D34] max-lg:text-white"
      >
        {i18n.t('delivery country')}
      </Text>
      <Dropdown
        isTranslate
        type="icon"
        list={countries}
        currentValue={countryInfo}
        onChange={setCountryInfo}
        setSelectedValue={setCountryInfo}
        absolute={false}
      />
      <Text
        variant="body"
        className="pt-3 text-sm font-bold text-[#282D34] max-lg:text-white"
      >
        {i18n.t('currency')}
      </Text>
      <Dropdown
        list={currencies}
        currentValue={currencyInfo}
        onChange={setCurrencyInfo}
        setSelectedValue={setCurrencyInfo}
        absolute={false}
      />
      <Button className="mt-6 p-1 text-sm text-[#282D34]" onClick={routeChange}>
        {i18n.t('save')}
      </Button>
    </>
  )
}

export default LanguageModal
