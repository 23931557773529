import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  personalDetails: {},
  paymentDetails: {},
  shippingDetails: {},
  shippingMethod: {
    code: 'uninsured',
    carrier: 'uninsured',
  },
  shippingOptions: [],
  paymentMethod: {
    code: '',
    title: '',
    additional: {},
  },
  paymentError: {
    message: '',
  },
  lastOrder: {
    id: '',
    backendOrderId: '',
    leasingId: '',
    products: [],
    totals: {},
    personalDetails: {},
    shippingDetails: {},
    shippingDetails: {},
    shippingMethod: {
      code: '',
      carrier: '',
    },
    paymentMethod: {
      code: '',
      title: '',
      additional: {},
    },
  },
}

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setPersonalDetails: (state, action) => {
      state.personalDetails = action.payload
    },
    setPaymentDetails: (state, action) => {
      state.paymentDetails = action.payload
    },
    setShippingDetails: (state, action) => {
      state.shippingDetails = action.payload
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload
    },
    setShippingMethod: (state, action) => {
      state.shippingMethod = action.payload
    },
    setShippingOptions: (state, action) => {
      state.shippingOptions = action.payload
    },
    setPaymentError: (state, action) => {
      state.paymentError = action.payload
    },
    setLastOrder: (state, action) => {
      state.lastOrder = action.payload
    },
    resetCheckout: (state, action) => {
      if (action.payload?.withoutLastOrder) {
        return { ...initialState, lastOrder: state.lastOrder }
      }
      return initialState
    },
  },
})

export const {
  setPersonalDetails,
  setPaymentDetails,
  setShippingDetails,
  setShippingMethod,
  setShippingOptions,
  setPaymentMethod,
  setPaymentError,
  setLastOrder,
  resetCheckout,
} = checkoutSlice.actions

export default checkoutSlice.reducer
