import { apiPost } from '@utils/api'
import { store } from '@features/store'
import { appConfig } from '@utils/appConfig'
import bodybuilder from 'bodybuilder'

const getProductsBySku = async (sku, locale) => {
  if (!sku) {
    return []
  }

  const body = bodybuilder()
    .filter('terms', 'visibility', [2, 3, 4])
    .filter('terms', 'status', [0, 1])
    .filter('terms', 'sku', Array.isArray(sku) ? sku : [sku])
    .build()

  const sourceInclude = [
    'id',
    'sku',
    'name',
    'url_path',
    'url_key',
    'sales_price_*',
    'deliverytime',
    'custom_options',
    'average_review',
    'average_review_count',
    'short_description',
    'manufacturer_dimensions',
    'net_dimensions',
    'size',
    'product_label',
    'custom_options',
    'request_item',
    'rrp',
    'second_choice',
    'image',
    'media_gallery',
    'second_choice',
    'no_leasing',
    'bynder_images',
    'bynder_images_nested',
    'sales_blacklist',
  ]

  const elasticsearch = locale
    ? appConfig.elasticsearch[locale]
    : store.getState().store.elasticsearch

  const res = await apiPost(
    `catalog/${elasticsearch}/product/_search?_source_exclude=sgn&_source_include=${sourceInclude.join(
      ',',
    )}&from=0&size=20`,
    JSON.stringify(body),
  )

  if (res?.hits && res.hits.hits && res.hits.hits.length > 0) {
    return { products: res.hits.hits, total: res.hits.total.value }
  }

  return res
}

export default getProductsBySku
