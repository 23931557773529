import addAddress from '@utils/magento2/user/addAddress' // create address
import addToWishlist from '@utils/magento2/user/addToWishlist' // add item to wishlist
import clearWishlist from '@utils/magento2/user/clearWishlist' // clear wishlist
import createReview from '@utils/magento2/user/createReview' // create review
import deleteAddress from '@utils/magento2/user/deleteAddress' // delete address
import editAddress from '@utils/magento2/user/editAddress' // edit address
import login from '@utils/magento2/user/login' // login user
import me from '@utils/magento2/user/me' // get user info
import orderHistory from '@utils/magento2/user/orderHistory' // get user order history
import pullAddress from '@utils/magento2/user/pullAdress' // get latest address
import register from '@utils/magento2/user/register' // register user
import removeWishListItem from '@utils/magento2/user/removeWishlistItem' // remove item from wishlist
import resetPassword from '@utils/magento2/user/resetPassword' // reset password
import setAddress from '@utils/magento2/user/setAddress' // update shipping and billing address
import subscribe from '@utils/magento2/user/subscribe' // update user newsLetter
import unSubscribe from '@utils/magento2/user/unSubscribe' // update user newsLetter
import update from '@utils/magento2/user/update' // update user info
import validateAddress from '@utils/magento2/user/validateAddress' // validate address
import wishlist from '@utils/magento2/user/wishlist' // get wishlist
import socialLoginAuth from '@utils/magento2/user/socialLoginAuth'
import socialLoginToken from '@utils/magento2/user/socialLoginToken'
import refresh from '@utils/magento2/user/refresh'
import changePassword from '@utils/magento2/user/changePassword'
import resetPasswordValidateCode from '@utils/magento2/user/resetPasswordValidateCode'
import resetByCode from '@utils/magento2/user/resetByCode'

const user = {
  login,
  me,
  orderHistory,
  wishlist,
  update,
  resetPassword,
  subscribe,
  unSubscribe,
  setAddress,
  deleteAddress,
  validateAddress,
  pullAddress,
  addAddress,
  editAddress,
  clearWishlist,
  addToWishlist,
  removeWishListItem,
  register,
  createReview,
  socialLoginAuth,
  socialLoginToken,
  refresh,
  changePassword,
  resetPasswordValidateCode,
  resetByCode,
}

export default user
