import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  mobileMenu: false,
  languageModal: false,
  isLoginModal: false,
  isSearchbarFocus: false,
  isLeasingModal: false,
  leasingModalPrice: 0,
  leasingModalProduct: {},
  isMobile: typeof window !== 'undefined' ? window.innerWidth < 1024 : false,
  scrollLock: false,
  microCart: false,
  notification: {
    type: null,
    message: null,
    callback: null,
  },
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setLanguageModal: (state, action) => {
      state.languageModal = action.payload
    },
    setIsSearchbarFocus: (state, action) => {
      state.isSearchbarFocus = action.payload
    },
    setMenuModal: (state, action) => {
      state.mobileMenu = action.payload
    },

    setIsMobile: (state, action) => {
      state.isMobile = action.payload
    },
    setScrollLock: (state, action) => {
      state.scrollLock = action.payload
    },
    setLoginModal: (state, action) => {
      state.isLoginModal = action.payload
    },
    setLeasingModal: (state, action) => {
      state.isLeasingModal = action.payload
    },
    setLeasingModalPrice: (state, action) => {
      state.leasingModalPrice = action.payload
    },
    setLeasingModalProduct: (state, action) => {
      state.leasingModalProduct = action.payload
    },
    setNotification: (state, action) => {
      state.notification = action.payload
    },
    setMicroCart: (state, action) => {
      state.microCart = action.payload
    },
  },
})

export const {
  setLanguageModal,
  setMenuModal,
  setIsMobile,
  setScrollLock,
  setLoginModal,
  setNotification,
  setMicroCart,
  setLeasingModal,
  setLeasingModalPrice,
  setLeasingModalProduct,
  setIsSearchbarFocus,
} = uiSlice.actions

export default uiSlice.reducer
