import getCurrency from '@utils/magento2/ext/getCurrency'
import getCountryPrice from '@utils/magento2/ext/getCountryPrice'
import magentoForm from '@utils/magento2/ext/magentoForm'
import checkIban from '@utils/magento2/ext/checkIban'
import saveOrderComment from '@utils/magento2/ext/saveOrderComment'

const ext = {
  getCurrency,
  getCountryPrice,
  magentoForm,
  checkIban,
  saveOrderComment,
}

export default ext
